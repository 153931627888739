import { message, Table, Tabs, Tag } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dateFormat from '../Content/dateFormat'
import { getTaskById } from '../Services/task'
import Spinner from './Spinner'
import { Overview } from './Views'
import { taskPriorityColor, taskStatusColor } from '../Content/tasks'
import { associationData } from '../Content/association'
import { getTimesheetByTaskID } from '../Services/timesheet'
import { timeCategory } from '../Content/timetracker'
import { timeDuration } from '../Controllers/timeDuration'
import * as Colors from '../styles/colors'

const TaskViewer = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [data, setData] = useState(null)
	const [timesheetsData, setTimesheetsData] = useState([])
	const fetchTaskData = async () => {
		try {
			const res = await getTaskById(token, id)
			setData(res.data.data)
		} catch (error) {
			message.error('We could not fetch the information.')
		}
	}

	const fetchTimesheetByTaskId = async () => {
		try {
			const res = await getTimesheetByTaskID(token, id)
			setTimesheetsData(res.data.data)
		} catch (error) {
			setTimesheetsData([])
		}
	}

	const timesheetColumns = [
		{
			title: 'Description',
			key: 'description',
			dataIndex: 'description',
			render: desc => desc || '-'
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, rec) =>
				rec.start_time && !timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? (
					moment.unix(rec.start_time).format(dateFormat)
				) : (
					<Tag />
				)
		},
		{
			title: 'Start',
			dataIndex: 'start_time',
			key: 'start_time',
			render: (_, rec) =>
				rec.start_time
					? moment
							.unix(rec.start_time)
							.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
					: null
		},
		{
			title: 'End',
			key: 'end_time',
			dataIndex: 'end_time',
			render: (_, rec) =>
				rec.end_time ? (
					moment
						.unix(rec.end_time)
						.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
				) : (
					<Tag color={Colors.GREEN}> In Progress </Tag>
				)
		},
		{
			title: 'Duration',
			key: 'duration',
			dataIndex: 'duration',
			render: (_, rec) => {
				const duration = rec.end_time
					? moment.duration(moment.unix(rec.end_time).diff(moment.unix(rec.start_time)))
					: false

				if (duration) {
					if (timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange) {
						return timeDuration(duration, 'days')
					}
					return timeDuration(duration, 'table')
				}
				return null
			}
		},
		{
			title: 'By',
			key: 'username',
			dataIndex: 'username'
		}
	]

	useEffect(() => {
		fetchTaskData()
		fetchTimesheetByTaskId()
	}, [])

	const overviewData = {
		..._.omit(data, [
			'assignor_name',
			'assignee_name',
			'association_id',
			'association_name',
			'collaborators_data',
			'activity_id',
			'deliverable_id',
			'lead_owner_name',
			'related_to',
			'reminder',
			'task_type'
		]),
		assignor: data?.assignor_name,
		assignee: data?.assignee_name,
		association: data?.association ? (
			<div className='flex items-center'>
				<Tag>{data?.association}</Tag>
				<Link to={`${associationData.filter(a => a.name === data?.association)[0].link}?id=${data?.association_id}`}>
					{data?.association_name}
				</Link>
			</div>
		) : null,
		collaborators: data?.collaborators_data?.map((a, i) => <Tag key={i}>{a.name}</Tag>),
		end_date: data?.end_date ? moment.unix(data?.end_date).format(dateFormat) : null,
		start_date: data?.start_date ? moment.unix(data?.start_date).format(dateFormat) : null
	}

	return data ? (
		<div>
			<div className='text-5xl font-semibold'>{data.name}</div>
			<div className='grid gap-4 mt-6 text-base font-normal'>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Status</div>
					<div>
						<Tag color={taskStatusColor[data.status]}>{data.status}</Tag>
					</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Assignee</div>
					<div>{data.assignee_name}</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Due Date</div>
					<div>{moment.unix(data.end_date).format(dateFormat)}</div>
				</div>
				<div className='flex gap-8'>
					<div className='text-bell-gray w-20'>Priority</div>
					<div>
						<Tag color={taskPriorityColor[data.priority]}>{data.priority}</Tag>
					</div>
				</div>
				<div className='my-6 grid gap-1'>
					<div className='text-xl font-bold'>Description</div>
					<div>
						{data.description || <span className='text-bell-gray text-sm font-thin'>No description added.</span>}
					</div>
				</div>
			</div>
			<div>
				<Tabs
					items={[
						{
							label: 'Progress',
							key: 'progress',
							children: <Table columns={timesheetColumns} dataSource={timesheetsData} pagination={false} />
						},
						{ label: 'Details', key: 'details', children: <Overview data={overviewData} /> }
					]}
				/>
			</div>
		</div>
	) : (
		<Spinner />
	)
}

export default TaskViewer

/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message, Popover, Skeleton, Spin, Statistic, Table, Tag } from 'antd'
import { AiOutlineInfoCircle, AiTwotoneEdit } from 'react-icons/ai'
import _ from 'lodash'
import moment from 'moment'
import * as Colors from '../../../styles/colors'
import {
	ActivityTimeline,
	Details,
	Files,
	Information,
	LogInformation,
	Notes,
	Overview,
	TasksView,
	Title,
	Variations
} from '../../../Components/Views'
import { DrawerComponent, InternalHeader } from '../../../Components'
import { ContractForm, TaskForm } from '../../../Components/Forms'
import accounting from '../../../Controllers/accountingNumbers'
import dateFormat from '../../../Content/dateFormat'
import {
	getContractAPRStat,
	getContractById,
	getContractDialStat,
	getContractInvoiceDeviationStat,
	getContractLastSubmittedStat,
	updateContract
} from '../../../Services/Contracts/contracts'
import InvoicesView from '../../../Components/Views/Details/InvoicesView'
import BankGuaranteesView from '../../../Components/Views/Details/BankGuaranteeView'
import InvoicingPlanView from '../../../Components/Views/Details/InvoicingPlanView'
import { variationType } from '../../../Content/contracts'
import InvoiceStatusReport from '../../../Components/Views/InvoiceStatusReport'
import GaugeMeter from '../../../Components/GaugeMeter'
import { getInvoicingPlanByContractId } from '../../../Services/Contracts/invoices'
import ShareModal from '../../../Components/ShareModal'
import renderShareTag from '../../../Controllers/shareTag'
import ExpensesView from '../../../Components/Views/Details/ExpensesView'

const SingleContract = () => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')

	const { token, id: userId } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [editDrawer, setEditDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [contract, setContract] = useState('')
	const [additionalStats, setAdditionalStats] = useState(null)
	const [badgeData, setBadgeData] = useState(null)
	const [planId, setPlanId] = useState(false)
	const [planData, setPlanData] = useState([])
	const isInvoicePlanIncomplete = !!badgeData?.filter(one => one?.totalInvoicingMilestone < 100)?.length
	const history = useHistory()

	const accessType = contract?.access_specifier
	const shareType = contract?.access_type

	const fetchSingleContract = async () => {
		try {
			const { data } = await getContractById(token, id)
			if (!data.data) {
				history.push('/contracts')
			}
			setContract(data.data)
		} catch (error) {
			history.push('/contracts')
		}
	}

	// invoicing plan
	useEffect(() => {
		const badgeInformation = contract?.ps?.map(product => ({
			name: product?.display_name,
			id: product?.ps_group_id,
			totalInvoicingMilestone: planData?.filter(one => one?.ps_group_id === product?.ps_group_id)[0]
				? planData
						?.filter(one => one?.ps_group_id === product?.ps_group_id)[0]
						.data?.map(one => one?.invoicing_milestone)
						.reduce((a, b) => a + b, 0)
				: 0
		}))

		setBadgeData(badgeInformation)
	}, [contract, planData])

	const fetchInvoicingPlan = async () => {
		try {
			const { data } = await getInvoicingPlanByContractId(token, id)
			setPlanId(data?.data?.id)
			setPlanData(data?.data?.products)
		} catch (error) {
			setPlanId(false)
			setPlanData([])
		}
	}

	useEffect(() => {
		fetchInvoicingPlan()
	}, [refreshComponent])

	const fetchAdditionalStats = async () => {
		// payments stats
		try {
			const {
				data: { data }
			} = await getContractDialStat(token, id)
			setAdditionalStats(prev => ({ ...prev, payments: data }))
		} catch (error) {
			setAdditionalStats(prev => ({ ...prev, payments: null }))
		}

		// apr stats
		try {
			const {
				data: { data }
			} = await getContractAPRStat(token, id)
			setAdditionalStats(prev => ({ ...prev, apr: data }))
		} catch (error) {
			setAdditionalStats(prev => ({ ...prev, apr: null }))
		}

		// last submitted stats
		try {
			const {
				data: { data }
			} = await getContractLastSubmittedStat(token, id)
			setAdditionalStats(prev => ({ ...prev, lastSubmitted: data }))
		} catch (error) {
			setAdditionalStats(prev => ({ ...prev, lastSubmitted: null }))
		}

		// invoice deviation stats
		try {
			const {
				data: { data }
			} = await getContractInvoiceDeviationStat(token, id)
			setAdditionalStats(prev => ({ ...prev, invoiceDeviation: data }))
		} catch (error) {
			setAdditionalStats(prev => ({ ...prev, invoiceDeviation: null }))
		}
	}

	useEffect(() => {
		fetchSingleContract()
		fetchAdditionalStats()
		// eslint-disable-next-line
	}, [editDrawer, refreshComponent, shareDrawer])

	const handleFiles = async (currentAttachment, fileName, typeOfDocument) => {
		try {
			await updateContract(token, {
				id: contract.id,
				attachments: {
					...contract?.attachments,
					[typeOfDocument]: [...contract.attachments[typeOfDocument], currentAttachment]
				}
			})
			setContract(prev => ({
				...prev,
				attachments: {
					...prev.attachments,
					[typeOfDocument]: [...prev.attachments[typeOfDocument], currentAttachment]
				}
			}))
			message.success(`${fileName} uploaded successfully`)
		} catch (error) {
			message.error(`Uploading ${fileName} failed. Try again.`)
		}
	}
	const handleRemoveAsset = async (fileName, typeOfDocument) => {
		try {
			await updateContract(token, {
				id: contract.id,
				attachments: {
					...contract?.attachments,
					[typeOfDocument]: contract?.attachments[typeOfDocument].filter(x => x !== fileName)
				}
			})
			setContract(prev => ({
				...prev,
				attachments: {
					...prev.attachments,
					[typeOfDocument]: prev.attachments[typeOfDocument].filter(x => x !== fileName)
				}
			}))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: contract.id,
				...sharingData
			}
			await updateContract(token, dataObject)
			message.success(`Contract updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}

	const overviewData = contract && {
		..._.omit(contract, [
			'attachments',
			'ps',
			'currency',
			'trn',
			'account_id',
			'enquiry_id',
			'po_date',
			'po_number',
			'contracts_representatives_data',
			'projects_representatives_data',
			'owner_name',
			'account_name',
			'enquiry_name',
			'current_variation_data',
			'current_variation',
			'variations',
			'entity_data',
			'entity_id',
			'entity_name',
			'project_manager_id',
			'project_manager_name',
			'deputy_id',
			'deputy_name'
		]),
		account: contract.account_id && (
			<Link to={`../sales/accounts?id=${contract.account_id}`}> {contract.account_name}</Link>
		),
		invoicing_entity: contract.entity_id && contract?.entity_data?.name,
		owner: contract.owner_name,
		project_manager: contract.project_manager_name,
		deputy: contract.deputy_name,
		enquiry: contract.enquiry_id && (
			<Link to={`../sales/enquiries?id=${contract.enquiry_id}`}> {contract.enquiry_name}</Link>
		),
		'PO Number': contract.po_number,
		'PO Date': contract.po_date && moment.unix(contract.po_date).format(dateFormat),
		contracts_representatives:
			contract?.contracts_representatives?.length &&
			contract?.contracts_representatives_data?.map(one => (
				<div>
					<Link to={`../sales/contacts?id=${one.id}`}> {one.name}</Link>
				</div>
			)),
		projects_representatives:
			contract?.projects_representatives?.length &&
			contract?.projects_representatives_data?.map(one => (
				<div>
					<Link to={`../sales/contacts?id=${one.id}`}> {one.name}</Link>
				</div>
			)),
		total_variation_made: contract.variations && contract.variations.length - 1,
		latest_variation: contract.current_variation_data && (
			<div className='flex space-x-2 items-center'>
				<span>{contract?.current_variation_data?.type}</span>
				<span>{variationType[contract?.current_variation_data?.type]}</span>
			</div>
		),
		'Latest Products & Services': contract?.ps?.length && (
			<Table
				columns={[
					{ title: 'Product / Service Name', dataIndex: 'display_name' },
					{ title: 'Product / Service Group', render: (_, w) => w.ps_group_name },
					{ title: 'Order Value', render: (_, w) => `USD ${accounting(w.order_value)}` }
				]}
				dataSource={contract.ps}
				pagination={false}
				size='small'
			/>
		),
		TRN: contract.trn
	}

	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Variations',
								content: <Variations contractData={contract} accessType={accessType} />
							},
							{
								name: 'Invoices',
								content: <InvoicesView contractData={contract} accessType={accessType} />
							},
							{
								name: 'Bank Guarantees',
								content: <BankGuaranteesView associationData={contract} type='Bank Guarantee' accessType={accessType} />
							},
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: (
									<div className='flex justify-between'>
										<div className='w-full'>
											<div className='mb-2  text-sm italic text-bell-gray'>Contract Documents</div>
											<Files
												id={id}
												attachments={contract?.attachments?.contract_documents || []}
												handleFiles={(a, b) => handleFiles(a, b, 'contract_documents')}
												handleRemove={a => handleRemoveAsset(a, 'contract_documents')}
												accessType={accessType}
												tabView
											/>
										</div>
										<div className='w-full'>
											<div className='mb-2  text-sm italic text-bell-gray'>Supporting Documents</div>
											<Files
												id={id}
												attachments={contract?.attachments?.supporting_documents || []}
												handleFiles={(a, b) => handleFiles(a, b, 'supporting_documents')}
												handleRemove={a => handleRemoveAsset(a, 'supporting_documents')}
												accessType={accessType}
												tabView
											/>
										</div>
									</div>
								)
							}
						]}
					/>
				)
			},
			{
				name: 'Project & Invoicing Plan',
				content: (
					<InvoicingPlanView contractData={contract} planData={planData} planId={planId} accessType={accessType} />
				),
				badge: {
					status: isInvoicePlanIncomplete || badgeData?.length === 0 ? 'error' : 'success',
					popoverText:
						isInvoicePlanIncomplete && badgeData?.length ? (
							<div className='w-64'>
								<div className='font-medium'>Following products/services have not been planned fully:</div>
								<ul>
									{badgeData
										?.filter(one => one?.totalInvoicingMilestone < 100)
										?.map(one => (
											<li>{one?.name}</li>
										))}
								</ul>
							</div>
						) : badgeData?.length === 0 ? (
							'Project & Invoicing Plan is pending!'
						) : (
							'Project & Invoicing Plan has been filled completely.'
						),
					count: badgeData?.filter(one => one?.totalInvoicingMilestone < 100).length
				}
			},
			{
				name: 'Invoicing Status Report',
				content: <InvoiceStatusReport contractData={contract} />
			},

			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Contracts' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							}
						]}
					/>
				)
			}
		]
	}

	const StatComponent = () =>
		useMemo(
			() =>
				additionalStats ? (
					<div className='flex gap-10 flex-wrap'>
						<GaugeMeter
							percent={additionalStats?.payments?.toFixed(1)}
							heading='Payments'
							subHeading='Money Received vs. Total Contract Value'
							popoverContent='This indicates the percentage of contract amount that has been paid.'
						/>
						<GaugeMeter
							percent={additionalStats?.invoiceDeviation?.stats?.toFixed(1) || 0}
							heading='Invoice Plan Deviation'
							subHeading='Actual Money Received vs. Projected'
							popoverContent='This indicates the percentage of amount paid as per the invoicing plan in this month.'
						/>
						<div className='flex flex-col justify-between'>
							<Statistic
								title={
									<div className='text-bell-gray flex items-center space-x-2'>
										<span>APR Turnover</span>
										<span className='cursor-pointer'>
											<Popover
												content={
													<div className='w-56'>
														<p>
															<strong>How quickly do you collect payments?</strong>
														</p>
														<p>
															The Average Payments Receivable (APR) turnover measures how quickly you collect your
															payments owed and displays our effectiveness in extending credits. The faster we can turn
															credit sales into cash, the higher is the liquidity.
														</p>
														{additionalStats?.apr?.net && (
															<p>
																The actual payment term as per the latest paid invoice is
																<strong className='text-bell-red'> {additionalStats?.apr?.net}</strong>.
															</p>
														)}
													</div>
												}
											>
												<AiOutlineInfoCircle fontSize={18} />
											</Popover>
										</span>
									</div>
								}
								value={additionalStats?.apr?.avg ? Number.parseFloat(additionalStats?.apr?.avg).toFixed(1) : 'NA'}
								suffix={additionalStats?.apr?.avg ? 'days' : ''}
							/>
							<Statistic
								title={
									<div className='text-bell-gray flex items-center space-x-2 justify-center'>
										<span>Days Since Last Invoice Submitted</span>
									</div>
								}
								value={
									additionalStats?.lastSubmitted?.duration
										? Number.parseFloat(additionalStats?.lastSubmitted?.duration).toFixed(1)
										: 'NA'
								}
								suffix={additionalStats?.lastSubmitted?.duration ? 'days' : ''}
							/>
						</div>
					</div>
				) : (
					<Skeleton active />
				),
			[additionalStats]
		)
	return (
		<div>
			<InternalHeader title='Contracts' selected='Contracts' />
			{contract ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<div className='grid grid-cols-3 gap-10 place-content-stretch'>
						<Title
							title='Contract'
							name={contract.project_name}
							hasSubtitle
							extraDetail={
								<div>
									<Tag color={userId === contract?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === contract?.owner,
											accessType
										})}
									</Tag>
								</div>
							}
							editButton={
								<DrawerComponent
									form={
										<ContractForm
											edit
											handleClose={() => {
												setEditDrawer(false)
											}}
											data={contract}
										/>
									}
									visible={editDrawer}
									onOpen={() => setEditDrawer(true)}
									onClose={() => setEditDrawer(false)}
									buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
									buttonType='link'
									drawerTitle='Edit Contract'
									drawerWidth={900}
									isHidden={accessType !== 'Public'}
								/>
							}
							shareButton={
								<ShareModal
									visible={shareDrawer}
									onOpen={() => setShareDrawer(true)}
									onClose={() => setShareDrawer(false)}
									modalTitle='Share Contract'
									isHidden={accessType !== 'Public'}
									handleValues={handleSharing}
									data={contract}
								/>
							}
							overview={[
								{
									title: 'Account Name',
									value: contract.account_id && (
										<Link to={`../sales/accounts?id=${contract?.account_id}`}>{contract?.account_name}</Link>
									)
								},
								{
									title: 'PO Date',
									value: contract.po_date && moment.unix(contract.po_date).format(dateFormat)
								},
								{
									title: 'Enquiry',
									value: contract.enquiry_id && (
										<Link to={`../sales/enquiries?id=${contract?.enquiry_id}`}>{contract?.enquiry_name}</Link>
									)
								},
								{
									title: 'Contract Owner',
									value: contract?.owner_name
								}
							]}
						/>
						<div className='col-span-2 grid place-items-center'>
							{accessType === 'Public' || accessType === 'Protect' ? <StatComponent /> : null}
						</div>
					</div>
					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spin />
			)}
		</div>
	)
}

export default SingleContract

import moment from 'moment'
import React from 'react'
import { SiMicrosoftoffice } from 'react-icons/si'
import dateFormat from '../Content/dateFormat'

const AddToCalendar = ({ subject, description, unixDate }) => (
	<div className='flex items-center space-x-4'>
		<div>{moment.unix(unixDate).format(dateFormat)}</div>
		<a
			href={`https://outlook.office.com/calendar/0/deeplink/compose?allday=true&body=${description}&enddt=${moment
				.unix(unixDate)
				.add(1, 'd')
				.format('YYYY-MM-DD')}T00%3A00%3A00%2B00%3A00&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${moment
				.unix(unixDate)
				.format('YYYY-MM-DD')}T00%3A00%3A00%2B00%3A00&subject=${subject}`}
			className='flex items-center'
			target='_blank'
			rel='noreferrer'
		>
			Add to Calendar
			<span className='pl-2'>
				<SiMicrosoftoffice fontSize={16} />
			</span>
		</a>
	</div>
)

export default AddToCalendar

import { Divider } from 'antd'
import moment from 'moment'
import React from 'react'
import dateFormat from '../Content/dateFormat'

const ModalView = ({ data }) => {
	const creationInformationArray = ['created_at', 'updated_at']

	return (
		<div>
			<div className='p-4 space-y-4 bg-white'>
				{Object.keys(data)
					.filter(item => creationInformationArray.indexOf(item) === -1 && data[item])
					.map((item, i) => (
						<div className='grid grid-cols-4 gap-4' key={i}>
							<div className='capitalize col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
							<div className='col-span-3'> {data[item]}</div>
						</div>
					))}
				<Divider />
				{creationInformationArray
					.filter(item => data[item])
					.map((item, i) => (
						<div className='grid grid-cols-4 gap-4' key={i}>
							<div className='capitalize col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
							<div className='col-span-3'>
								{moment.unix(data[item]).calendar(
									(null,
									{
										sameElse: dateFormat
									})
								)}
							</div>
						</div>
					))}
			</div>
		</div>
	)
}

export default ModalView

/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Divider, Statistic, Table, Cascader, Spin, message, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { InternalHeader, SelectDebounce } from '../../Components'
import { fetchUserList } from '../../Controllers/fetchLists'
import dateFormat from '../../Content/dateFormat'
import { addReportFilter, addReportUser } from '../../Store/Actions/reports'
import ReporteeSelect from '../../Components/ReporteeSelect'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { BASE_URL } from '../../config'
import { getSalesReport } from '../../Services/reports'
import { daysFilterOptions } from '../../Controllers/daysFilter'
import accounting from '../../Controllers/accountingNumbers'
import { associationData } from '../../Content/association'

const ComponentToPrint = React.forwardRef(({ componentData, user, filter, loading }, ref) =>
	loading || !componentData ? (
		<div className='pt-32 text-center'>
			{' '}
			<Spin size='large' />
		</div>
	) : (
		<div ref={ref} className='print:p-10 py-10'>
			<section className='text-2xl font-semibold py-2'>
				Sales Report: <span className='font-medium text-bell-text'>{user.name} </span>
				<span className='text-base text-bell-text font-normal'>
					(
					{moment()
						.subtract(filter[filter.length - 1], 's')
						.format(dateFormat)}
					{' - '}
					{moment().format(dateFormat)})
				</span>
			</section>
			<section className='text-xl font-semibold py-2'>Overview</section>
			<div className='grid grid-cols-6 gap-5'>
				<Statistic title='New Leads Added' value={componentData.new_leads} />
				<Statistic title='Calls Made to Leads' value={componentData.calls_to_leads} />
				<Statistic title='Calls Made to Contacts' value={componentData.calls_to_contacts} />
				<Statistic title='Opportunities Identified' value={componentData.new_opportunities} />
				<Statistic title='Enquiries Identified' value={componentData.new_enquiries} />
				<Statistic title='Proposals Submitted' value={componentData.proposal_submitted} />
			</div>
			<Divider />
			<section className='text-xl font-semibold py-2'>Opportunities Added </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Account', dataIndex: 'account_name' },
					{ title: 'Stage', dataIndex: 'stage' },
					{
						title: 'Product / Services',
						render: (_, record) =>
							record?.ps?.length ? (
								<ul>
									{record?.ps?.map(q => (
										<li>{q.name || ''}</li>
									))}
								</ul>
							) : null
					},
					{
						title: 'Decision Makers',
						render: (_, record) => (
							<>
								{record?.decision_makers_registration_data?.length && (
									<>
										<Tag>Registration</Tag>
										<ul>
											{record?.decision_makers_registration_data?.map(q => (
												<li>{q.name || ''}</li>
											))}
										</ul>
									</>
								)}
								{record?.decision_makers_technical_data?.length && (
									<>
										<Tag>Technical</Tag>
										<ul>
											{record?.decision_makers_technical_data?.map(q => (
												<li>{q.name || ''}</li>
											))}
										</ul>
									</>
								)}
								{record?.decision_makers_procurement_data?.length && (
									<>
										<Tag>Procurement</Tag>
										<ul>
											{record?.decision_makers_procurement_data?.map(q => (
												<li>{q.name || ''}</li>
											))}
										</ul>
									</>
								)}
							</>
						)
					},
					{
						title: 'Related Tasks',
						render: (_, record) =>
							record?.tasks?.length ? (
								<ul>
									{record?.tasks?.map(q => (
										<li>{q.name || ''}</li>
									))}
								</ul>
							) : null
					},
					{
						title: 'Closing Date',
						dataIndex: 'closing_date',
						render: date => (date ? moment.unix(date).format(dateFormat) : null)
					},
					{
						title: 'Expected Budget',
						dataIndex: 'expected_budget',
						render: budget => (budget ? `USD ${accounting(budget)}` : null)
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/opportunities?id=${record.id}`}>View</a>
							</div>
						)
					}
				]}
				dataSource={componentData.added_opportunities}
				pagination={false}
			/>
			<Divider />
			<section className='text-xl font-semibold py-2'>Leads Added </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Account', dataIndex: 'account_name' },
					{
						title: 'Last Activity',
						render: (_, rec) => (
							<div>
								<Tag>{rec?.activity_type}</Tag>
								<div className='font-medium'>{rec?.activity_subject}</div>
								<div>{rec?.activity_description}</div>
							</div>
						)
					},
					{
						title: 'Latest Activity Date',
						dataIndex: 'activity_date',
						render: (_, rec) => (rec.activity_date ? moment.unix(rec.activity_date).format(dateFormat) : null)
					},

					{
						title: 'Related Tasks',
						render: (_, record) =>
							record?.tasks?.length ? (
								<ul>
									{record?.tasks?.map(q => (
										<li>{q.name || ''}</li>
									))}
								</ul>
							) : null
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/leads?id=${record.id}`}>View</a>
							</div>
						)
					}
				]}
				dataSource={componentData.added_leads}
				pagination={false}
			/>
			<Divider />
			<section className='text-xl font-semibold py-2'>Enquiries Added </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Stage', dataIndex: 'stage' },
					{ title: 'Contact Person', dataIndex: 'contact_person_data' },
					{
						title: 'Submission Date',
						dataIndex: 'submission_date',
						render: (_, rec) => (rec.submission_date ? moment.unix(rec.submission_date).format(dateFormat) : null)
					},
					{
						title: 'Last Activity On',
						dataIndex: 'activity_date',
						render: (_, rec) => (rec.activity_date ? moment.unix(rec.activity_date).format(dateFormat) : null)
					},
					{
						title: 'Related Tasks',
						render: (_, record) =>
							record?.tasks?.length ? (
								<ul>
									{record?.tasks?.map(q => (
										<li>{q.name || ''}</li>
									))}
								</ul>
							) : null
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/enquiries?id=${record.id}`}>View</a>
							</div>
						)
					}
				]}
				dataSource={componentData.added_enquiries}
				pagination={false}
			/>
			<Divider />
			<section className='text-xl font-semibold py-2'>Proposals Submitted </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Account', dataIndex: 'account_name' },
					{
						title: 'Submitted On',
						dataIndex: 'submission_date',
						render: date => (date ? moment.unix(date).format(dateFormat) : null)
					},
					{ title: 'Amount', dataIndex: 'amount', render: amount => (amount ? `USD ${accounting(amount)}` : null) },
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/enquiries?id=${record.id}`}>View</a>
							</div>
						)
					}
				]}
				dataSource={componentData.submitted}
				pagination={false}
				summary={pageData => {
					let totalAmount = 0
					pageData.forEach(({ amount }) => {
						if (typeof amount === 'number') {
							totalAmount += amount
						}
					})
					return (
						<Table.Summary.Row>
							<Table.Summary.Cell />
							<Table.Summary.Cell />
							<Table.Summary.Cell>Total</Table.Summary.Cell>
							<Table.Summary.Cell colSpan={2}>
								<div type='primary' className='font-medium'>
									USD {accounting(totalAmount)}
								</div>
							</Table.Summary.Cell>
							<Table.Summary.Cell />
						</Table.Summary.Row>
					)
				}}
			/>

			<Divider />
			<section className='text-xl font-semibold py-2'>Critical Tasks to Follow </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{
						title: 'Date',
						dataIndex: 'date',
						render: (_, rec) => (rec.date ? moment.unix(rec.date).format(dateFormat) : null)
					}
				]}
				dataSource={componentData.critical_tasks}
				pagination={false}
			/>
			<Divider />
			<section className='text-xl font-semibold py-2'>Activities </section>
			<Table
				columns={[
					{
						title: 'Association',
						key: 'association_name',
						render: (_, record) =>
							record.association_name ? (
								<div>
									<div className='text-xs text-bell-gray uppercase'>{record.association_type}</div>
									<Link
										to={`..${associationData.filter(a => a.name === record.association_type)[0]?.link}?id=${
											record.association_id
										}`}
									>
										{record.association_name}
									</Link>
								</div>
							) : (
								<div className='border border-bell-blue w-2 ml-6' />
							)
					},
					{ title: 'Date', dataIndex: 'created_at', render: date => date && moment.unix(date).format(dateFormat) },
					{ title: 'Activity Type', dataIndex: 'activity_type' },
					{ title: 'Type', dataIndex: 'type' },
					{ title: 'Purpose', dataIndex: 'purpose' },
					{ title: 'Subject', dataIndex: 'subject' },
					{ title: 'Description', dataIndex: 'description' },
					{ title: 'Logged By', dataIndex: 'created_by_name' }
				]}
				dataSource={
					componentData.activities && componentData.activities.sort((a = 0, b = 1) => b.created_at - a.created_at)
				}
				pagination={false}
			/>
		</div>
	)
)

const SalesReport = () => {
	const componentRef = useRef()

	const { token, role } = useSelector(state => state.userSession)
	const { first_name, last_name, id } = useSelector(state => state.userProfile)
	const { user, filter } = useSelector(state => state.reportsData)

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)
	const [reportData, setReportData] = useState(null)

	const fetchReport = async (id, duration) => {
		setLoading(true)
		try {
			const { data } = await getSalesReport(token, id, duration)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(data.data[0])
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!user.name) {
			dispatch(addReportUser({ name: `${first_name} ${last_name}`, id }))
		}
	}, [])

	useEffect(() => {
		fetchReport(user.id || id, filter[filter.length - 1])
	}, [filter, user])

	const excelData = {
		Overview: [
			{
				Name: user.name,
				Date: `${moment()
					.subtract(filter[filter.length - 1], 's')
					.format(dateFormat)} -
					${moment().format(dateFormat)}`,
				'Calls to Contacts': reportData?.calls_to_contacts,
				'Calls to Leads': reportData?.calls_to_leads,
				'New Enquiries': reportData?.new_enquiries,
				'New Leads': reportData?.new_leads,
				'New Opportunities': reportData?.new_opportunities,
				'Proposals Submitted': reportData?.proposal_submitted
			}
		],
		'Added Opportunities': reportData?.added_opportunities
			? reportData?.added_opportunities?.map(one => ({
					Name: one?.name,
					'Account Name': one?.account_name,
					Stage: one?.stage,
					'Products / Services': one?.ps?.length ? one?.ps?.map(q => q.name).join('; ') : '',
					'Decision Makers Registration': one?.decision_makers_registration_data?.length
						? one?.decision_makers_registration_data?.map(q => q.name).join('; ')
						: '',
					'Decision Makers Technical': one?.decision_makers_technical_data?.length
						? one?.decision_makers_technical_data?.map(q => q.name).join('; ')
						: '',
					'Decision Makers Procurement': one?.decision_makers_procurement_data?.length
						? one?.decision_makers_procurement_data?.map(q => q.name).join('; ')
						: '',

					Tasks: one?.tasks ? one?.tasks?.map(q => q.name).join('; ') : '',
					'Closing Date': one.closing_date ? moment.unix(one?.closing_date).format(dateFormat) : '',
					'Expected Budget': one?.expected_budget ? accounting(one?.expected_budget) : '',
					Link: `${BASE_URL}/sales/opportunities?id=${one.id}`
			  }))
			: null,
		'Added Leads': reportData?.added_leads
			? reportData?.added_leads?.map(one => ({
					Name: one?.name,
					'Account Name': one?.account_name,
					'Latest Activity':
						one?.activity_type && one?.activity_subject && `${one?.activity_type}: ${one?.activity_subject}`,
					'Latest Activity Desc.': one?.activity_description,
					'Latest Activity On': one?.activity_date ? moment.unix(one?.activity_date).format(dateFormat) : '',
					Tasks: one?.tasks ? one?.tasks?.map(q => q.name).join('; ') : '',
					Link: `${BASE_URL}/sales/leads?id=${one.id}`
			  }))
			: null,
		'Added Enquiries': reportData?.added_enquiries
			? reportData?.added_enquiries?.map(one => ({
					Name: one?.name,
					Stage: one?.stage,
					'Contact Person': one?.contact_person_data,
					'Submission Date': one?.submission_date ? moment.unix(one?.submission_date).format(dateFormat) : '',
					'Last Activity On': one?.activity_date ? moment.unix(one?.activity_date).format(dateFormat) : '',
					Tasks: one?.tasks ? one?.tasks?.map(q => q.name).join('; ') : '',
					Link: `${BASE_URL}/sales/enquiries?id=${one.id}`
			  }))
			: null,
		'Proposals Submitted': reportData?.submitted
			? reportData?.submitted?.map(one => ({
					'Enquiry Name': one?.name,
					'Account Name': one?.account_name,
					'Amount (USD)': one?.amount,
					'Submitted On': one?.submission_date ? moment.unix(one?.submission_date).format(dateFormat) : '',
					Link: `${BASE_URL}/sales/enquiries?id=${one.id}`
			  }))
			: null,
		'Critical Tasks': reportData?.critical_tasks
			? reportData?.critical_tasks?.map(one => ({
					Name: one?.name,
					Date: one.date ? moment.unix(one?.date).format(dateFormat) : '',
					Link: `${BASE_URL}/tasks/tasks`
			  }))
			: null,
		Activities: reportData?.activities
			? reportData?.activities?.map(one => ({
					Association: `${one?.association_name} (${one?.association_type})`,
					Date: one?.created_at && moment.unix(one?.created_at).format(dateFormat),
					'Activity Type': one?.activity_type,
					Type: one?.type,
					Purpose: one?.purpose,
					Subject: one?.subject,
					Description: one?.description,
					'Logged By': one?.created_by_name,
					Link: `${BASE_URL}${associationData.filter(a => a.name === one?.association_type)[0]?.link}?id=${one.id}`
			  }))
			: null
	}

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Sales Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName={`${user.name} Sales Report.xlsx`} />
						<ReactToPrint
							trigger={() => (
								<Button type='primary' icon={<PrinterOutlined />}>
									Print Report
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						{role === 'Admin' ? (
							<SelectDebounce
								showSearch
								placeholder='Search for Users'
								fetchOptions={e => fetchUserList(e, token)}
								currentSearchValue={user.name || `${first_name} ${last_name}`}
								onChange={e => dispatch(addReportUser({ name: e.label, id: e.value }))}
								style={{ width: 200 }}
							/>
						) : (
							<ReporteeSelect currentValue={user.name || `${first_name} ${last_name}`} />
						)}

						<Cascader
							options={daysFilterOptions}
							onChange={e => e && dispatch(addReportFilter(e))}
							placeholder='Filter'
							value={filter}
							displayRender={(label, option) => (label.length > 1 ? `Last ${label[2]} ${label[1]}` : option[0].label)}
							clearIcon={false}
						/>
						<Button
							onClick={() => {
								dispatch(addReportUser({ name: `${first_name} ${last_name}`, id }))
								dispatch(addReportFilter([604800]))
							}}
							disabled={user?.id === id}
						>
							Reset
						</Button>
					</div>
				</div>
				<ComponentToPrint ref={componentRef} componentData={reportData} user={user} filter={filter} loading={loading} />
			</div>
		</div>
	)
}

export default SalesReport

import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Radio, Select, Space, Switch, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import SelectDebounce from '../../SelectDebounce'
import {
	fetchAccountList,
	fetchContactsList,
	fetchContactsOrLeads,
	fetchOpportunitiesList
} from '../../../Controllers/fetchLists'
import AccountForm from '../AccountForm'
import { addWorkingLead } from '../../../Store/Actions/leads'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { updateLead } from '../../../Services/Sales/leads'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import * as Colors from '../../../styles/colors'
import colorSelector from '../../../Content/tagColor'
import { ADD_OPPORTUNITY } from '../../../Controllers/buttonNames'
import OpportunitiesForm from '../OpportunitiesForm'
import { ERROR_MESSAGE } from '../../../Content/messages'

const { Option } = Select

const WorkingLeadForm = ({ edit, handleClose, data }) => {
	const formData = data
		? {
				...data,
				stakeholders: data?.stakeholders_data?.map(one => ({
					key: one.id,
					value: one.id,
					label: (
						<div>
							<Tag color={Colors[colorSelector[one.type]]}>{one.type}</Tag>
							{one.name}
						</div>
					)
				})),
				competitors: data?.competitors_data?.map(one => ({
					key: one.id,
					value: one.id,
					label: one.name
				})),
				decision_makers: data?.decision_makers_data?.map(one => ({
					key: one.id,
					value: one.id,
					label: one.name
				})),
				opportunity: {
					key: data?.opportunity,
					value: data?.opportunity,
					label: data?.opportunity_name || data?.opportunity
				}
		  }
		: false

	const { token } = useSelector(state => state.userSession)
	const { id, lead_status } = useSelector(state => state.singleLeadsData)
	const { first_name, last_name } = useSelector(state => state.userProfile)
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [accountValue, setAccountValue] = useState(data?.competitors_data || [])

	// Updates Field on New Account Creation
	useEffect(() => {
		form.setFieldsValue({
			competitors: accountValue?.map(x => ({
				key: x.key ? x.key : x?.id,
				label: x.label ? x.label : x?.name,
				value: x.value ? x.value : x?.id
			}))
		})
	}, [accountValue, form])

	const handleAccountValues = e => {
		const dataObject = {
			key: e.id,
			label: e.account_name,
			value: e.id
		}
		setAccountValue(prev => [...prev, dataObject])
	}

	const getDrawerComponent = onClose => {
		switch (visible) {
			case 'Create an Account':
				return <AccountForm handleClose={onClose} handleAccountValues={handleAccountValues} />
			case 'Add Opportunity':
				return (
					<OpportunitiesForm
						handleClose={onClose}
						handleValues={data => {
							form.setFieldsValue({
								opportunity: {
									label: data.name,
									value: data.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}

	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}
	const [formSwitches, setFormSwitches] = useState({
		existing_contact: data?.existing_contact || true
	})

	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			id,
			lead_status: edit ? lead_status : 'Working',
			working_data: {
				...values,
				customer_needs: values?.customer_needs,
				competitors: values?.competitors && values?.competitors.map(one => one.value),
				decision_makers: values?.decision_makers && values?.decision_makers.map(one => one.value),
				proposed_solutions: values?.proposed_solutions,
				opportunity: values?.opportunity?.value || '',
				stakeholders:
					values?.stakeholders &&
					values.stakeholders.map(one => ({
						type: one.label.props.children[0].props.children,
						id: one.value
					})),
				summary: values.summary
			}
		}
		try {
			await updateLead(token, dataObject)
			dispatch(addWorkingLead(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Lead Stage',
				subject: edit ? `Lead Working Stage updated.` : `Lead Working Stage added.`,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Stage updated successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(ERROR_MESSAGE)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={onFinish}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Registration Information</div>

				<Form.Item label='Business Opportunity'>
					<Space>
						<Form.Item name='opportunity' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Opportunity'
								fetchOptions={e => fetchOpportunitiesList(e, token)}
								style={{
									width: 500
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Opportunity')} type='link'>
							{ADD_OPPORTUNITY}
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Existing Contact?' name='existing_contact'>
					<Switch
						onChange={e => setFormSwitches(prev => ({ ...prev, existing_contact: e }))}
						defaultChecked={formSwitches.existing_contact}
					/>
				</Form.Item>
				<Form.Item label='Purchase Time Frame' name='purchase_time_frame'>
					<Select>
						{['Immediate', 'This Quarter', 'Next Quarter', 'This Year', 'Unknown'].map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Estimated Budget' name='estimated_budget'>
					<Input addonBefore='USD' />
				</Form.Item>
				<Form.Item name='is_decision_maker' label='Is this lead a decision maker?'>
					<Radio.Group>
						<Radio.Button value='Yes'>Yes</Radio.Button>
						<Radio.Button value='No'>No</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label='Decision Makers' name='decision_makers'>
					<SelectDebounce
						showSearch
						placeholder='Identify Decision Makers'
						fetchOptions={e => fetchContactsList(e, token)}
						isMultiple
					/>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Working Information</div>
				<Form.Item
					label='Customer Needs'
					name='customer_needs'
					rules={[{ required: true, message: 'Please input customer needs!' }]}
				>
					<Input.TextArea rows={4} placeholder='What are their needs and demands?' />
				</Form.Item>
				<Form.Item label='Proposed Solutions' name='proposed_solutions'>
					<Input.TextArea rows={4} placeholder='What solutions are being proposed?' />
				</Form.Item>
				<Form.Item label='Stakeholders' name='stakeholders'>
					<SelectDebounce
						showSearch
						placeholder='Identify Stakeholders (Leads / Contacts / Accounts)'
						fetchOptions={e => fetchContactsOrLeads(e, token)}
						isMultiple
					/>
				</Form.Item>

				<Form.Item label='Identify Competitors'>
					<Space>
						<Form.Item name='competitors' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Identify Accounts..'
								fetchOptions={e => fetchAccountList(e, token)}
								isMultiple
								style={{
									width: 500
								}}
								defaultValue={accountValue?.map(x => ({ key: x.id, label: x.label, value: x.value }))}
								onChange={e => setAccountValue(e)}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Create an Account')} type='link'>
							Create an Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Summary' name='summary'>
					<Input.TextArea rows={4} placeholder='Summary?' />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={900}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default WorkingLeadForm

WorkingLeadForm.propTypes = {
	edit: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.shape
}

WorkingLeadForm.defaultProps = {
	edit: false,
	data: null
}

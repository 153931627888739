import moment from 'moment/moment'
import React, { useState } from 'react'
import dateFormat from '../../../../Content/dateFormat'
import accounting from '../../../../Controllers/accountingNumbers'
import DrawerComponent from '../../../DrawerComponent'
import SubmissionForm from '../../../Forms/Enquiries/SubmissionForm'
import MINIOLink from '../../../../Controllers/MinioLink'

const Submission = ({ data, isFilled, isCurrent, showButton, otherData }) => {
	const [form, setForm] = useState(false)
	return (
		<div>
			<div className='font-bold text-base text-bell-text py-2'> Submission </div>
			{isFilled ? (
				<div>
					<div className='grid grid-cols-3 gap-4 py-1'>
						<div className='capitalize col-span-1 text-bell-gray'>Due Date of Submission</div>
						<div className='col-span-2'> {moment.unix(otherData.submission_date).format(dateFormat)}</div>
					</div>
					{data.submission_date && (
						<div className='grid grid-cols-3 gap-4 py-1'>
							<div className='capitalize col-span-1 text-bell-gray'>Actual Date of Submission</div>
							<div className='col-span-2'>
								{moment.unix(data.submission_date).format(dateFormat)} (
								{moment.unix(otherData.submission_date).diff(moment.unix(data.submission_date), 'days') > 0 ? (
									<span className='text-bell-green font-medium'>
										Advanced by{' '}
										{moment.unix(otherData.submission_date).diff(moment.unix(data.submission_date), 'days') + 1} days
									</span>
								) : (
									<span className='text-bell-red font-medium'>
										Delayed by{' '}
										{Math.abs(moment.unix(otherData.submission_date).diff(moment.unix(data.submission_date), 'days'))}{' '}
										days
									</span>
								)}
								)
							</div>
						</div>
					)}
					{data.amount > 0 && (
						<div className='grid grid-cols-3 gap-4'>
							<div className='capitalize col-span-1 text-bell-gray'>Amount</div>
							<div className='col-span-2'> USD {accounting(data.amount)}</div>
						</div>
					)}

					<div className='p-4 grid grid-cols-3 gap-10'>
						{data.submission_data.map((item, i) => (
							<div className='flex flex-col bg-bell-background p-5 rounded shadow' key={i}>
								<div className='font-semibold py-4 text-center'> {item?.proposal_type}</div>
								<div className='pl-5 space-y-2'>
									<div>
										<div> Evidence Documents</div>
										<div>
											{item?.evidence?.map(doc => (
												<div className='truncate'>
													<MINIOLink item={doc} />
												</div>
											))}
										</div>
									</div>
									<div>
										<div> Attachments</div>
										<div>
											{item?.attachments?.map(doc => (
												<div className='truncate'>
													<MINIOLink item={doc} />
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{isCurrent && showButton && (
						<DrawerComponent
							form={<SubmissionForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Submission'
							drawerWidth={700}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default Submission

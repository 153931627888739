import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, message, Table, Tag } from 'antd'
import { AiOutlineDown, AiOutlineUp, AiTwotoneEdit } from 'react-icons/ai'
import moment from 'moment'

import * as Colors from '../../../styles/colors'

// COMPONENTS
import {
	ActivityTimeline,
	Details,
	EnquiryAttachments,
	EnquiryStage,
	Information,
	LogInformation,
	Notes,
	Overview,
	Revisions,
	TasksView,
	Title
} from '../../../Components/Views'
import { DrawerComponent, InternalHeader, Spinner } from '../../../Components'
import { enquiryStages } from '../../../Content/enquiries'
import { addSingleEnquiry, resetEnquiryAttachment } from '../../../Store/Actions/enquiries'
import {
	getAttachmentsByEnquiryID,
	getContactsByEnquiryID,
	getEnquiryById,
	getRevisionById,
	updateEnquiry
} from '../../../Services/Sales/enquiries'
import EnquiryStepper from '../../../Components/Internal/EnquiryStepper'
import { RegisterEnquiryForm, TaskForm } from '../../../Components/Forms'
import BankGuaranteesView from '../../../Components/Views/Details/BankGuaranteeView'
import renderShareTag from '../../../Controllers/shareTag'
import ShareModal from '../../../Components/ShareModal'
import dateFormat from '../../../Content/dateFormat'
import ExpensesView from '../../../Components/Views/Details/ExpensesView'

const SingleEnquiry = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')
	const rev = query.get('rev')
	const { token, id: userId } = useSelector(state => state.userSession)
	const singleEnquiriesData = useSelector(state => state.singleEnquiriesData)
	const refreshComponent = useSelector(state => state.refreshComponent)
	const [loading, setLoading] = useState(true)
	const [editEnquiryDrawer, setEditEnquiryDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [previousRevisionData, setPreviousRevisionData] = useState(false)
	const [enquiryContacts, setEnquiryContacts] = useState([])
	const [displayStepper, setDisplayStepper] = useState(false)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		count: enquiryContacts.length
	})

	// access information
	const accessType = singleEnquiriesData?.access_specifier
	const shareType = singleEnquiriesData?.access_type

	const handleChange = page => {
		setPagination(prev => ({ ...prev, current: page.current, pageSize: page.pageSize }))
	}
	const fetchSingleEnquiry = async () => {
		try {
			const { data } = await getEnquiryById(token, id)
			if (!data.data) {
				history.push('/sales/enquiries')
			}
			dispatch(addSingleEnquiry(data.data))
			setLoading(false)
		} catch (error) {
			history.push('/sales/enquiries')
		}
	}

	const fetchRevision = async () => {
		try {
			const { data } = await getRevisionById(token, rev)
			if (!data.data) {
				history.push('/sales/enquiries')
			}
			setPreviousRevisionData(data.data)
			setLoading(false)
		} catch (error) {
			history.push('/sales/enquiries')
		}
	}
	useEffect(() => {
		fetchSingleEnquiry()
		if (rev) {
			fetchRevision()
		}
		// eslint-disable-next-line
	}, [refreshComponent])
	// singleEnquiriesData, revisionsData

	const fetchAttachments = async (token, association_id) => {
		try {
			const { data } = await getAttachmentsByEnquiryID(token, association_id)
			if (!data.data) {
				dispatch(resetEnquiryAttachment([]))
			} else {
				dispatch(resetEnquiryAttachment(data.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		fetchAttachments(token, id)
		// eslint-disable-next-line
	}, [refreshComponent])

	const contactsData = w => {
		const dm_reg =
			w?.revisionContacts?.filter(contact => contact?.decision_makers_registration?.length > 0).length > 0
				? w?.revisionContacts
						?.filter(contact => contact?.decision_makers_registration?.length > 0)
						?.map(x =>
							x?.decision_makers_registration?.map(x => ({
								name: 'Decision Maker (Registration)',
								data: x
							}))
						)[0]
				: []

		const dm_pro =
			w?.revisionContacts?.filter(contact => contact?.decision_makers_procurement?.length > 0).length > 0
				? w?.revisionContacts
						?.filter(contact => contact?.decision_makers_procurement?.length > 0)
						?.map(x =>
							x?.decision_makers_procurement?.map(x => ({
								name: 'Decision Maker (Procurement)',
								data: x
							}))
						)[0]
				: []

		const dm_tec =
			w?.revisionContacts?.filter(contact => contact?.decision_makers_technical?.length > 0).length > 0
				? w?.revisionContacts
						?.filter(contact => contact?.decision_makers_technical?.length > 0)
						?.map(x =>
							x?.decision_makers_technical?.map(x => ({
								name: 'Decision Maker (Technical)',
								data: x
							}))
						)[0]
				: []

		const cp = w?.contact_person_data?.name
			? {
					name: 'Contact Person',
					data: w?.contact_person_data
			  }
			: null
		return [cp, ...dm_reg, ...dm_pro, ...dm_tec]
	}

	const fetchEnquiryContacts = async (token, association_id) => {
		try {
			const { data } = await getContactsByEnquiryID(token, association_id)

			if (data.data) {
				setEnquiryContacts(contactsData(data.data))
				setPagination(prev => ({ ...prev, count: contactsData(data.data).length }))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong!')
		}
	}
	useEffect(() => {
		fetchEnquiryContacts(token, id)
		// eslint-disable-next-line
	}, [])

	// sharing enquiry
	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: singleEnquiriesData.id,
				...sharingData
			}
			await updateEnquiry(token, dataObject)
			fetchSingleEnquiry()
			message.success(`Enquiry updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}

	const contactsColumns = [
		{
			title: 'Contact Name',
			dataIndex: 'contact_name',
			render: (_, record) => record.data.name
		},
		{
			title: 'Role',
			dataIndex: 'name',
			key: 'role'
		},
		{
			title: 'Actions',

			key: 'actions',
			render: (text, record) => <a href={`./contacts?id=${record.data.id}`}> View Contact </a>
		}
	]
	const overviewData = {
		enquiry_number: singleEnquiriesData?.enquiry_number,
		enquiry_name: singleEnquiriesData?.job_description,
		lead_account_name: (
			<Link to={`./accounts?id=${singleEnquiriesData.account_id}`}> {singleEnquiriesData.lead_account_name} </Link>
		),
		account_reference_number: singleEnquiriesData?.account_ref_number,
		enquiry_source: singleEnquiriesData?.enquiry_source,
		enquiry_stage: singleEnquiriesData?.enquiry_stage,
		probability: `${singleEnquiriesData?.probability}%`,
		description: singleEnquiriesData?.description,
		enquiry_date: singleEnquiriesData.enquiry_date
			? moment.unix(singleEnquiriesData.enquiry_date).format(dateFormat)
			: undefined,
		submission_date: singleEnquiriesData.submission_date
			? moment.unix(singleEnquiriesData.submission_date).format(dateFormat)
			: undefined,
		created_at: singleEnquiriesData.created_at,
		updated_at: singleEnquiriesData.updated_at
	}
	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Revisions',
								content: <Revisions enquiry_id={singleEnquiriesData?.id} accessType={accessType} />
							},
							{
								name: 'Contacts',
								content: (
									<Table
										columns={contactsColumns}
										dataSource={enquiryContacts.filter(x => x)}
										pagination={pagination}
										onChange={handleChange}
									/>
								)
							},
							{
								name: 'Tender Bonds',
								content: (
									<BankGuaranteesView
										associationData={singleEnquiriesData}
										type='Tender Bond'
										accessType={accessType}
									/>
								)
							},
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: <EnquiryAttachments id={id} accessType={accessType} />
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={singleEnquiriesData?.id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Enquiries' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							},
							{
								name: 'New Event'
							}
						]}
					/>
				)
			}
		]
	}

	return (
		<div>
			<InternalHeader title='Sales' selected='Enquiries' />
			{!loading ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<Title
						title='Enquiry'
						name={singleEnquiriesData?.job_description}
						hasSubtitle
						extraDetail={
							<div>
								<div>
									<span className='text-bell-gray text-lg font-medium'> {singleEnquiriesData?.enquiry_number}: </span>
									{singleEnquiriesData?.revisions && (
										<span className=' text-bell-text text-lg'>
											Revision {previousRevisionData.revision_number || singleEnquiriesData?.revisions.length}
										</span>
									)}

									{previousRevisionData &&
										previousRevisionData.revision_number !==
											singleEnquiriesData?.current_revision_data.revision_number && (
											<Alert
												message={<div className='text-base'>You are viewing an older revision of the enquiry.</div>}
												action={
													<a href={`/sales/enquiries?id=${singleEnquiriesData?.id}`} className='text-base'>
														Switch to Current Revision
													</a>
												}
												type='info'
												showIcon
												style={{ width: '50%', marginTop: '15px' }}
											/>
										)}
								</div>
								<div>
									<Tag color={userId === singleEnquiriesData?.enquiry_owner_id ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === singleEnquiriesData?.enquiry_owner_id,
											accessType
										})}
									</Tag>
								</div>
							</div>
						}
						editButton={
							<DrawerComponent
								form={
									<RegisterEnquiryForm
										edit
										handleClose={() => {
											setEditEnquiryDrawer(false)
										}}
										data={singleEnquiriesData}
									/>
								}
								visible={editEnquiryDrawer}
								onOpen={() => setEditEnquiryDrawer(true)}
								onClose={() => setEditEnquiryDrawer(false)}
								buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
								buttonType='link'
								drawerTitle='Edit Enquiry'
								drawerWidth='900'
								isHidden={accessType !== 'Public'}
							/>
						}
						shareButton={
							<ShareModal
								visible={shareDrawer}
								onOpen={() => setShareDrawer(true)}
								onClose={() => setShareDrawer(false)}
								modalTitle='Share Opportunity'
								isHidden={accessType !== 'Public'}
								handleValues={handleSharing}
								data={singleEnquiriesData}
							/>
						}
						overview={[
							{
								title: 'Account Name',
								value: (
									<Link to={`./accounts?id=${singleEnquiriesData.account_id}`} color={Colors.BLUE}>
										{singleEnquiriesData.lead_account_name}
									</Link>
								)
							},
							{
								title: 'Enquiry Date',
								value: singleEnquiriesData.enquiry_date
									? moment.unix(singleEnquiriesData.enquiry_date).format(dateFormat)
									: ''
							},
							{
								title: 'Submission Date',
								value: singleEnquiriesData.submission_date
									? moment.unix(singleEnquiriesData.submission_date).format(dateFormat)
									: ''
							},
							{
								title: 'Enquiry Owner',
								value: singleEnquiriesData.enquiry_owner_name
							},
							{
								title: 'Enquiry Stage',
								value:
									accessType === 'Public' || accessType === 'Protect' ? (
										<div
											className='text-bell-blue flex items-center space-x-2 cursor-pointer'
											onClick={() => setDisplayStepper(!displayStepper)}
										>
											<div>
												{!singleEnquiriesData.closed ? (
													singleEnquiriesData.enquiry_stage
												) : (
													<Tag color={singleEnquiriesData.closed === 'Won' ? Colors.GREEN : Colors.RED}>
														{singleEnquiriesData.enquiry_stage?.toUpperCase()}
													</Tag>
												)}
											</div>

											<div>{displayStepper ? <AiOutlineUp /> : <AiOutlineDown />}</div>
										</div>
									) : null
							},
							{
								title: 'Converted From',
								value: singleEnquiriesData.converted_from && (
									<Link to={`./opportunities?id=${singleEnquiriesData.converted_from}`}>
										{singleEnquiriesData?.converted_from_name || 'This Opportunity'}
									</Link>
								)
							}
						]}
					/>
					{(accessType === 'Public' || accessType === 'Protect') && (
						<EnquiryStepper
							current={singleEnquiriesData.enquiry_stage}
							display={displayStepper}
							enquiryID={singleEnquiriesData.id}
							closeStepper={() => setDisplayStepper(false)}
							accessType={accessType}
						/>
					)}
					{(accessType === 'Public' || accessType === 'Protect') && (
						<EnquiryStage
							stages={enquiryStages}
							id={id}
							closed='Won'
							data={singleEnquiriesData}
							revisionData={previousRevisionData}
							accessType={accessType}
						/>
					)}

					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spinner />
			)}
		</div>
	)
}

export default SingleEnquiry

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tag, Button, Space, Popconfirm, message } from 'antd'
import moment from 'moment'
import { TiTick } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import { DataView } from './index'
import dateFormat from '../Content/dateFormat'
import { createLead } from '../Services/Sales/leads'
import { deleteWebInquiryById, getAllWebInquiries, updateWebInquiryById } from '../Services/Sales/websiteinquiries'
import * as Colors from '../styles/colors'

const { CheckableTag } = Tag
const WebsiteInquiriesView = () => {
	const { token } = useSelector(state => state.userSession)
	const [data, setData] = useState([])
	const [dataLoader, setDataLoader] = useState(false)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: ''
	})
	const [addLeadButtonLoader, setAddLeadButtonLoader] = useState(false)
	const [clamp, setClamp] = useState(false)
	const history = useHistory()

	const tagsData = {
		website: ['Bell Energy', 'Bell Immersive', 'Bell Analytics'],
		converted: ['Converted', 'Not Converted'],
		replied: ['Replied', 'Not Replied']
	}
	const [tags, setTags] = useState({
		website: undefined,
		converted: undefined,
		replied: undefined
	})

	const tagColor = {
		'Bell Energy': Colors.LIGHT_BLUE,
		'Bell Immersive': Colors.LIGHT_RED,
		'Bell Analytics': Colors.LIGHT_GREEN
	}

	const handleTags = (type, tag) => {
		setTags(prev => ({ ...prev, [type]: tag }))
	}

	const fetchData = async (_, current, pageSize) => {
		try {
			const response = await getAllWebInquiries(token, current, pageSize, tags.website, tags.replied, tags.converted)
			setData(response.data.data)
			setPagination({
				current: current + 1,
				pageSize,
				total: response.data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong!')
		} finally {
			setDataLoader(false)
		}
	}

	const handleChange = page => {
		fetchData(token, page.current - 1, page.pageSize)
	}

	useEffect(() => {
		fetchData(token, 0, pagination.pageSize, pagination.sortOrder)
		// eslint-disable-next-line
	}, [tags])

	const handleSetLead = async values => {
		const { id } = values
		setAddLeadButtonLoader(true)
		const dataObject = {
			first_name: values.name.split(' ')[0],
			last_name: values.name.split(' ').slice(1).join(' '),
			lead_source: 'Website',
			lead_status: 'New',
			mobile_phone: values.phone,
			personal_email_address: values.email,
			attachments: []
		}
		try {
			const res = await createLead(token, dataObject)
			await updateWebInquiryById(token, { id, converted: true })
			message.success('Lead created!')
			history.push(`/sales/leads?id=${res.data.data.id}`)
		} catch (error) {
			message.error('This inquiry could not be converted to a lead. Please try again!')
		} finally {
			setAddLeadButtonLoader(false)
		}
	}

	const columns = [
		{
			title: 'Date',
			key: 'created_at',
			dataIndex: 'created_at',
			render: date => (date !== 0 ? moment.unix(date).format(dateFormat) : '_')
		},
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			render: x => <div className='font-semibold'>{x}</div>
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'email',
			render: email => (
				<a href={`mailto:${email}`} className='inline-block w-36'>
					{email}
				</a>
			)
		},
		{
			title: 'Phone',
			key: 'phone',
			dataIndex: 'phone',
			render: phone => (
				<a href={`tel:${phone}`} className='inline-block w-24'>
					{phone}
				</a>
			)
		},
		{
			title: 'Message',
			key: 'message',
			dataIndex: 'message',
			render: (text, record) => (
				<div
					className={
						clamp !== record.id
							? 'line-clamp-4 text-stone-700 cursor-pointer'
							: 'line-clamp-none text-stone-700 cursor-pointer'
					}
					onClick={() => {
						if (clamp === record.id) {
							setClamp(false)
						} else {
							setClamp(record.id)
						}
					}}
				>
					{text}
				</div>
			)
		},
		{
			title: 'Website',
			key: 'tag',
			dataIndex: 'tag',
			render: tag => <Tag color={tagColor[tag]}>{tag}</Tag>
		},

		{
			title: 'Converted',
			key: 'converted',
			dataIndex: 'converted',
			render: (converted, record) => (
				<Space>
					<Popconfirm
						title='Are you sure to convert it as Lead? Tapping Yes will automatically create a Lead with the same details.'
						disabled={converted}
						showArrow
						icon={false}
						okText='Yes'
						onConfirm={() => handleSetLead(record)}
						okButtonProps={{ loading: addLeadButtonLoader }}
					>
						{!converted ? (
							<Button type='link'>Convert to Lead</Button>
						) : (
							<Button type='text' disabled>
								<div className='flex items-center gap-1 text-bell-green opacity-70'>
									<TiTick />
									Converted
								</div>
							</Button>
						)}
					</Popconfirm>
				</Space>
			)
		},
		{
			title: 'Replied',
			key: 'replied',
			dataIndex: 'replied',
			render: (replied, record) => (
				<Space>
					<Popconfirm
						title="Are you sure to set this entry as 'Replied'?"
						disabled={replied}
						showArrow
						icon={false}
						okText='Yes'
						onConfirm={async () => {
							try {
								await updateWebInquiryById(token, { id: record.id, replied: true })
								setData(prev =>
									prev.map(q => {
										if (q.id === record.id) {
											return { ...q, replied: true }
										}
										return q
									})
								)
							} catch (error) {
								message.error('Something wrong happened. Please try again!')
							}
						}}
					>
						{!replied ? (
							<Button type='link'>Set as Replied</Button>
						) : (
							<Button type='text' disabled>
								<div className='flex items-center gap-1 text-bell-green opacity-70'>
									<TiTick />
									Replied
								</div>
							</Button>
						)}
					</Popconfirm>
				</Space>
			)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Popconfirm
						title='Are you sure to delete this entry? This action cannot be undone.'
						showArrow
						icon={false}
						okText='Delete'
						okButtonProps={{ danger: true }}
						onConfirm={async () => {
							try {
								await deleteWebInquiryById(token, record.id)
								setData(prev => prev.filter(q => q.id !== record.id))
							} catch (error) {
								message.error('Something wrong happened. Please try again!')
							}
						}}
					>
						<Button type='link' danger>
							Delete
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	]

	return (
		<div>
			<div className='mx-10 flex gap-3 my-4'>
				<div className='px-2 py-1 bg-gray-50 border border-solid border-gray-200 rounded-md'>
					{tagsData.website.map(tag => (
						<CheckableTag
							key={tag}
							checked={tags.website === tag}
							onChange={checked => handleTags('website', checked ? tag : undefined)}
						>
							{tag}
						</CheckableTag>
					))}
				</div>
				<div className='px-2 py-1 bg-gray-50 border border-solid border-gray-200 rounded-md'>
					{tagsData.converted.map(tag => (
						<CheckableTag
							key={tag}
							checked={tags.converted === tag}
							onChange={checked => handleTags('converted', checked ? tag : undefined)}
						>
							{tag}
						</CheckableTag>
					))}
				</div>

				<div className='px-2 py-1 bg-gray-50 border border-solid border-gray-200 rounded-md'>
					{tagsData.replied.map(tag => (
						<CheckableTag
							key={tag}
							checked={tags.replied === tag}
							onChange={checked => handleTags('replied', checked ? tag : undefined)}
						>
							{tag}
						</CheckableTag>
					))}
				</div>
			</div>
			<DataView
				columns={columns}
				noHeader
				data={data}
				type='website'
				loading={dataLoader}
				pagination={pagination}
				handleChange={handleChange}
				handleSearch={() => {}}
			/>
		</div>
	)
}

export default WebsiteInquiriesView

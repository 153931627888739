/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useRef } from 'react'
import { Table, Input, Button, Form, DatePicker, message, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { createInvoicingPlan, updateInvoicingPlan } from '../../../Services/Contracts/invoices'

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

const initialItem = {
	key: v4(),
	deliverable: '',
	project_milestone: '',
	submission_month: '',
	invoicing_milestone: '',
	invoicing_month: '',
	payment_terms: '',
	receipt_month: '',
	updated: false
}

const EditableCell = ({
	title,
	value,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	handleRemove,
	...restProps
}) => {
	const inputRef = useRef(null)
	const form = useContext(EditableContext)

	const save = async () => {
		const values = await form.validateFields()
		if (editable) {
			handleSave({ ...record, ...values })
		}
	}

	const renderChildren = {
		deliverable: <Input.TextArea rows={1} ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} />,
		project_milestone: <Input.TextArea rows={1} ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} />,
		submission_month: <DatePicker ref={inputRef} picker='month' onChange={save} />,
		invoicing_milestone: (
			<InputNumber
				ref={inputRef}
				onPressEnter={save}
				onChange={save}
				onBlur={save}
				formatter={d => d && `${d}%`}
				parser={d => d.replace('%', '')}
				min={0}
				max={100}
			/>
		),
		invoicing_month: <DatePicker ref={inputRef} picker='month' onChange={save} />,
		payment_terms: <Input ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} />,
		receipt_month: <DatePicker ref={inputRef} picker='month' onChange={save} />
	}
	return (
		<td {...restProps}>
			{editable ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					initialValue={record.updated ? record[dataIndex] : ''}
				>
					{renderChildren[dataIndex]}
				</Form.Item>
			) : (
				<div>{children}</div>
			)}
		</td>
	)
}

const expandedRowRender = (record, dataSource, setDataSource) => {
	const { ps_group_id } = record
	const tableData = dataSource?.filter(x => x?.ps_group_id === ps_group_id)[0]?.data
	const handleSave = row => {
		setDataSource(prev =>
			prev.map(product => {
				if (product.ps_group_id === ps_group_id) {
					return {
						...product,
						data: product.data.map(x => {
							if (x.key === row.key) {
								return { ...x, ...row, updated: true }
							}
							return x
						})
					}
				}
				return product
			})
		)
	}

	const handleAdd = async () => {
		setDataSource(prev =>
			prev.map(product => {
				if (product.ps_group_id === ps_group_id) {
					return {
						...product,
						data: [
							...product.data,
							{
								key: v4(),
								deliverable: '',
								project_milestone: '',
								submission_month: '',
								invoicing_milestone: '',
								invoicing_month: '',
								payment_terms: '',
								receipt_month: '',
								updated: false
							}
						]
					}
				}
				return product
			})
		)
	}

	const handleRemove = async row => {
		setDataSource(prev =>
			prev.map(product => {
				if (product.ps_group_id === ps_group_id) {
					if (product?.data?.length > 0) {
						return {
							...product,
							data: product.data.filter(x => x.key !== row.key)
						}
					}
					return {
						...product,
						data: [initialItem]
					}
				}
				return product
			})
		)
	}
	const columns = [
		{
			title: 'Deliverable',
			dataIndex: 'deliverable',
			editable: true
		},
		{
			title: 'Project Milestone',
			dataIndex: 'project_milestone',
			editable: true
		},
		{
			title: 'Est. Submission Month',
			dataIndex: 'submission_month',
			width: '10%',
			editable: true
		},
		{
			title: 'Invoicing Milestone',
			key: 'invoicing_milestone',
			dataIndex: 'invoicing_milestone',
			width: '10%',
			editable: true
		},
		{
			title: 'Est. Invoicing Month',
			dataIndex: 'invoicing_month',
			width: '10%',
			editable: true
		},
		{
			title: 'Payment Terms',
			key: 'payment_terms',
			dataIndex: 'payment_terms',
			width: '10%',
			editable: true
		},

		{
			title: 'Est. Payment Receipt Month',
			key: 'receipt_month',
			dataIndex: 'receipt_month',
			width: '10%',
			editable: true
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, w) => (
				<Button onClick={() => handleRemove(w)} type='link'>
					Remove
				</Button>
			)
		}
	]
	const mergerColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
				handleRemove
			})
		}
	})

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell
		}
	}
	return (
		<div className='space-y-4'>
			<Table
				components={components}
				size='small'
				rowClassName={() => 'editable-row'}
				bordered
				dataSource={tableData}
				columns={mergerColumns}
				pagination={false}
			/>
			<Button type='dashed' onClick={() => handleAdd()} block icon={<PlusOutlined />}>
				Add Item
			</Button>
		</div>
	)
}
const InvoicingPlanForm = ({ contractData, handleClose, data, planId }) => {
	const [dataSource, setDataSource] = useState(
		data.length > 0
			? contractData?.ps?.map(product => ({
					key: v4(),
					ps_group_id: product?.ps_group_id,
					ps_group_name: product?.ps_group_name,
					display_name: product?.display_name,
					order_value: product?.order_value,
					data: data?.filter(one => one.ps_group_id === product?.ps_group_id)[0]
						? data
								?.filter(one => one.ps_group_id === product?.ps_group_id)[0]
								?.data?.map(one => ({
									...one,
									submission_month: one.submission_month ? moment.unix(one.submission_month) : undefined,
									invoicing_month: one.invoicing_month ? moment.unix(one.invoicing_month) : undefined,
									receipt_month: one.receipt_month ? moment.unix(one.receipt_month) : undefined
								}))
						: [initialItem]
			  }))
			: contractData?.ps?.map(product => ({
					key: v4(),
					ps_group_id: product?.ps_group_id,
					ps_group_name: product?.ps_group_name,
					display_name: product?.display_name,
					order_value: product?.order_value,
					data: [initialItem]
			  }))
	)

	const [loading, setLoading] = useState(false)
	const { token } = useSelector(state => state.userSession)

	const handleSave = async () => {
		setLoading(true)
		const dataObject = {
			contract_id: contractData?.id,
			products: dataSource.map(product => ({
				..._.omit(product, ['key', 'order_value', 'ps_group_name', 'display_name']),
				data: product?.data
					?.filter(x => x.updated)
					.map(one => ({
						...one,
						submission_month: one?.submission_month ? moment(one.submission_month).unix() : 0,
						invoicing_month: one?.invoicing_month ? moment(one.invoicing_month).unix() : 0,
						receipt_month: one?.receipt_month ? moment(one.receipt_month).unix() : 0,
						invoicing_milestone: one?.invoicing_milestone || 0,
						amount: one.invoicing_milestone ? (one.invoicing_milestone * product.order_value) / 100 : 0
					}))
			}))
		}
		try {
			planId
				? await updateInvoicingPlan(token, { id: planId, ...dataObject })
				: await createInvoicingPlan(token, dataObject)
			message.success(planId ? 'Invoice plan updated successfully.' : 'Invoice plan added successfully.')
			handleClose()
		} catch (error) {
			message.error('Invoice plan could not be created. Please try again.')
		} finally {
			setLoading(false)
		}
	}
	return (
		<>
			<Table
				columns={[
					{ title: 'Product / Service Name', dataIndex: 'display_name', key: 'display_name' },
					{ title: 'Product / Service Group', dataIndex: 'ps_group_name', key: 'ps_group_name' },
					{
						title: 'Total Deliverables',
						dataIndex: 'deliverables',
						render: (_, w) =>
							dataSource.filter(x => x.ps_group_id === w.ps_group_id)[0]?.data?.filter(x => x.updated)?.length
					}
				]}
				expandedRowRender={record => expandedRowRender(record, dataSource, setDataSource)}
				dataSource={dataSource}
				pagination={false}
				locale={{
					emptyText: 'Currently, This contract has no products. Add Products/Services in Contracts to get started!'
				}}
			/>
			<div className='grid place-items-center pt-10'>
				<Button
					type='primary'
					onClick={handleSave}
					loading={loading}
					disabled={
						dataSource?.length === 0 ||
						dataSource.filter(product => product?.data?.filter(x => x?.updated).length)?.length === 0
					}
				>
					Save & Close Invoicing Plan
				</Button>
			</div>
		</>
	)
}

export default InvoicingPlanForm

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Alert, Avatar, Button, Divider, Input, message, Modal, Popconfirm, Space, Steps, Tag, Tooltip } from 'antd'
import { DislikeFilled, LikeFilled } from '@ant-design/icons'
import _ from 'lodash'

import { ExpenseForm } from '../../Components/Forms'
import { DeleteItem, DrawerComponent, Spinner, ModalView } from '../../Components'
import { approveExpense, deleteExpenseById, getExpenseById, updateExpense } from '../../Services/expenses'

import accounting from '../../Controllers/accountingNumbers'
import * as Colors from '../../styles/colors'
import { ExpenseStatus, StepStatus, drawerData, segmentOptions } from '../../Content/expenses'
import dateFormat from '../../Content/dateFormat'
import { randomColorGenerator } from '../../Content/projects'
import { ERROR_MESSAGE } from '../../Content/messages'

import { deleteExpense } from '../../Store/Actions/expenses'
import { refreshComponent } from '../../Store/Actions/userSession'
import PettyCashReportStats from '../../Components/PettyCashReportStats'
import PettyCashForm from '../../Components/Forms/PettyCashForm'

const SingleExpense = ({ id, closeDrawer, currentView, financeView }) => {
	const personalExpense = currentView === segmentOptions.yourExpenses
	const { token } = useSelector(state => state.userSession)
	const [editDrawer, setEditDrawer] = useState(false)
	const [resubmitDrawer, setResubmitDrawer] = useState(false)
	const [data, setData] = useState('')
	const [errorCode, setErrorCode] = useState(null)
	const [modalView, setModalView] = useState(null)
	const [loading, setLoading] = useState(false)
	const [remarks, setRemarks] = useState('')
	const [pettyReport, setPettyReport] = useState(null)
	const [disbursementDrawer, setDisbursementDrawer] = useState(false)

	const { REQUESTED, DECLINED, APPROVED, CLOSE_OUT } = ExpenseStatus

	const isEditable = data?.status === REQUESTED
	// the expense can only be edited if no actions have been taken in the approval process. once it is approved even by a single manager, we cannot edit it further.

	const isApprovable = [REQUESTED, 'Queued'].includes(currentView)
	// the expense is approvable if it is in the 'requested' or 'queued' stage for the logged in user.

	const declinationItem = data?.approvals?.filter(a => a?.status === DECLINED)[0]
	// details of the declined item in the approvals array

	const pettyBalance = pettyReport && pettyReport.total_cash_issued - pettyReport.total_close_out_expenses
	const netAmount = data && pettyBalance - data.amount

	const dispatch = useDispatch()

	const fetchExpenseData = async () => {
		try {
			const { data: expenseData } = await getExpenseById(token, id)
			if (expenseData?.data?.access_specifier === 'Private') {
				setErrorCode(403)
			} else if (expenseData.data) {
				setData(expenseData.data)
			} else {
				setErrorCode(401)
			}
		} catch (error) {
			setErrorCode(error?.response?.data?.status || 401)
			message.error('The entity could not be loaded.')
		}
	}

	useEffect(() => {
		fetchExpenseData()
	}, [editDrawer])

	const handleDelete = async ({ noMessage }) => {
		try {
			await deleteExpenseById(token, id)
			dispatch(deleteExpense(id))
			if (!noMessage) {
				message.success('Expense deleted successfully!')
			}
			closeDrawer()
		} catch (error) {
			message.error(error?.response?.data?.message || 'Item could not be deleted. Please try again!')
		}
	}

	const handleApproval = async status => {
		const dataObject = {
			id,
			status,
			remarks,
			date: moment().unix()
		}
		setLoading(true)
		try {
			await approveExpense(token, dataObject)
			message.success(`${status} successfully!`)
			closeDrawer()
			dispatch(refreshComponent())
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
		} finally {
			setLoading(false)
		}
	}

	const handleCloseOut = async () => {
		const dataObject = {
			id,
			status: CLOSE_OUT
		}
		try {
			await updateExpense(token, dataObject)
			message.success(`Expense updated successfully!`)
			closeDrawer()
			dispatch(refreshComponent())
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
		}
	}

	return data ? (
		<div>
			<div className='px-10'>
				<Steps
					items={
						data?.approvals &&
						data.approvals.map(a => ({
							title: a.name,
							description: `${a.status} ${a.date !== 0 ? ` on ${moment.unix(a.date).format(dateFormat)}` : ''}`,
							status: StepStatus[a.status]
						}))
					}
				/>
			</div>
			<div className='py-2'>
				{data?.status === APPROVED && (
					<Alert
						message='The expense has been approved!'
						description={data?.client_claim && 'The expense must be added in client invoice as well.'}
						type='success'
						showIcon
					/>
				)}
				{data?.status === CLOSE_OUT && <Alert message='The expense has been closed out!' type='success' showIcon />}
				{data?.status === DECLINED && (
					<Alert
						message='The expense has been declined!'
						description={
							declinationItem.remark
								? `Remark: ${declinationItem.remark}`
								: 'No remark was added while declining this expense.'
						}
						type='error'
						showIcon
					/>
				)}
			</div>
			<div className='flex justify-end items-center py-2'>
				<div className='flex space-x-2'>
					<DrawerComponent
						form={<ExpenseForm edit handleClose={() => setEditDrawer(false)} data={data} />}
						visible={editDrawer}
						onOpen={() => setEditDrawer(true)}
						onClose={() => setEditDrawer(false)}
						buttonTitle='Edit'
						buttonType='primary'
						drawerWidth={1000}
						isHidden={!personalExpense || !isEditable}
					/>
					<DrawerComponent
						form={
							<ExpenseForm
								handleClose={() => {
									handleDelete({ noMessage: true })
									setResubmitDrawer(false)
								}}
								data={{
									..._.omit(data, ['id', 'approvals', 'updated_at', 'created_at', 'association_name']),
									status: REQUESTED
								}}
							/>
						}
						visible={resubmitDrawer}
						onOpen={() => setResubmitDrawer(true)}
						onClose={() => setResubmitDrawer(false)}
						buttonTitle='Resubmit'
						buttonType='primary'
						drawerWidth={1000}
						isHidden={!personalExpense || data?.status !== DECLINED}
					/>
					{isApprovable && (
						<div className='flex space-x-2'>
							<Button
								type='primary'
								onClick={() => {
									setModalView('approve')
								}}
								icon={<LikeFilled />}
							>
								Approve
							</Button>
							<Modal
								title='Approve Expense'
								open={modalView === 'approve'}
								onOk={() => handleApproval(APPROVED)}
								onCancel={() => {
									setModalView(null)
								}}
								closable={false}
								okText='Approve'
								okButtonProps={{ loading }}
							>
								<Input.TextArea
									placeholder='Any remarks for approval?'
									rows={2}
									onChange={e => {
										setRemarks(e.target.value)
									}}
								/>
							</Modal>
							<Button
								type='secondary'
								danger
								onClick={() => {
									setModalView('decline')
								}}
								icon={<DislikeFilled />}
							>
								Decline
							</Button>
							<Modal
								title='Decline Expense'
								open={modalView === 'decline'}
								onOk={() => handleApproval(DECLINED)}
								onCancel={() => {
									setModalView(null)
								}}
								closable={false}
								okText='Decline'
								okType='danger'
								okButtonProps={{ loading }}
							>
								<Input.TextArea
									placeholder='Why are you declining this expense?'
									rows={2}
									onChange={e => {
										setRemarks(e.target.value)
									}}
								/>
							</Modal>
						</div>
					)}
				</div>
			</div>

			<Divider />
			<div>
				<div className='text-3xl font-semibold text-center text-bell-text'> USD {accounting(data.amount)}</div>
				<div className='text-lg font-medium text-center text-bell-text'> {data.title}</div>
				<div className='text-center text-xs'>
					<Space>
						<Space>
							<Avatar
								style={{ backgroundColor: randomColorGenerator(data.employee_name, 30, 50) }}
								className='uppercase'
							>
								{data.employee_name?.split(' ')[0][0]}
								{data.employee_name?.split(' ')[1] && data.employee_name.split(' ')[1][0]}
							</Avatar>
							{data.employee_name}
						</Space>
						<Divider type='vertical' />
						<div>{moment.unix(data.expense_date).format(dateFormat)}</div>
					</Space>
				</div>
				<div className='pt-2 text-center'>
					<Space>
						{data.client_claim && (
							<Tooltip title='To be invoiced to the client.'>
								<Tag color={Colors.GREEN}> From Client</Tag>
							</Tooltip>
						)}
					</Space>
				</div>
			</div>
			{financeView && data.status === APPROVED && data.employee && (
				<>
					<Divider />
					<PettyCashReportStats
						employeeId={data?.employee}
						handleReport={e => setPettyReport(e)}
						thisExpense={data?.amount}
						refresh={disbursementDrawer}
					/>
				</>
			)}
			{financeView && data.status === APPROVED && pettyReport && (
				<div className='py-2'>
					<div className='text-center'>
						{netAmount >= 0
							? 'You can directly close out this expense. Closing out this expense would mean disbursement of expense amount from the balance.'
							: `In order to close out this expense, you need to issue a further amount of ${accounting(
									Math.abs(netAmount)
							  )}`}
					</div>
					<div className='py-4 flex justify-center'>
						<DrawerComponent
							form={
								<PettyCashForm
									data={{
										employee_id: data?.employee,
										employee_name: data.employee_name,
										issued_on: moment().unix(),
										amount: Math.abs(netAmount)
									}}
									handleClose={() => {
										setDisbursementDrawer(false)
									}}
									disableEmployeeInput
								/>
							}
							visible={disbursementDrawer}
							onOpen={() => setDisbursementDrawer(true)}
							onClose={() => setDisbursementDrawer(false)}
							drawerTitle='Issue Disbursement'
							buttonTitle='Issue Disbursement'
							drawerWidth={600}
							buttonType='secondary'
						/>
						<Popconfirm
							title='Are you sure to close out this expense?'
							onConfirm={handleCloseOut}
							okText='Close Out'
							cancelText='Cancel'
							icon={null}
						>
							<Button type='primary' disabled={netAmount < 0}>
								Close Out Expense
							</Button>
						</Popconfirm>
					</div>
				</div>
			)}
			<Divider />

			{/* data display */}
			<ModalView data={drawerData(data)} />

			{/* delete expense */}
			{personalExpense && data.status !== CLOSE_OUT && (
				<div className='absolute bottom-5 w-11/12 bg-white text-center'>
					<DeleteItem
						onDelete={handleDelete}
						popTitle='Are you sure to delete this expense? All approvals and reimbursements will be lost.'
					>
						Delete this expense
					</DeleteItem>
				</div>
			)}
		</div>
	) : (
		<Spinner text='Loading..' errorCode={errorCode} />
	)
}

export default SingleExpense

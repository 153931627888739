/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import moment from 'moment'
import dateFormat from './dateFormat'

export const activityOptions = {
	purpose: ['Cold Call', 'Prospecting', 'Administrative', 'Negotiation', 'Demo', 'Feedback', 'Project', 'Support'],
	type: ['Inbound', 'Outbound', 'Missed Call'],
	activity_type: ['Call', 'Email', 'Other']
}

export const timelineColumns = [
	{
		title: 'Date',
		key: 'date',
		render: text => <div> {moment.unix(text.created_at).format(dateFormat)}</div>
	},
	{
		title: 'Activity Type',
		dataIndex: 'activity_type',
		key: 'activity_type'
	},
	{
		title: 'Subject',
		dataIndex: 'subject',
		key: 'subject'
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description'
	},
	{
		title: 'Logged By',
		dataIndex: 'created_by_name',
		key: 'created_by_name'
	}
]
export const activityColumns = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date'
	},

	{
		title: 'Activity Type',
		dataIndex: 'activity_type',
		key: 'activity_type'
	},

	{
		title: 'Details',
		dataIndex: 'details',
		key: 'details'
	}
]

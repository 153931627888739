import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Popconfirm, Space, message } from 'antd'
import BulkUpload from './BulkUpload'
import { DrawerComponent, InternalHeader, Stats, DataView } from '../../Components'
import { deleteEmployee, resetEmployee } from '../../Store/Actions/employees'
import { getAllUsers, updateUser } from '../../Services/user'
import { getEmployeeStats } from '../../Services/stats'
import { EmployeeForm } from '../../Components/Forms'

const Employees = () => {
	const dispatch = useDispatch()
	const employeesData = useSelector(state => state.employeesData)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [search, setSearch] = useState('')
	const [stats, setStats] = useState(null)
	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const [addEmployeeDrawer, setAddEmployeeDrawer] = useState(false)
	const [bulkUploadDrawer, setBulkUploadDrawer] = useState(false)
	const [editEmployeeDrawer, setEditEmployeeDrawer] = useState(false)
	const userSession = useSelector(state => state.userSession)

	const fetchData = async (token, current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllUsers(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetEmployee([]))
			} else {
				dispatch(resetEmployee(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			userSession.token,
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order] || pagination.sortOrder,
			sorter.field || pagination.sortField,
			search
		)
	}

	useEffect(() => {
		fetchData(userSession.token, 0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async token => {
		try {
			const { data } = await getEmployeeStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		getStats(userSession.token)
		// eslint-disable-next-line
	}, [employeesData])

	const statsData = {
		title: 'Employees',
		total: stats ? stats.active - stats.admin : null,
		data: []
	}
	const columns = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
			sorter: true,
			key: 'first_name'
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			sorter: true,
			key: 'last_name'
		},
		{
			title: 'Department',
			dataIndex: 'department',
			sorter: true,
			key: 'department'
		},
		{
			title: 'Designation',
			dataIndex: 'designation_name',
			key: 'designation_name'
		},
		{
			title: 'Email Address',
			dataIndex: 'email',
			sorter: true,
			key: 'email'
		},
		{
			title: 'Reports To',
			dataIndex: 'reports_to_name',
			key: 'reports_to_name'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						form={<EmployeeForm edit handleClose={() => setEditEmployeeDrawer(false)} data={record} />}
						visible={editEmployeeDrawer[record.id]}
						onOpen={() => setEditEmployeeDrawer({ [record.id]: true })}
						onClose={() => setEditEmployeeDrawer(false)}
						buttonTitle='Edit'
						buttonType='link'
						drawerWidth='700'
					/>
					<Popconfirm
						title={
							<div className='w-72'>
								Are you sure to deactivate{' '}
								<strong>
									{record.first_name} {record.last_name}
								</strong>
								?<div>The user will be unable to login but the data associated with the user will still remain.</div>
							</div>
						}
						showArrow
						icon={false}
						okText='Deactivate'
						okButtonProps={{ danger: true }}
						onConfirm={async () => {
							try {
								await updateUser(userSession.token, { id: record.id, account_status: 'Inactive' })
								dispatch(deleteEmployee(record.id))
								message.success('Employee deactivated successfully!')
							} catch (error) {
								message.error('Something wrong happened. Please try again!')
							}
						}}
					>
						<Button type='link' danger>
							Deactivate
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	]
	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Employees' selected='Employees' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Employees </div>
				<div className='flex space-x-4'>
					<div>
						<DrawerComponent
							visible={bulkUploadDrawer}
							onOpen={() => setBulkUploadDrawer(true)}
							onClose={() => setBulkUploadDrawer(false)}
							buttonTitle='Bulk Upload'
							buttonType='secondary'
							drawerWidth='500'
							form={<BulkUpload handleClose={() => setBulkUploadDrawer(false)} />}
						/>
					</div>

					<div>
						<DrawerComponent
							visible={addEmployeeDrawer}
							onOpen={() => setAddEmployeeDrawer(true)}
							onClose={() => setAddEmployeeDrawer(false)}
							buttonTitle='Add Employee'
							drawerWidth='700'
							form={<EmployeeForm handleClose={() => setAddEmployeeDrawer(false)} />}
						/>
					</div>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={statsData} nograph />
				</div>
			</div>
			<div className='px-10 mt-10'>
				{/* <TableComponent columns={columns} data={employeesData} pagination={pagination} handleChange={handleChange} /> */}
				<DataView
					columns={columns}
					data={employeesData}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	)
}

export default Employees

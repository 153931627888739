import React, { useState } from 'react'
import { Button, Form, Input, message, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'

import { addRevision } from '../../../Store/Actions/enquiries'
import { initRevision } from '../../../Services/Sales/enquiries'

import { URL } from '../../../config'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { refreshComponent } from '../../../Store/Actions/userSession'

const RevisionForm = ({ handleClose }) => {
	const { token } = useSelector(state => state.userSession)
	const { id, current_revision_id } = useSelector(state => state.singleEnquiriesData)
	const revisionsData = useSelector(state => state.revisionsData)

	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const [uploadedFiles, setUploadedFiles] = useState([])

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			enquiry_id: id,
			current_revision_id,
			attachments: uploadedFiles
		}
		try {
			const { data } = await initRevision(token, dataObject)
			dispatch(
				addRevision({ created_at: moment().unix(), id: data.data.id, revision_stage: 'Registration', ...dataObject })
			)
			dispatch(refreshComponent())
			// ADD ACTIVITY
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Revision ${revisionsData.length + 1} Initiated`,
				description: values.abandon_reason
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Revision added successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}
	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: true,
		onChange(info) {
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				setUploadedFiles([...uploadedFiles, info.file.response.data.name])
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		}
	}

	return (
		<div className='space-y-20'>
			{/* SCOPE AND EVALUATE AN ENQUIRY FORM */}
			<Form name='control-hooks' form={form} onFinish={onAdd} {...formItemLayout} scrollToFirstError>
				<Form.Item label='Revision Number' name='revision_number' initialValue={revisionsData.length + 1}>
					<Input disabled />
				</Form.Item>

				<Form.Item
					label='Reason for Revision'
					name='abandon_reason'
					rules={[{ required: true, message: 'Please input reason for revision!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Upload {...props}>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Upload>
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default RevisionForm

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message, Tag, Tooltip } from 'antd'
import { AiTwotoneEdit } from 'react-icons/ai'

import moment from 'moment'
import _ from 'lodash'
import * as Colors from '../../../styles/colors'

// COMPONENTS
import {
	Details,
	Information,
	LeadStage,
	LogInformation,
	Notes,
	Overview,
	Title,
	Files,
	ActivityTimeline,
	TasksView
} from '../../../Components/Views'
import { DrawerComponent, InternalHeader, Spinner } from '../../../Components'
import { addSingleLead, updateSingleLead } from '../../../Store/Actions/leads'
import { leadStages } from '../../../Content/leads'
import { getLeadById, updateLead } from '../../../Services/Sales/leads'
import { LeadForm, TaskForm } from '../../../Components/Forms'
import renderShareTag from '../../../Controllers/shareTag'
import ShareModal from '../../../Components/ShareModal'
import ExpensesView from '../../../Components/Views/Details/ExpensesView'

const SingleLead = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')
	const { token, id: userId } = useSelector(state => state.userSession)
	const singleLeadsData = useSelector(state => state.singleLeadsData)
	const refreshComponent = useSelector(state => state.refreshComponent)
	const [editLeadDrawer, setEditLeadDrawer] = useState(false)
	const [shareLeadDrawer, setShareLeadDrawer] = useState(false)

	const [lead, setLead] = useState('')

	const accessType = lead?.access_specifier

	const fetchSingleLead = async () => {
		try {
			const { data } = await getLeadById(token, id)
			if (!data.data) {
				history.push('/sales/leads')
			}
			setLead(data.data)
			dispatch(addSingleLead(data.data))
		} catch (error) {
			history.push('/sales/leads')
		}
	}
	useEffect(() => {
		fetchSingleLead()
		// eslint-disable-next-line
	}, [editLeadDrawer, refreshComponent])

	const handleFiles = async (currentAttachment, fileName) => {
		try {
			await updateLead(token, {
				id: singleLeadsData.id,
				attachments: [...singleLeadsData.attachments, currentAttachment]
			})
			dispatch(
				updateSingleLead({
					attachments: [...singleLeadsData.attachments, currentAttachment]
				})
			)
			message.success(`${fileName} uploaded successfully`)
		} catch (error) {
			message.error(`Uploading ${fileName} failed. Try again.`)
		}
	}
	const handleRemoveAsset = async fileName => {
		try {
			await updateLead(token, {
				id: singleLeadsData.id,
				attachments: singleLeadsData.attachments.filter(x => x !== fileName)
			})
			dispatch(
				updateSingleLead({
					attachments: singleLeadsData.attachments.filter(x => x !== fileName)
				})
			)
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: singleLeadsData.id,
				...sharingData
			}
			await updateLead(token, dataObject)
			dispatch(updateSingleLead(sharingData))
			message.success(`Lead information updated successfully`)
			setShareLeadDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}
	const overviewData = {
		..._.omit(lead, ['lead_account_name', 'lead_owner_name', 'working_data', 'nurturing_data', 'closed_data']),
		account_name: <Link to={`./accounts?id=${lead?.account_name}`}>{lead?.lead_account_name}</Link>,
		lead_owner: lead?.lead_owner_name,
		date_of_birth: lead.date_of_birth ? moment.unix(lead.date_of_birth).format('DD MMM YYYY') : undefined,
		mobile_phone: lead.mobile_phone ? <a href={`tel:${lead.mobile_phone}`}>{lead.mobile_phone}</a> : null,
		official_email_address: lead.official_email_address && (
			<a href={`mailto:${lead.official_email_address}`}> {lead.official_email_address}</a>
		),
		personal_email_address: lead.personal_email_address && (
			<a href={`mailto:${lead.personal_email_address}`}> {lead.personal_email_address}</a>
		),
		fax: lead.fax && <a href={`tel:${lead.fax}`}>{lead.fax}</a>,
		office_phone: lead.office_phone && <a href={`tel:${lead.office_phone}`}>{lead.office_phone}</a>,
		other_phone: lead.other_phone && <a href={`tel:${lead.other_phone}`}>{lead.other_phone}</a>,
		home_phone: lead.home_phone && <a href={`tel:${lead.home_phone}`}>{lead.home_phone}</a>,
		linkedin: lead.linkedin && <a href={`https://www.linkedin.com/in/${lead.linkedin}/`}>{lead.linkedin}</a>,
		twitter_id: lead.twitter_id && <a href={`https://twitter.com/${lead.twitter_id}/`}>{lead.twitter_id}</a>
	}
	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: (
									<Files
										id={id}
										attachments={singleLeadsData?.attachments}
										handleFiles={handleFiles}
										handleRemove={handleRemoveAsset}
										accessType={accessType}
										tabView
									/>
								)
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={singleLeadsData?.id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Leads' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							},
							{
								name: 'New Event'
							}
						]}
					/>
				)
			}
		]
	}
	return (
		<div>
			<InternalHeader title='Sales' selected='Leads' />
			{lead ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<Title
						title='Lead'
						name={`${singleLeadsData.first_name} ${singleLeadsData.last_name}`}
						hasSubtitle
						extraDetail={
							<div>
								<div className='text-bell-gray'>{singleLeadsData.title}</div>
								<div>
									{lead?.lead_source === 'Website' && (
										<Tooltip
											placement='bottomLeft'
											title='The lead has been added automatically from Bell Energy website as this person tried to contact us!'
										>
											<span>
												<Tag color={Colors.LIGHT_BLUE}>Website</Tag>
											</span>
										</Tooltip>
									)}
									<Tag color={userId === lead?.lead_owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType: lead?.access_type,
											isOwner: userId === lead?.lead_owner,
											accessType
										})}
									</Tag>
								</div>
							</div>
						}
						editButton={
							<DrawerComponent
								form={
									<LeadForm
										edit
										handleClose={() => {
											setEditLeadDrawer(false)
										}}
										data={lead}
									/>
								}
								visible={editLeadDrawer}
								onOpen={() => setEditLeadDrawer(true)}
								onClose={() => setEditLeadDrawer(false)}
								buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
								buttonType='link'
								drawerTitle='Edit Lead'
								isHidden={accessType !== 'Public'}
							/>
						}
						shareButton={
							<ShareModal
								visible={shareLeadDrawer}
								onOpen={() => setShareLeadDrawer(true)}
								onClose={() => setShareLeadDrawer(false)}
								modalTitle='Share Lead'
								isHidden={accessType !== 'Public'}
								handleValues={handleSharing}
								data={lead}
							/>
						}
						overview={[
							{
								title: 'Account Name',
								value: <Link to={`./accounts?id=${lead?.account_name}`}>{lead?.lead_account_name}</Link>
							},
							{
								title: 'Email Address',
								value: <a href={`mailto:${lead?.official_email_address}`}>{lead?.official_email_address}</a>
							},
							{
								title: 'Rating',
								value: lead?.rating
							},
							{
								title: 'Lead Owner',
								value: lead?.lead_owner_name
							}
						]}
					/>
					{(accessType === 'Public' || accessType === 'Protect') && (
						<LeadStage
							stages={leadStages}
							id={id}
							closed={lead?.closed}
							data={singleLeadsData}
							editable={accessType === 'Public'}
						/>
					)}
					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spinner />
			)}
		</div>
	)
}

export default SingleLead

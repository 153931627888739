import React, { useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, InputNumber, message, Radio, Space } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import SelectDebounce from '../SelectDebounce'
import { fetchContactsList, fetchContractsList, fetchEnquiriesList } from '../../Controllers/fetchLists'
import { ContactForm } from '.'
import { Files } from '../Views'
import { addBG } from '../../Store/Actions/bank-guarantees'
import { getGuaranteeById, registerGuarantee, updateGuarantee } from '../../Services/guarantees'
import { objectDifference } from '../../Controllers/objectDifference'
import { getContractById } from '../../Services/Contracts/contracts'
import { getEnquiryById } from '../../Services/Sales/enquiries'
import dateFormat from '../../Content/dateFormat'

const BankGuaranteeForm = ({ edit, handleClose, data, associationData, type, handleValues }) => {
	const formData = data
		? {
				...data,
				association_id: {
					key: data?.association_id,
					label: data?.association_name,
					value: data?.association_id
				},
				bank_contact_id: {
					key: data.bank_contact_id,
					label: data.bank_contact_name,
					value: data.bank_contact_id
				},
				client_contact_id: {
					key: data.client_contact_id,
					label: data.client_contact_name,
					value: data.client_contact_id
				},
				issue_date: data.issue_date !== 0 ? moment.unix(data.issue_date) : undefined,
				expiration_date: data.expiration_date !== 0 ? moment.unix(data.expiration_date) : undefined,
				open_guarantee: data.open_guarantee ? 'Yes' : 'No'
		  }
		: false
	// Type : Bank Guarantee || Tender Bond

	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [evidenceFieldDisplay, setEvidenceFieldDisplay] = useState(
		data?.status === 'Released' || data?.status === 'Forfeited'
	)

	const [uploadedFiles, setUploadedFiles] = useState({
		attachments: data?.attachments || [],
		supporting_docs: data?.supporting_docs || [],
		evidence_docs: data?.evidence_docs || []
	})
	const selectWidth = 400
	const showDrawer = (e, q) => {
		setVisible(q)
	}
	const onClose = () => {
		setVisible('')
	}
	const dataObject = values => ({
		...values,
		type,
		open_guarantee: values.open_guarantee === 'Yes',
		association_id: associationData?.id || values?.association_id?.value,
		bank_contact_id: values?.bank_contact_id?.value || '',
		client_contact_id: values?.client_contact_id?.value || '',
		expiration_date: values.expiration_date ? moment(values?.expiration_date).unix() : 0,
		issue_date: values.issue_date ? moment(values?.issue_date).unix() : 0,
		...uploadedFiles
	})
	const onAdd = async values => {
		setLoading(true)
		try {
			const { data: responseData } = await registerGuarantee(token, dataObject(values))
			const { id: guaranteeId } = responseData.data
			const res = await getGuaranteeById(token, guaranteeId)
			dispatch(addBG(res.data.data))
			if (handleValues) {
				handleValues(res.data.data)
			}
			message.success(`${type} added successfully!`)
			form.resetFields()
			setUploadedFiles({
				attachments: data?.attachments || [],
				supporting_docs: data?.supporting_docs || [],
				evidence_docs: data?.evidence_docs || []
			})
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}
	const onEdit = async values => {
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject(values))
			await updateGuarantee(token, { id: data.id, ...updatedData })
			const res = await getGuaranteeById(token, data.id)
			if (handleValues) {
				handleValues(res.data.data)
			}
			message.success(`${type} updated successfully!`)
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	const getDrawerComponent = () => {
		switch (visible) {
			case "Add Client's Contact":
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(_, one) => {
							form.setFieldsValue({
								client_contact_id: {
									key: one.id,
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case "Add Bank's Contact":
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(_, one) => {
							form.setFieldsValue({
								bank_contact_id: {
									key: one.id,
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => 700

	const handleFiles = async (currentAttachment, fileName, type) => {
		setUploadedFiles(prev => ({
			...prev,
			[type]: [...prev[type], currentAttachment]
		}))
		message.success(`${fileName} uploaded successfully`)
	}
	const handleRemove = async (fileName, type) => {
		try {
			setUploadedFiles(prev => ({
				...prev,
				[type]: prev[type].filter(x => x !== fileName)
			}))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleChange = () => {
		const status = form.getFieldValue('status')
		if (status === 'Released' || status === 'Forfeited') {
			setEvidenceFieldDisplay(true)
		}
		if (status === 'Active') {
			setEvidenceFieldDisplay(false)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
				onChange={handleChange}
			>
				{!associationData && (
					<div className='py-2 italic text-bell-gray'>
						{type === 'Bank Guarantee' ? 'Contract' : 'Enquiry'} Information
					</div>
				)}
				{type === 'Bank Guarantee' ? (
					<Form.Item
						label='Contract'
						name='association_id'
						hidden={associationData}
						rules={
							!associationData
								? [
										{
											validator: async (_, value) => {
												if (!value && !associationData) {
													return Promise.reject(new Error('Please select a contract!'))
												}
												const {
													data: { data }
												} = await getContractById(token, value.value)
												if (data?.access_specifier !== 'Public') {
													return Promise.reject(new Error("You don't have write access to the selected contract!"))
												}
												return Promise.resolve()
											}
										}
								  ]
								: []
						}
					>
						<SelectDebounce
							showSearch
							placeholder='Search For Contracts'
							fetchOptions={e => fetchContractsList(e, token)}
						/>
					</Form.Item>
				) : (
					<Form.Item
						label='Enquiry'
						name='association_id'
						hidden={associationData}
						rules={
							!associationData
								? [
										{
											validator: async (_, value) => {
												if (!value && !associationData) {
													return Promise.reject(new Error('Please select a contract!'))
												}
												const {
													data: { data }
												} = await getEnquiryById(token, value.value)
												if (data?.access_specifier !== 'Public') {
													return Promise.reject(new Error("You don't have write access to the selected enquiry!"))
												}
												return Promise.resolve()
											}
										}
								  ]
								: []
						}
					>
						<SelectDebounce
							showSearch
							placeholder='Search For Enquiries'
							fetchOptions={e => fetchEnquiriesList(e, token)}
						/>
					</Form.Item>
				)}
				<div className='py-2 italic text-bell-gray'>{type} Information</div>
				<Form.Item label='Bank Name' name='name' rules={[{ required: true, message: 'Please input bank name!' }]}>
					<Input placeholder='Bank Name' />
				</Form.Item>
				<Form.Item label='Bank Address' name='address'>
					<Input placeholder='Bank Address' />
				</Form.Item>
				<Form.Item label={`${type} Amount`} name='amount'>
					<InputNumber placeholder='USD' />
				</Form.Item>
				<Form.Item label='Issue Date' name='issue_date'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Expiration Date' name='expiration_date'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label="Client's Contact">
					<Space>
						<Form.Item name='client_contact_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, "Add Client's Contact")} type='link'>
							Add Client&apos;s Contact
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label="Bank's Contact">
					<Space>
						<Form.Item name='bank_contact_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, "Add Bank's Contact")} type='link'>
							Add Bank&apos;s Contact
						</Button>
					</Space>
				</Form.Item>
				<Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please pick an item!' }]}>
					<Radio.Group>
						<Radio.Button value='Active'>Active</Radio.Button>
						<Radio.Button value='Released'>Released</Radio.Button>
						<Radio.Button value='Forfeited'>Forfeited</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name='open_guarantee'
					label={`Is this an open ${type === 'Bank Guarantee' ? 'guarantee' : 'tender bond'}?`}
					rules={[{ required: true, message: 'Please pick an item!' }]}
				>
					<Radio.Group>
						<Radio.Button value='Yes'>Yes</Radio.Button>
						<Radio.Button value='No'>No</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Attachments</div>
				<Form.Item label={`Insert ${type}`} name='attachments'>
					<Files
						attachments={uploadedFiles.attachments}
						handleFiles={(a, b) => handleFiles(a, b, 'attachments')}
						handleRemove={a => handleRemove(a, 'attachments')}
					/>
				</Form.Item>
				<Form.Item label='Supporting Documents' name='supporting_docs'>
					<Files
						attachments={uploadedFiles.supporting_docs}
						handleFiles={(a, b) => handleFiles(a, b, 'supporting_docs')}
						handleRemove={a => handleRemove(a, 'supporting_docs')}
					/>
				</Form.Item>
				{evidenceFieldDisplay && (
					<Form.Item label='Evidence Documents' name='evidence_docs'>
						<Files
							attachments={uploadedFiles.evidence_docs}
							handleFiles={(a, b) => handleFiles(a, b, 'evidence_docs')}
							handleRemove={a => handleRemove(a, 'evidence_docs')}
						/>
					</Form.Item>
				)}
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default BankGuaranteeForm

/* eslint-disable react/no-unstable-nested-components */
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { refreshComponent } from '../../../Store/Actions/userSession'
import DrawerComponent from '../../DrawerComponent'
import InvoicingPlanForm from '../../Forms/Contracts/InvoicingPlanForm'
import PrintHeading from '../../PrintHeading'

const InvoicingPlanView = ({ contractData, planId, planData, accessType }) => {
	const dispatch = useDispatch()
	const [invoicingPlanDrawer, setInvoicingPlanDrawer] = useState(false)

	// product display name is also included inside data array for tabular representation
	const tableData = planData
		.map(product => [...product.data.map(x => ({ ...x, ..._.omit(product, ['data']) }))])
		.reduce((a, b) => [...a, ...b], [])

	const columns = [
		{
			title: 'Product / Service',
			dataIndex: 'display_name',
			render: (_, record) =>
				contractData?.ps?.filter(one => one?.ps_group_id === record?.ps_group_id)[0]?.display_name || (
					<div className='text-bell-red'>NA</div>
				)
		},
		{
			title: 'Deliverable',
			dataIndex: 'deliverable'
		},
		{
			title: 'Project Milestone',
			dataIndex: 'project_milestone'
		},
		{
			title: 'Est. Submission Month',
			dataIndex: 'submission_month',
			width: '10%',
			render: (_, record) => (record.submission_month ? moment.unix(record.submission_month).format('MMM YY') : '-')
		},
		{
			title: 'Invoicing Milestone',
			key: 'invoicing_milestone',
			dataIndex: 'invoicing_milestone',
			width: '10%',
			render: (_, record) => record.invoicing_milestone && `${record.invoicing_milestone}%`
		},
		{
			title: 'Est. Invoicing Month',
			dataIndex: 'invoicing_month',
			width: '10%',
			render: (_, record) => (record.invoicing_month ? moment.unix(record.invoicing_month).format('MMM YY') : '-')
		},
		{
			title: 'Est. Payment Receipt Month',
			key: 'receipt_month',
			dataIndex: 'receipt_month',
			width: '10%',
			render: (_, record) => (record.receipt_month ? moment.unix(record.receipt_month).format('MMM YY') : '-')
		}
	]

	const componentRef = useRef()

	const ComponentToPrint = React.forwardRef((_, ref) => (
		<div ref={ref}>
			<PrintHeading title='Project & Invoicing Plan' contractData={contractData} />
			<Table dataSource={tableData} columns={columns} pagination={false} />
		</div>
	))
	return (
		<div>
			<div className='flex flex-row-reverse mb-4'>
				<div className='pl-4'>
					<ReactToPrint
						trigger={() => (
							<Button type='secondary' icon={<PrinterOutlined />}>
								Print Report
							</Button>
						)}
						content={() => componentRef.current}
					/>
				</div>
				<div>
					<DrawerComponent
						form={
							<InvoicingPlanForm
								contractData={contractData}
								handleClose={() => {
									dispatch(refreshComponent())
									setInvoicingPlanDrawer(false)
								}}
								data={planData}
								planId={planId}
							/>
						}
						visible={invoicingPlanDrawer}
						onOpen={() => setInvoicingPlanDrawer(true)}
						onClose={() => setInvoicingPlanDrawer(false)}
						buttonTitle='Invoicing Plan'
						drawerWidth={1300}
						isHidden={accessType !== 'Public'}
					/>
				</div>
			</div>
			<ComponentToPrint ref={componentRef} />
		</div>
	)
}

export default InvoicingPlanView

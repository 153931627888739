import moment from 'moment'
import { useSelector } from 'react-redux'

const w = moment().hour()
const Greetings = () => {
	const { dob } = useSelector(state => state.userProfile)
	if (dob > 0 && moment.unix(dob).format('DD-MM') === moment().format('DD-MM')) {
		return 'Happy Birthday 🎉'
	}
	if (moment().dayOfYear() === 1) {
		return '🎉 Happy New Year'
	}
	if (w > 4 && w <= 12) {
		return 'Good Morning'
	}
	if (w > 12 && w <= 18) {
		return 'Good Afternoon'
	}
	if (w > 18 && w <= 20) {
		return 'Good Evening'
	}
	if (w > 20 || w <= 4) {
		return 'Good Night'
	}
	return 'Welcome'
}

export default Greetings

import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Space, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import _ from 'lodash'
import { Files } from '../../Views'
import { addVariation } from '../../../Store/Actions/contracts'
import { createVariation, getVariationById } from '../../../Services/Contracts/contracts'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import DrawerComponent from '../../DrawerComponent'
import ProductServicesTable from './ProductServicesTable'
import { getInvoiceHistoryByContractId } from '../../../Services/Contracts/invoices'

const VariationsForm = ({ contractData, handleClose }) => {
	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [drawer, setDrawer] = useState(false)
	const [tableData, setTableData] = useState([])

	// fetch invoice history on load in variations form
	useEffect(async () => {
		try {
			const { data: invoiceHistory } = await getInvoiceHistoryByContractId(token, contractData?.id)
			if (contractData?.ps) {
				setTableData(
					contractData.ps.map(one => ({
						...one,
						ps_group_id: { key: one.ps_group_id, label: one.ps_group_name, value: one.ps_group_id },
						history: invoiceHistory?.data[one.key],
						updated: true
					}))
				)
			}
		} catch (error) {
			if (contractData?.ps) {
				setTableData(
					contractData.ps.map(one => ({
						...one,
						ps_group_id: { key: one.ps_group_id, label: one.ps_group_name, value: one.ps_group_id },
						updated: true
					}))
				)
			}
		}
	}, [])

	const dispatch = useDispatch()

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 }
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 }
		}
	}
	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0
			},
			sm: {
				span: 16,
				offset: 4
			}
		}
	}
	const [uploadedFiles, setUploadedFiles] = useState({
		variation_order: [],
		attachments: []
	})

	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			...uploadedFiles,
			contract_id: contractData.id,
			number: contractData.current_variation_data.number + 1,
			ps: tableData?.map(x => ({ ..._.omit(x, ['history']), ps_group_id: x?.ps_group_id?.value || v4() }))
		}
		try {
			const { data } = await createVariation(token, dataObject)
			const res = await getVariationById(token, data.data.id)
			dispatch(addVariation(res.data.data))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: contractData.id,
				activity_type: 'Variation',
				subject: `Variation No. ${contractData.current_variation_data.number + 1} Initiated`,
				description: dataObject.description
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Variation added successfully!')
			form.resetFields()
			setUploadedFiles({
				variation_order: [],
				attachments: []
			})
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='space-y-20'>
			<Form name='control-hooks' form={form} onFinish={onFinish} {...formItemLayout} scrollToFirstError>
				<Divider />
				<Form.Item label='Products & Services' name='ps'>
					<Space>
						<DrawerComponent
							form={
								<ProductServicesTable
									handleClose={() => setDrawer(false)}
									data={tableData.map(q => _.omit(q, ['ps_data']))}
									handleData={w => setTableData(w)}
								/>
							}
							visible={drawer}
							onOpen={() => setDrawer(true)}
							onClose={() => setDrawer(false)}
							buttonTitle='Update Products & Services'
							drawerWidth={1000}
							buttonType='secondary'
						/>
						<Divider type='vertical' />
						<div className='text-gray-600 font-normal'>
							<span className='text-bell-blue text-2xl font-medium'>{tableData?.length || 'No'}</span> Item(s) added.
						</div>
					</Space>
				</Form.Item>
				<Divider />
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} placeholder='Specify the reason for variation or any other information' />
				</Form.Item>
				<Form.Item label='Insert Variation Order' name='variation_order'>
					<Files
						attachments={uploadedFiles.variation_order}
						handleFiles={curr =>
							setUploadedFiles(prev => ({ ...prev, variation_order: [curr, ...prev.variation_order] }))
						}
						handleRemove={name =>
							setUploadedFiles(prev => ({ ...prev, variation_order: prev.variation_order.filter(x => x !== name) }))
						}
					/>
				</Form.Item>
				<Form.Item label='Insert Other Attachments' name='attachments'>
					<Files
						attachments={uploadedFiles.attachments}
						handleFiles={curr => setUploadedFiles(prev => ({ ...prev, attachments: [curr, ...prev.attachments] }))}
						handleRemove={name =>
							setUploadedFiles(prev => ({ ...prev, attachments: prev.attachments.filter(x => x !== name) }))
						}
					/>
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default VariationsForm

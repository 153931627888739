/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Button, Divider, message, Popover, Spin } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { FaStop } from 'react-icons/all'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { v4 } from 'uuid'
import { addTime, currentTime, editTime } from '../Store/Actions/timesheets'
import { timeDuration } from '../Controllers/timeDuration'
import { addTimeEntry, getTimeEntryById, updateTimeEntry } from '../Services/timesheet'
import DrawerComponent from './DrawerComponent'
import TimeEntryForm from './Forms/TimeEntryForm'
import { timeCategory } from '../Content/timetracker'

const TimeTracker = ({ type }) => {
	const { token } = useSelector(state => state.userSession)
	const [modalVisible, setModalVisible] = useState(false)
	const [duration, setDuration] = useState(null)
	const [loading, setLoading] = useState(true)
	const [buttonLoader, setButtonLoader] = useState(false)
	const currentTimeEntry = useSelector(state => state?.currentTimeEntry)

	const dispatch = useDispatch()

	useEffect(() => {
		const startTime = currentTimeEntry ? moment.unix(currentTimeEntry?.start_time) : null
		const timeDifference = startTime ? moment.duration(moment().diff(startTime)) : null
		setTimeout(() => {
			if (currentTimeEntry) {
				setDuration(timeDifference)
			} else {
				setDuration(null)
			}
			setLoading(false)
		}, 1000)
		return clearTimeout()
	})

	// when timer starts
	const onTimerStart = async values => {
		setButtonLoader(true)
		const currentCategory = timeCategory.filter(q => q.name === values.category)[0]
		const dataObject = {
			..._.omit(values, ['time_range', 'date']),
			association_id: values?.association_id?.value,
			description: values?.description || values?.task_id?.label,
			tag: currentCategory.tagName,
			group_id: v4(),
			category: values?.category,
			task_id: values?.task_id?.value,
			start_time:
				values.timer_type === 'Manual' && values?.time_range[0]
					? moment(values?.time_range[0])
						.set({
							year: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).year(),
							month: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).month(),
							date: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).date()
						})
						.unix()
					: moment().unix(),
			end_time:
				values.timer_type === 'Manual' && values?.time_range[1]
					? moment(values?.time_range[1])
						.set({
							year: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).year(),
							month: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).month(),
							date: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).date()
						})
						.unix()
					: undefined
		}

		try {
			const { data } = await addTimeEntry(token, dataObject)
			const res = await getTimeEntryById(token, data.data.id)
			dispatch(addTime(res.data.data))
			if (values.timer_type === 'Timer') {
				dispatch(currentTime({ ...res.data.data, start_time: moment().unix() }))
			}
			setModalVisible(false)
			setButtonLoader(false)
		} catch (error) {
			setButtonLoader(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// when timer stops
	const onTimerStop = async () => {
		setButtonLoader(false)
		const dataObject = { ...currentTimeEntry, end_time: moment().unix() }
		try {
			await updateTimeEntry(token, {
				id: currentTimeEntry.id,
				end_time: moment().unix()
			})
			dispatch(
				editTime({
					id: currentTimeEntry.id,
					data: dataObject
				})
			)
			dispatch(currentTime(null))
			message.success('Time Entry Saved!')
			setDuration(null)
			setButtonLoader(false)
		} catch (error) {
			setButtonLoader(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	return loading ? (
		<Spin />
	) : (
		<div>
			{/* if there is a current ongoing time entry */}
			{currentTimeEntry ? (
				<div className='bg-bell-blue p-2 rounded-sm transition-all duration-300'>
					<div className='flex items-center space-x-2'>
						<div className='text-sm text-white'>{currentTimeEntry?.description}</div>
						{currentTimeEntry.description && <Divider type='vertical' style={{ backgroundColor: 'white' }} />}
						<div className='text-sm font-medium text-white pr-4'>{duration && timeDuration(duration)}</div>
						<div className='cursor-pointer' onClick={onTimerStop}>
							<FaStop fontSize={16} color='white' />
						</div>
					</div>
				</div>
			) : // if the user is in timesheets module
				type !== 'timesheets' ? (
					<Popover
						content={
							<div className='w-72'>
								<TimeEntryForm onFinish={onTimerStart} loading={buttonLoader} />
							</div>
						}
						trigger='click'
						open={modalVisible}
						onOpenChange={() => setModalVisible(prev => !prev)}
					>
						<Button type='primary' icon={<PlayCircleOutlined />}>
							Start Timer
						</Button>
					</Popover>
				) : (
					// for all other pages other than timesheets
					<DrawerComponent
						form={<TimeEntryForm onFinish={onTimerStart} extendedView loading={buttonLoader} />}
						visible={modalVisible}
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						buttonTitle='Add Time Entry'
					/>
				)}
		</div>
	)
}

export default TimeTracker

import { Button, Form, Input, message, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import { activityOptions } from '../../../Content/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { createActivity } from '../../../Services/activity'

const LogInformation = ({ association_id, association_type }) => {
	const [form] = Form.useForm()
	const { TextArea } = Input
	const { Option } = Select
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)

	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			association_id,
			association_type,
			created_at: moment().unix(),
			created_by_name: `${first_name} ${last_name}`,
			...values
		}
		try {
			const { data } = await createActivity(token, dataObject)
			dispatch(addActivity({ id: data.data.id, ...dataObject }))
			setLoading(false)
			message.success('Information logged successfully!')
			form.resetFields()
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
			setLoading(false)
		}
	}

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo)
	}
	return (
		<div className='mt-5'>
			<Form name='control-hooks' onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} {...formItemLayout}>
				<Form.Item
					label='Activity Type'
					name='activity_type'
					rules={[
						{
							required: true,
							message: 'Please input activity type'
						}
					]}
				>
					<Select placeholder='Call'>
						{activityOptions.activity_type.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='Subject'
					name='subject'
					rules={[
						{
							required: true,
							message: 'Please input subject!'
						}
					]}
				>
					<Input placeholder="Call with Lead's assistant" />
				</Form.Item>
				<Form.Item label='Purpose' name='purpose'>
					<Select placeholder='Administrative'>
						{activityOptions.purpose.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Type' name='type'>
					<Select placeholder='Inbound'>
						{activityOptions.type.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Description' name='description'>
					<TextArea rows={6} placeholder='Had a call and we discussed the proposal.' />
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Save
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default LogInformation

import React, { useEffect, useState } from 'react'
import { Button, message, Popconfirm, Steps } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { updateEnquiry } from '../../Services/Sales/enquiries'
import { updateSingleEnquiry } from '../../Store/Actions/enquiries'
import { refreshComponent } from '../../Store/Actions/userSession'
import { CloseEnquiry } from '../Forms'
import { createActivity } from '../../Services/activity'
import { addActivity } from '../../Store/Actions/activity'

const EnquiryStepper = ({ current, display, enquiryID, accessType }) => {
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)

	const enquiryStageData = useSelector(state => state.enquiryStageData)
	const { closed } = useSelector(state => state.singleEnquiriesData)
	const [currentStep, setCurrentStep] = useState(null)
	const [displayModal, setDisplayModal] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		setCurrentStep(enquiryStageData.indexOf(current))
		// eslint-disable-next-line
	}, [])

	const confirm = async (e, i) => {
		const dataObject = {
			enquiry_stage: enquiryStageData[i],
			closed: '',
			closing_description: ''
		}
		setConfirmLoading(true)
		try {
			await updateEnquiry(token, { id: enquiryID, ...dataObject })
			dispatch(updateSingleEnquiry(dataObject))
			dispatch(refreshComponent())
			setConfirmLoading(false)
			setDisplayModal(false)
			message.success('Enquiry stage updated successfully.')

			const activityObject = {
				association_id: enquiryID,
				activity_type: 'Enquiry Stage',
				subject: `Enquiry Stage updated to "${enquiryStageData[i]}"`,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: data.data.id,
					...activityObject
				})
			)
			dispatch(refreshComponent())
			setCurrentStep(i)
			// closeStepper()
		} catch (error) {
			setConfirmLoading(false)
			message.error('Something went wrong.')
		}
	}

	const handleOK = async e => {
		const dataObject = {
			...e,
			enquiry_stage: e.closed === 'Cancelled' ? 'Cancelled' : `Closed (${e.closed})`
		}
		setConfirmLoading(true)
		try {
			await updateEnquiry(token, { id: enquiryID, ...dataObject })
			dispatch(updateSingleEnquiry(dataObject))
			dispatch(refreshComponent())
			setConfirmLoading(false)
			setCurrentStep(enquiryStageData.length + 1)
			const activityObject = {
				association_id: enquiryID,
				activity_type: 'Enquiry Stage',
				subject: `Enquiry Stage updated to "${e.closed === 'Cancelled' ? 'Cancelled' : `Closed (${e.closed})`}"`,
				description: e.closing_description,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: data.data.id,
					...activityObject
				})
			)
			dispatch(refreshComponent())
			setDisplayModal(false)
			// closeStepper()
		} catch (error) {
			console.log(error)
		}
	}

	const items = enquiryStageData.slice(0, 6).map((stage, i) => ({
		title: (
			<Popconfirm
				title='Are you sure to update the enquiry stage?'
				onConfirm={e => confirm(e, i)}
				okText='Yes'
				cancelText='No'
				disabled={accessType === 'Protect'}
				icon={null}
			>
				<div className={accessType === 'Public' ? 'cursor-pointer' : 'cursor-not-allowed'}>{stage}</div>
			</Popconfirm>
		),
		key: i
	}))
	return (
		display && (
			<div className='animate__animated animate__fadeInDown flex space-x-10 items-center'>
				<Steps current={currentStep} size='small' items={items} />
				<div>
					<div>
						{!closed && accessType === 'Public' && (
							<Button type='primary' onClick={() => setDisplayModal(true)}>
								Close Enquiry
							</Button>
						)}
						<CloseEnquiry
							open={displayModal}
							confirmLoading={confirmLoading}
							handleOK={handleOK}
							handleCancel={() => setDisplayModal(false)}
						/>
					</div>
				</div>
			</div>
		)
	)
}

export default EnquiryStepper

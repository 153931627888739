/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
import FullCalendar from '@fullcalendar/react' // must go before plugins

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Button, Divider, message, Popconfirm, Space, Table, Tabs, Tag, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { v4 } from 'uuid'
import { DrawerComponent, InternalHeader } from '../../Components'
import TimeEntryForm from '../../Components/Forms/TimeEntryForm'
import InformationBanner from '../../Components/InformationBanner'
import TimeTracker from '../../Components/TimeTracker'
import dateFormat from '../../Content/dateFormat'
import { associationEnumURL, timeCategory } from '../../Content/timetracker'
import { timeDuration } from '../../Controllers/timeDuration'
import {
	addTimeEntry,
	deleteTimeEntry,
	getAllTimeEntries,
	getCurrentTimeEntry,
	getTimeEntryById,
	updateTimeEntry
} from '../../Services/timesheet'
import { addTime, currentTime, deleteTime, editTime, resetTime } from '../../Store/Actions/timesheets'
import * as Colors from '../../styles/colors'

const TimeSheets = () => {
	// states
	const [tableLoader, setTableLoader] = useState(true)
	const [editDrawer, setEditDrawer] = useState(false)
	const [calendarDrawer, setCalendarDrawer] = useState(false)
	const [timeEntryData, setTimeEntryData] = useState(null)
	const [buttonLoader, setButtonLoader] = useState(false)
	const [restartButton, setRestartButton] = useState(false)
	const [deleteButton, setDeleteButton] = useState(false)
	const currentTimeEntry = useSelector(state => state?.currentTimeEntry)
	const timeSheetsData = useSelector(state => state.timeSheetsData)
	const { token } = useSelector(state => state.userSession)
	const userPreference = useSelector(state => state.userPreference)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 200
	})
	// dispatch
	const dispatch = useDispatch()

	// fetching data for timesheets table
	const fetchData = async (offset, limit) => {
		setTableLoader(true)
		try {
			const { data } = await getAllTimeEntries(token, offset, limit)
			if (!data.data) {
				dispatch(resetTime([]))
			} else {
				dispatch(resetTime(data.data))
				setPagination({
					current: offset ? offset + 1 : 1,
					pageSize: limit,
					total: data.count
				})
			}
			setTableLoader(false)
		} catch (error) {
			setTableLoader(false)
			dispatch(resetTime([]))
		}
	}

	// to know the current entry; (one which does not have an end_time)
	const fetchCurrentEntry = async () => {
		try {
			const { data } = await getCurrentTimeEntry(token)
			if (!data.data) {
				dispatch(currentTime(null))
			} else {
				dispatch(currentTime(data.data))
			}
		} catch (error) {
			dispatch(currentTime(null))
		}
	}

	const handleChange = page => {
		fetchData(page.current - 1, page.pageSize)
	}
	// fetch timesheets data and current entry on drawer changes and first load
	useEffect(() => {
		fetchData()
		fetchCurrentEntry()
	}, [editDrawer])

	// actions: Add Entry in Calendar
	const onAdd = async values => {
		setButtonLoader(true)
		const currentCategory = timeCategory.filter(q => q.name === values.category || 'Project')[0]
		const dataObject = {
			..._.omit(values, ['time_range', 'date', 'task']),
			association_id: values?.association_id?.value,
			association: values.association || 'Project',
			description: values?.description || values?.task_id?.label,
			tag: currentCategory.tagName,
			group_id: v4(),
			category: values?.category || 'Project',
			task_id: values?.task_id?.value,
			start_time:
				values.timer_type === 'Manual' && values?.time_range[0]
					? moment(values?.time_range[0])
							.set({
								year: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).year(),
								month: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).month(),
								date: moment(currentCategory.showDateRange ? values?.time_range[0] : values?.date).date()
							})
							.unix()
					: moment().unix(),
			end_time:
				values.timer_type === 'Manual' && values?.time_range[1]
					? moment(values?.time_range[1])
							.set({
								year: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).year(),
								month: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).month(),
								date: moment(currentCategory.showDateRange ? values?.time_range[1] : values?.date).date()
							})
							.unix()
					: undefined
		}
		try {
			const { data } = await addTimeEntry(token, dataObject)
			const res = await getTimeEntryById(token, data.data.id)
			dispatch(addTime(res.data.data))
			if (values.timer_type === 'Timer') {
				dispatch(currentTime({ ...res.data.data, start_time: moment().unix() }))
			}
			setButtonLoader(false)
			setCalendarDrawer(false)
		} catch (error) {
			setButtonLoader(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// actions: Edit Entry
	const onEdit = async (record, id) => {
		setButtonLoader(true)
		const currentCategory = timeCategory.filter(q => q.name === record.category)[0]
		const dataObject = {
			id,
			..._.omit(record, ['time_range', 'date', 'updated_at', 'created_at']),
			tag: currentCategory.tagName,
			group_id: record?.group_id,
			association_id: record?.association_id?.value,
			task_id: record?.task_id?.value,
			category: record?.category,
			start_time: moment(record?.time_range[0])
				.set({
					year: moment(currentCategory.showDateRange ? record?.time_range[0] : record?.date).year(),
					month: moment(currentCategory.showDateRange ? record?.time_range[0] : record?.date).month(),
					date: moment(currentCategory.showDateRange ? record?.time_range[0] : record?.date).date()
				})
				.unix(),
			end_time: moment(record?.time_range[1])
				.set({
					year: moment(currentCategory.showDateRange ? record?.time_range[1] : record?.date).year(),
					month: moment(currentCategory.showDateRange ? record?.time_range[1] : record?.date).month(),
					date: moment(currentCategory.showDateRange ? record?.time_range[1] : record?.date).date()
				})
				.unix()
		}
		try {
			await updateTimeEntry(token, dataObject)
			dispatch(editTime({ id, data: dataObject }))
			setEditDrawer(false)
			setButtonLoader(false)
		} catch (error) {
			setButtonLoader(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// actions: Restart Entry: Timer Type changes to 'Timer' and Time starts from now. Rest all the details are from the selected entry.
	const onRestart = async record => {
		setRestartButton({ [record.id]: true })
		const dataObject = {
			..._.omit(record, ['created_at', 'updated_at', 'end_time', 'id', 'timer_type', 'user_id', 'duration']),
			timer_type: 'Timer',
			start_time: moment().unix()
		}
		try {
			const { data } = await addTimeEntry(token, dataObject)
			dispatch(addTime({ id: data.data.id, ...dataObject }))
			dispatch(currentTime({ id: data.data.id, ...dataObject }))
			setRestartButton(false)
		} catch (error) {
			setRestartButton(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// actions: Delete Entry
	const onDelete = async record => {
		setDeleteButton({ [record.id]: true })
		const { id } = record
		try {
			await deleteTimeEntry(token, id)
			dispatch(deleteTime(id))
			setDeleteButton(false)
			message.success('Time entry deleted successfully!')
		} catch (error) {
			setDeleteButton(false)
			message.error(error?.response?.data?.message || 'Item could not be deleted. Please try again!')
		}
	}

	// timesheets column
	const columns = [
		{
			title: 'Description',
			key: 'description',
			dataIndex: 'description'
		},
		{
			title: 'Category',
			key: 'category',
			dataIndex: 'category'
		},
		{
			title: 'Associated With',
			key: 'association_name',
			render: (_, record) =>
				record.association_id && record.association_id ? (
					<div>
						<div className='text-xs text-bell-gray uppercase'>{record.association}</div>
						<Link to={`..${associationEnumURL[record.association]}?id=${record.association_id}`}>
							{record.association_name}
						</Link>
					</div>
				) : (
					<div className='border border-bell-blue w-2 ml-6' />
				)
		},
		{
			title: 'Tag',
			key: 'tags',
			dataIndex: 'tags',
			render: (_, rec) => <Tag color={timeCategory.filter(a => a.tagName === rec.tag)[0]?.color}>{rec.tag}</Tag>
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, rec) =>
				rec.start_time && !timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? (
					moment.unix(rec.start_time).format(dateFormat)
				) : (
					<Tag />
				)
		},
		{
			title: 'Start',
			dataIndex: 'start_time',
			key: 'start_time',
			render: (_, rec) =>
				rec.start_time
					? moment
							.unix(rec.start_time)
							.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
					: null
		},
		{
			title: 'End',
			key: 'end_time',
			dataIndex: 'end_time',
			render: (_, rec) =>
				rec.end_time ? (
					moment
						.unix(rec.end_time)
						.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
				) : (
					<Tag color={Colors.GREEN}> In Progress </Tag>
				)
		},
		{
			title: 'Duration',
			key: 'duration',
			dataIndex: 'duration',
			render: (_, rec) => {
				const duration = rec.end_time
					? moment.duration(moment.unix(rec.end_time).diff(moment.unix(rec.start_time)))
					: false

				if (duration) {
					if (timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange) {
						return timeDuration(duration, 'days')
					}
					return timeDuration(duration, 'table')
				}
				return null
			}
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						form={
							<TimeEntryForm onFinish={e => onEdit(e, record.id)} extendedView data={record} loading={buttonLoader} />
						}
						visible={editDrawer[record.id]}
						onOpen={() => setEditDrawer({ [record.id]: true })}
						onClose={() => setEditDrawer(false)}
						buttonTitle='Edit'
						buttonType='link'
						isHidden={!record.end_time}
					/>
					<Tooltip title={currentTimeEntry ? 'You have one work already started!' : null}>
						<Button
							type='link'
							onClick={() => onRestart(record)}
							loading={restartButton[record.id]}
							hidden={!record.end_time}
							disabled={record?.tag === 'Holiday' || currentTimeEntry}
						>
							Restart
						</Button>
					</Tooltip>
					<Popconfirm
						title='Are you sure to delete this time entry?'
						onConfirm={() => onDelete(record)}
						okText='Yes'
						cancelText='No'
						okButtonProps={{ danger: true }}
					>
						<Button type='link' danger loading={deleteButton[record.id]} hidden={!record.end_time}>
							Delete
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	]

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Timesheets' selected='Timesheets' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Timesheets </div>
				{/* timesheet tracker with form */}
				<div>
					<TimeTracker type='timesheets' />
				</div>
			</div>
			<div className=' px-10'>
				<Tabs
					items={[
						{
							label: 'List',
							key: 'item-1',
							children: (
								<div>
									<div className='text-bell-text font-medium text-base pb-2'> Today </div>
									<Table
										columns={columns.filter(q => q.key !== 'date')}
										dataSource={timeSheetsData?.filter(
											a => a.start_time && moment.unix(a.start_time).isSame(moment(), 'day')
										)}
										pagination={false}
										loading={tableLoader}
										summary={pageData => {
											const totalDuration = pageData.reduce((acc, curr) => {
												if (curr.end_time) {
													const duration = moment.duration(
														moment.unix(curr.end_time).diff(moment.unix(curr.start_time))
													)
													return acc + duration.asMilliseconds()
												}
												return acc
											}, 0)
											return (
												<Table.Summary.Row className='font-medium'>
													<Table.Summary.Cell />
													<Table.Summary.Cell />
													<Table.Summary.Cell />
													<Table.Summary.Cell />
													<Table.Summary.Cell />
													<Table.Summary.Cell index={1}>Total Duration</Table.Summary.Cell>
													<Table.Summary.Cell index={2}>
														<div type='danger'> {timeDuration(moment.duration(totalDuration), 'table')}</div>
													</Table.Summary.Cell>
												</Table.Summary.Row>
											)
										}}
									/>
									<Divider />
									<div className='text-bell-text font-medium text-base pb-2'> Other </div>
									<Table
										columns={columns}
										dataSource={timeSheetsData.filter(
											a => a.start_time && !moment.unix(a.start_time).isSame(moment(), 'day')
										)}
										pagination={pagination}
										loading={tableLoader}
										onChange={handleChange}
									/>
								</div>
							)
						},
						{
							label: 'Calendar',
							key: 'item-2',
							children: (
								<>
									{userPreference?.calendarInfo && (
										<InformationBanner text='The calendar feature is in beta. You can view your timesheets in any view. You can also add events by clicking or dragging on the calendar.' />
									)}
									<FullCalendar
										plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
										initialView='dayGridMonth'
										events={timeSheetsData.map(item => ({
											title: item.description,
											id: item.id,
											start: moment.unix(item.start_time).format(),
											end: moment.unix(item.end_time).format(),
											backgroundColor: !item.end_time && Colors.GREEN
										}))}
										headerToolbar={{
											left: 'prev,next today',
											center: 'title',
											right: 'listWeek dayGridMonth,timeGridWeek,timeGridDay'
										}}
										nowIndicator
										selectable
										select={e => {
											setTimeEntryData(e)
											setCalendarDrawer(true)
										}}
										// on click, the modal for edit show open
										eventClick={e => console.log('e', e)}
									/>
								</>
							)
						}
					]}
				/>
				<DrawerComponent
					form={
						<TimeEntryForm
							onFinish={onAdd}
							extendedView
							data={{
								start_time: timeEntryData ? moment(timeEntryData.start).unix() : null,
								end_time: timeEntryData ? moment(timeEntryData.end).unix() : null
							}}
							loading={buttonLoader}
						/>
					}
					visible={calendarDrawer}
					onOpen={() => setCalendarDrawer(true)}
					onClose={() => setCalendarDrawer(false)}
					isHidden
				/>
			</div>
		</div>
	)
}

export default TimeSheets

import React from 'react'

import { Alert, Button, Form, Input, Modal, Radio, message } from 'antd'
import { formItemLayout } from '../../../Controllers/form'
import { ERROR_MESSAGE } from '../../../Content/messages'

const CloseEnquiry = ({ open, handleOK, handleCancel, confirmLoading }) => {
	const [form] = Form.useForm()
	return (
		<Modal
			title='Title'
			visible={open}
			onOk={handleOK}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
			footer={[
				<Button key='back' onClick={handleCancel}>
					Cancel
				</Button>,
				<Button
					key='submit'
					type='primary'
					loading={confirmLoading}
					onClick={async () => {
						try {
							await form.validateFields()
							const data = form.getFieldsValue(['closed', 'closing_description'])
							handleOK(data)
						} catch (error) {
							message.error(ERROR_MESSAGE)
						}
					}}
				>
					Submit
				</Button>
			]}
		>
			<div>
				<Form name='control-hooks' form={form} {...formItemLayout}>
					<Form.Item name='closed' label='Closing Status' rules={[{ required: true, message: 'Please pick an item!' }]}>
						<Radio.Group>
							<Radio.Button value='Won'>Won</Radio.Button>
							<Radio.Button value='Lost'>Lost</Radio.Button>
							<Radio.Button value='Cancelled'>Cancelled</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item name='closing_description' label='Description' style={{ width: '100%' }}>
						<Input.TextArea rows={4} placeholder='Any information you would want to add before closing?' />
					</Form.Item>
				</Form>
				<Alert
					message='Warning'
					description='An enquiry once closed cannot be updated further. You will still be able to access the information related to this enquiry. '
					type='warning'
					showIcon
				/>
			</div>
		</Modal>
	)
}

export default CloseEnquiry

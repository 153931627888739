import React, { useState } from 'react'
import { Button, Form, Input, message, Radio, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import { fetchAccountList } from '../../../Controllers/fetchLists'
import SelectDebounce from '../../SelectDebounce'
import { updateLead } from '../../../Services/Sales/leads'
import { addClosedLead } from '../../../Store/Actions/leads'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { ERROR_MESSAGE } from '../../../Content/messages'

const { Option } = Select

const ClosingLeadForm = ({ edit, handleClose, data }) => {
	const formData = data || false
	// SELECTORS
	const { token } = useSelector(state => state.userSession)
	const { lead_status, id } = useSelector(state => state.singleLeadsData)
	const { first_name, last_name } = useSelector(state => state.userProfile)
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false)
	const [leadLost, setLeadLost] = useState(false)

	const handleFormChange = async () => {
		const { status } = await form.validateFields(['status'])
		if (status === 'Lost') {
			setLeadLost(true)
		}
		if (status === 'Won') {
			setLeadLost(false)
			form.setFieldsValue({
				lost_to: undefined
			})
		}
	}
	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			id,
			lead_status: edit ? lead_status : 'Closed',
			closed_data: {
				...values,
				lost_to: values.lost_to && values.lost_to.value
			}
		}
		try {
			await updateLead(token, dataObject)
			dispatch(addClosedLead(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Lead Stage',
				subject: edit ? `Lead Closed.` : `Lead Closed.`,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Stage updated successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || ERROR_MESSAGE)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={onFinish}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
				onChange={handleFormChange}
			>
				{/* PREVIOUS FORM */}
				<div className='py-2 italic text-bell-gray'>Previous Information</div>
				<Form.Item
					name='status'
					label='Closing Status?'
					rules={[{ required: true, message: 'Closing status is required!' }]}
				>
					<Radio.Group>
						<Radio.Button value='Won'>Won</Radio.Button>
						<Radio.Button value='Lost'>Lost</Radio.Button>
					</Radio.Group>
				</Form.Item>
				{leadLost ? (
					<>
						{' '}
						<Form.Item label='Lost to?' name='lost_to'>
							<SelectDebounce
								showSearch
								placeholder='Identify Account..'
								fetchOptions={e => fetchAccountList(e, token)}
							/>
						</Form.Item>
						<Form.Item label='Reason?' name='reason'>
							<Select placeholder='Why did we lose to the above competitor?'>
								{['Technically Stronger', 'Commercially Lower', 'Others'].map(option => (
									<Option value={option}>
										<p className='capitalize'>{option}</p>
									</Option>
								))}
							</Select>
						</Form.Item>
					</>
				) : null}
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Send Thank You?' name='send_thank_you'>
					<Radio.Group>
						<Radio.Button value='Yes'>Yes</Radio.Button>
						<Radio.Button value='No'>No</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label='File De-brief?' name='file_debrief'>
					<Radio.Group>
						<Radio.Button value='Yes'>Yes</Radio.Button>
						<Radio.Button value='No'>No</Radio.Button>
					</Radio.Group>
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default ClosingLeadForm

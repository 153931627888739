import React from 'react'
import { Avatar, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import * as Colors from '../../styles/colors'
import dateFormat from '../../Content/dateFormat'
import { revisionStageColor } from '../../Content/enquiries'
import accounting from '../../Controllers/accountingNumbers'

const KanbanCard = ({ content, id }) => {
	const bgArray = [Colors.LIGHT_GREEN, Colors.LIGHT_RED, Colors.LIGHT_BLUE]
	const randomColor = bgArray[Math.floor(Math.random() * bgArray.length)]
	const avatarName = content.enquiry_owner_name.match(/\b(\w)/g).join('')
	return (
		<Link to={`../enquiries?id=${id}`}>
			<div className='group bg-white shadow-md rounded-md p-4 my-2 w-72 space-y-1 cursor-pointer hover:-translate-y-1 duration-200 hover:bg-bell-blue'>
				<div className='text-bell-text text-xs group-hover:text-white'> {content?.enquiry_number}</div>
				<div className='text-bell-text text-base capitalize font-semibold overflow-hidden text-ellipsis group-hover:text-white'>
					{' '}
					{content?.job_description}
				</div>
				<div className='text-bell-gray text-sm group-hover:text-white'> {content?.lead_account_name} </div>
				{content.description && (
					<div className='text-bell-text font-light text-sm h-[40px] overflow-hidden group-hover:text-white'>
						{content?.description}
					</div>
				)}
				{content.current_revision_data?.submission?.amount && (
					<div className='text-bell-gray text-xs font-light group-hover:text-white'>
						USD {accounting(content.current_revision_data?.submission?.amount || 0)}
					</div>
				)}
				<div className='pt-2'>
					<Tag color={revisionStageColor[content?.current_revision_data?.revision_stage]}>
						{content?.current_revision_data?.revision_stage}
					</Tag>
				</div>
				<div className='flex justify-between pt-5 items-center'>
					<Tooltip title='Submission Date' placement='right'>
						<div className='text-xs font-light text-bell-gray group-hover:text-white'>
							{moment.unix(content?.submission_date).format(dateFormat)}
						</div>
					</Tooltip>
					<div>
						<Tooltip title={content.enquiry_owner_name}>
							<Avatar style={{ backgroundColor: randomColor }} size='small'>
								{avatarName}
							</Avatar>
						</Tooltip>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default KanbanCard

/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import { PrinterOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Button, Table, Cascader, Spin, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { InternalHeader } from '../../Components'
import dateFormat from '../../Content/dateFormat'
import { addClientEngagementReport } from '../../Store/Actions/reports'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { getClientEngagementReport } from '../../Services/reports'
import { daysFilterOptions } from '../../Controllers/daysFilter'
import { associationData } from '../../Content/association'
import { BASE_URL } from '../../config'

const ComponentToPrint = React.forwardRef(({ componentData, filter, loading }, ref) =>
	loading ? (
		<div className='pt-32 text-center'>
			{' '}
			<Spin size='large' />
		</div>
	) : (
		<div ref={ref} className='print:p-10 py-10'>
			<section className='text-2xl font-semibold py-2'>
				Client Engagement Report:
				<span className='text-lg text-bell-blue'>
					{' '}
					{moment()
						.subtract(filter[filter.length - 1], 's')
						.format(dateFormat)}
					{' - '}
					{moment().format(dateFormat)}
				</span>
			</section>
			<section className='text-xl font-semibold py-2'>Activities </section>
			<Table
				columns={[
					{
						title: 'Association',
						key: 'association_name',
						render: (_, record) =>
							record.association_name ? (
								<div>
									<div className='text-xs text-bell-gray uppercase'>{record.association_type}</div>
									<Link
										to={`..${associationData.filter(a => a.name === record.association_type)[0]?.link}?id=${
											record.association_id
										}`}
									>
										{record.association_name}
									</Link>
								</div>
							) : (
								<div className='border border-bell-blue w-2 ml-6' />
							)
					},
					{ title: 'Date', dataIndex: 'created_at', render: date => date && moment.unix(date).format(dateFormat) },
					{ title: 'Activity Type', dataIndex: 'activity_type' },
					{ title: 'Type', dataIndex: 'type' },
					{ title: 'Purpose', dataIndex: 'purpose' },
					{ title: 'Subject', dataIndex: 'subject' },
					{ title: 'Description', dataIndex: 'description' },
					{ title: 'Logged By', dataIndex: 'created_by_name' }
				]}
				dataSource={componentData && componentData.sort((a = 0, b = 1) => b.created_at - a.created_at)}
				pagination={false}
			/>
		</div>
	)
)

const ClientEngagementReport = () => {
	const componentRef = useRef()

	const { token } = useSelector(state => state.userSession)
	const {
		clientEngagementReport: { duration: filter }
	} = useSelector(state => state.reportsData)

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)
	const [reportData, setReportData] = useState(null)

	const fetchReport = async duration => {
		setLoading(true)
		try {
			const { data } = await getClientEngagementReport(token, duration)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchReport(filter[filter.length - 1])
	}, [filter])

	const excelData = reportData?.length
		? {
				'Client Engagement Report': reportData.map(one => ({
					Association: `${one?.association_name} (${one?.association_type})`,
					Date: one?.created_at && moment.unix(one?.created_at).format(dateFormat),
					'Activity Type': one?.activity_type,
					Type: one?.type,
					Purpose: one?.purpose,
					Subject: one?.subject,
					Description: one?.description,
					'Logged By': one?.created_by_name,
					Link: `${BASE_URL}${associationData.filter(a => a.name === one?.association_type)[0]?.link}?id=${one.id}`
				}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Client Engagement Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName='Client Engagement Report.xlsx' />
						<ReactToPrint
							trigger={() => (
								<Button type='primary' icon={<PrinterOutlined />}>
									Print Report
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<Cascader
							options={daysFilterOptions}
							onChange={e => e && dispatch(addClientEngagementReport({ duration: e }))}
							placeholder='Filter'
							value={filter}
							displayRender={(label, option) => (label.length > 1 ? `Last ${label[2]} ${label[1]}` : option[0].label)}
							clearIcon={false}
						/>
					</div>
				</div>
				<ComponentToPrint ref={componentRef} componentData={reportData} filter={filter} loading={loading} />
			</div>
		</div>
	)
}

export default ClientEngagementReport

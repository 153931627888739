import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// COMPONENTS
import { getAllRevisionsByEnquiryID } from '../../../Services/Sales/enquiries'
import { resetRevision } from '../../../Store/Actions/enquiries'
import { DrawerComponent } from '../..'
import RevisionForm from '../../Forms/Enquiries/RevisionForm'
import dateFormat from '../../../Content/dateFormat'

const Revisions = ({ enquiry_id }) => {
	// STATES
	const dispatch = useDispatch()
	const revisionsData = useSelector(state => state.revisionsData)
	const singleEnquiriesData = useSelector(state => state.singleEnquiriesData)

	const revisionStage = singleEnquiriesData?.current_revision_data?.revision_stage
	const { token } = useSelector(state => state.userSession)
	const [revisionFormDisplay, setRevisionFormDisplay] = useState(false)

	// GET REVISIONS BY ENQUIRY ID
	const fetchData = async (token, enquiry_id, current, pageSize) => {
		try {
			const { data } = await getAllRevisionsByEnquiryID(token, enquiry_id, current, pageSize)
			if (!data.data) {
				dispatch(resetRevision([]))
			} else {
				dispatch(resetRevision(data.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData(token, enquiry_id, 0, 10)
		// eslint-disable-next-line
	}, [])

	// COLUMNS IN REVISIONS TABLE
	const noteColumns = [
		{
			title: 'Date',

			key: 'created_at',
			render: text => <div> {moment.unix(text.created_at).format(dateFormat)} </div>
		},
		{
			title: 'Revision Number',
			dataIndex: 'revision_number',
			key: 'revision_number'
		},

		{
			title: 'Revision Stage',
			dataIndex: 'revision_stage',
			key: 'revision_stage'
		},
		{
			title: 'Change Description',
			dataIndex: 'abandon_reason',
			key: 'abandon_reason'
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<Space size='middle'>
						{/* eslint-disable-next-line */}
						<a href={`/sales/enquiries?id=${record.enquiry_id}&rev=${record.id}`}>View Revision</a>
					</Space>
				</div>
			)
		}
	]
	return (
		<div className='space-y-5'>
			<div className='text-right'>
				{revisionStage === 'Submission' && (
					<DrawerComponent
						form={
							<RevisionForm
								handleClose={() => {
									setRevisionFormDisplay(false)
								}}
							/>
						}
						visible={revisionFormDisplay}
						onOpen={() => setRevisionFormDisplay(true)}
						onClose={() => setRevisionFormDisplay(false)}
						buttonTitle='New Revision'
					/>
				)}
			</div>

			{/* NOTES TABLE */}
			<Table columns={noteColumns} dataSource={revisionsData} />
		</div>
	)
}

export default Revisions

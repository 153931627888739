/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

import { Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import accounting from '../Controllers/accountingNumbers'
import dateFormat from './dateFormat'
import * as Colors from '../styles/colors'
import MINIOLink from '../Controllers/MinioLink'

export const renderBankGuaranteeTag = {
	Active: {
		color: Colors.YELLOW
	},
	Released: {
		color: Colors.GREEN
	},
	Forfeited: {
		color: Colors.RED
	}
}

export const drawerData = record => ({
	bank_name: record.name,
	bank_address: record.address,
	'Is this an open Bank Guarantee?': record.type === 'Bank Guarantee' ? (record.open_guarantee ? 'Yes' : 'No') : null,
	'Is this an open Tender Bond?': record.type === 'Tender Bond' ? (record.open_guarantee ? 'Yes' : 'No') : null,
	issue_date: record.issue_date !== 0 && moment.unix(record.issue_date).format(dateFormat),
	expiration_date: record.expiration_date !== 0 && moment.unix(record.expiration_date).format(dateFormat),
	status: <Tag color={renderBankGuaranteeTag[record?.status]?.color}>{record.status}</Tag>,
	amount: `USD ${accounting(record.amount)}`,
	contract: record.type === 'Bank Guarantee' && record.association_id && (
		<Link to={`../contracts/contracts?id=${record.association_id}`}>{record.association_name}</Link>
	),
	enquiry: record.type === 'Tender Bond' && record.association_id && (
		<Link to={`../sales/enquiries?id=${record.association_id}`}>{record.association_name}</Link>
	),
	"Bank's Contact": record.bank_contact_id && (
		<Link to={`../sales/contacts?id=${record.bank_contact_id}`}>{record.bank_contact_name || 'Contact'}</Link>
	),
	"Client's Contact": record.client_contact_id && (
		<Link to={`../sales/contacts?id=${record.client_contact_id}`}>{record.client_contact_name || 'Contact'}</Link>
	),
	_attachments:
		record?.attachments?.length &&
		record?.attachments?.map((file, i) => (
			<div key={i}>
				<MINIOLink item={file} />
			</div>
		)),
	supporting_documents:
		record?.supporting_docs?.length &&
		record?.supporting_docs?.map((file, i) => (
			<div key={i}>
				<MINIOLink item={file} />
			</div>
		)),
	evidence_documents:
		record?.evidence_docs?.length &&
		record?.evidence_docs?.map((file, i) => (
			<div key={i}>
				<MINIOLink item={file} />
			</div>
		)),
	created_at: record.created_at,
	updated_at: record.updated_at
})

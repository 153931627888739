import React, { useState } from 'react'
import { Form, Input, Button, Select, Drawer, DatePicker, message, Space, InputNumber } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import AccountForm from '../AccountForm'
import { tailFormItemLayout, formItemLayout } from '../../../Controllers/form'
import EmployeeForm from '../EmployeeForm'
import { objectDifference } from '../../../Controllers/objectDifference'
import SelectDebounce from '../../SelectDebounce'
import { addEnquiry, editEnquiry } from '../../../Store/Actions/enquiries'
import { getEnquiryById, registerEnquiry, updateEnquiry } from '../../../Services/Sales/enquiries'
import ContactForm from '../ContactForm'
import { enquiry_stage_probability } from '../../../Content/enquiries'
import { ADD_ACCOUNT, ADD_CONTACT, ADD_OPPORTUNITY } from '../../../Controllers/buttonNames'
import {
	fetchAccountList,
	fetchContactsList,
	fetchOpportunitiesListForEnquiries
} from '../../../Controllers/fetchLists'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { refreshComponent } from '../../../Store/Actions/userSession'
import OpportunitiesForm from '../OpportunitiesForm'
import { updateOpportunity } from '../../../Services/Sales/opportunity'
import dateFormat from '../../../Content/dateFormat'
import ReporteeSelect from '../../ReporteeSelect'

const { Option } = Select

const RegisterEnquiryForm = ({ edit, handleClose, data, handleValue, ...props }) => {
	const id = edit ? data.id : null
	const formData = data
		? {
				...data,
				enquiry_date: data.enquiry_date !== 0 ? moment.unix(data.enquiry_date) : undefined,
				submission_date: data.submission_date !== 0 ? moment.unix(data.submission_date) : undefined,
				enquiry_owner_id: {
					label: data.enquiry_owner_name,
					key: data.enquiry_owner_id,
					value: data.enquiry_owner_id
				}
		  }
		: false
	const { token } = useSelector(state => state.userSession)

	const enquirySourceData = useSelector(state => state.enquirySourceData)
	const enquiryStageData = useSelector(state => state.enquiryStageData)

	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)

	const selectWidth = 500
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			enquiry_owner_id: values?.enquiry_owner_id?.value,
			account_id: values.account_id?.value || '',
			end_user_account_id: values.end_user_account_id?.value || '',
			contact_person: values.contact_person?.value || '',
			enquiry_date: values.enquiry_date ? moment(values.enquiry_date).unix() : undefined,
			submission_date: values.submission_date ? moment(values.submission_date).unix() : undefined,
			converted_from: values.converted_from?.value || ''
		}
		try {
			const { data } = await registerEnquiry(token, dataObject)
			const { enquiry_id } = data.data
			const res = await getEnquiryById(token, enquiry_id)
			dispatch(addEnquiry(res.data.data))
			if (handleValue) {
				handleValue(enquiry_id, dataObject.job_description)
			}
			if (!handleValue && values.converted_from) {
				try {
					await updateOpportunity(token, { id: values?.converted_from?.value, converted_to: enquiry_id })
				} catch (error) {
					message.error(error.response?.data?.message || 'Opportunity information could not be updated.')
				}
			}
			const activityObject = {
				association_id: enquiry_id,
				activity_type: 'Created',
				subject: `Enquiry Registered`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			await createActivity(token, {
				association_id: values?.converted_from?.value,
				activity_type: 'Convert',
				subject: `Opportunity Converted`,
				description: `Converted to Enquiry "${values.job_description}"`
			})
			if (!props.opportunityId) {
				dispatch(
					addActivity({
						id: activityData.data.id,
						...activityObject
					})
				)
			}
			message.success('Enquiry registered successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
			setLoading(false)
		}
	}
	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			enquiry_owner_id: values.enquiry_owner_id.value || data.enquiry_owner_id,
			account_id: values.account_id?.value || data.account_id,
			end_user_account_id: values.end_user_account_id?.value || data.end_user_account_id,
			contact_person: values.contact_person?.value || data.contact_person,
			enquiry_date: values.enquiry_date ? moment(values.enquiry_date).unix() : undefined,
			submission_date: values.submission_date ? moment(values.submission_date).unix() : undefined,
			converted_from: values.converted_from?.value || data.converted_from || ''
		}
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateEnquiry(token, { id, ...updatedData })
			const res = await getEnquiryById(token, id)
			dispatch(
				editEnquiry({
					id,
					data: { user_pinned: formData.user_pinned, ...res.data.data }
				})
			)
			if (values.converted_from && !data.converted_from) {
				await updateOpportunity(token, { id: values?.converted_from?.value, converted_to: id })
			}
			await createActivity(token, {
				association_id: values?.converted_from?.value,
				activity_type: 'Convert',
				subject: `Opportunity Converted`,
				description: `Converted to Enquiry "${values.job_description}"`
			})
			dispatch(refreshComponent())
			message.success('Enquiry updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	const getDrawerComponent = onClose => {
		switch (visible) {
			case 'Create an Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={e => {
							form.setFieldsValue({
								account_id: {
									label: e.account_name,
									value: e.id
								}
							})
						}}
					/>
				)
			case 'Create an End User Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={e => {
							form.setFieldsValue({
								end_user_account_id: {
									label: e.account_name,
									value: e.id
								}
							})
						}}
					/>
				)
			case 'Create a Contact':
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(w, data) => {
							form.setFieldsValue({
								contact_person: {
									label: `${data.first_name} ${data.last_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			case 'Add Employee':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={data => {
							form.setFieldsValue({
								enquiry_owner_id: {
									label: `${data.first_name} ${data.last_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			case 'Add Opportunity':
				return (
					<OpportunitiesForm
						handleClose={onClose}
						handleValues={data => {
							form.setFieldsValue({
								converted_from: {
									label: data.name,
									value: data.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Add Opportunity':
				return 900
			default:
				return 700
		}
	}

	const handleEnquiryStage = e => {
		form.setFieldsValue({
			probability: enquiry_stage_probability.filter(x => x.name === e)[0].percentage
		})
	}

	return (
		<div className='space-y-20'>
			{/* REGISTER ENQUIRY FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Owner Information</div>
				<Form.Item
					label='Enquiry Owner'
					name='enquiry_owner_id'
					rules={[{ required: true, message: 'Please input enquiry owner!' }]}
				>
					<ReporteeSelect
						form={form}
						fieldName='enquiry_owner_id'
						currentValue={formData ? formData?.enquiry_owner_id?.label : null}
					/>
				</Form.Item>

				<div className='py-2 italic text-bell-gray'>Enquiry Information</div>
				<Form.Item label='Account Name'>
					<Space>
						<Form.Item name='account_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Account'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								currentSearchValue={form.getFieldValue('account_id')?.label ? '' : data?.lead_account_name}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create an Account')} type='link'>
							{ADD_ACCOUNT}
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label="Account's Reference Number" name='account_ref_number'>
					<Input />
				</Form.Item>
				<Form.Item label='Contact Person'>
					<Space>
						<Form.Item name='contact_person' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Contact'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
								currentSearchValue={form.getFieldValue('contact_person')?.label ? '' : data?.contact_person_data}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create a Contact')} type='link'>
							{ADD_CONTACT}
						</Button>
					</Space>
				</Form.Item>

				<Form.Item label='End User Account Name'>
					<Space>
						<Form.Item name='end_user_account_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Account'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								currentSearchValue={form.getFieldValue('end_user_account_id')?.label ? '' : data?.end_user_account_name}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Create an End User Account')} type='link'>
							{ADD_ACCOUNT}
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Enquiry Source' name='enquiry_source'>
					<Select placeholder='Select an Enquiry Source'>
						{enquirySourceData.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Main Project Name'>
					<Space>
						<Form.Item name='converted_from' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Opportunity'
								fetchOptions={e => fetchOpportunitiesListForEnquiries(e, token)}
								style={{
									width: selectWidth
								}}
								currentSearchValue={form.getFieldValue('converted_from')?.label ? '' : data?.converted_from_name}
								disabled={data?.converted_from}
							/>
						</Form.Item>
						{!data?.converted_from ? (
							<Button onClick={e => showDrawer(e, 'Add Opportunity')} type='link'>
								{ADD_OPPORTUNITY}
							</Button>
						) : null}
					</Space>
				</Form.Item>

				<Form.Item
					label='Job Description'
					name='job_description'
					rules={[
						{
							required: true,
							message: 'Please input job description'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label='Enquiry Date'
					name='enquiry_date'
					rules={[
						{
							required: true,
							message: 'Please input enquiry date'
						}
					]}
				>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item
					label='Submission Date'
					name='submission_date'
					rules={[
						{
							required: true,
							message: 'Please input submission date'
						}
					]}
				>
					<DatePicker disabledDate={current => current < form.getFieldValue('enquiry_date')} format={dateFormat} />
				</Form.Item>
				<Form.Item label='Enquiry Stage' name='enquiry_stage'>
					<Select placeholder='Select Enquiry Stage' onChange={handleEnquiryStage}>
						{enquiryStageData.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Probability [%]' name='probability'>
					<InputNumber disabled />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Descriptive Information</div>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={
					<div
						style={{
							textAlign: 'right'
						}}
					/>
				}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default RegisterEnquiryForm

/* eslint-disable import/prefer-default-export */
import moment from 'moment'

const daysList = () => {
	const arr = []
	for (let i = 1; i <= 30; i += 1) {
		arr.push({
			value: moment().diff(moment().subtract(i, 'days'), 'seconds'),
			label: i
		})
	}
	return arr
}

const monthsList = () => {
	const arr = []
	for (let i = 1; i <= 12; i += 1) {
		arr.push({
			value: moment().diff(moment().subtract(i, 'months'), 'seconds'),
			label: i
		})
	}
	return arr
}

export const daysFilterOptions = [
	{
		value: 604800,
		label: 'Last 7 days'
	},
	{
		value: 2592000,
		label: 'Last 30 days'
	},
	{
		value: 'custom',
		label: 'Custom',
		children: [
			{
				value: 'days',
				label: 'Days',
				children: daysList()
			},
			{
				value: 'months',
				label: 'Months',
				children: monthsList()
			}
		]
	}
]

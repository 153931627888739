import React, { useEffect, useState } from 'react'
import Board from '@asseinfo/react-kanban'
import './kanbanview.css'
import { useSelector } from 'react-redux'
import KanbanCard from './KanbanCard'
import accounting from '../../Controllers/accountingNumbers'
import { kanbanColumnColor } from '../../Content/enquiries'

const KanbanColumn = ({ title, number }) => (
	<div
		className='flex flex-wrap w-72 justify-between pl-2 pb-2 border-b-2 mb-2 text-base sticky'
		style={{ borderColor: kanbanColumnColor[title] }}
	>
		<div className='text-bell-text'> {title}</div>
		<div className='text-bell-gray'> {number} </div>
	</div>
)

const KanbanView = ({ data, pagination }) => {
	const enquiryStageData = useSelector(state => state.enquiryStageData)

	const arrayForColumns = () => ({
		columns: enquiryStageData.map(stage => ({
			id: stage,
			title: stage,
			number: `USD ${accounting(
				data
					.filter(one => stage === one.enquiry_stage)
					.map(x => x.current_revision_data?.submission?.amount || 0)
					.reduce((a, b) => a + b, 0)
			)}`,
			cards: data
				.filter(one => stage === one.enquiry_stage)
				.slice(0, pagination)
				.map(x => ({ id: x.id, content: x }))
		}))
	})
	const [controlledBoard, setBoard] = useState(arrayForColumns())

	useEffect(() => {
		setBoard(arrayForColumns())
	}, [data, pagination])

	return (
		<Board
			disableCardDrag
			disableColumnDrag
			renderCard={({ content, id }) => <KanbanCard content={content} id={id} />}
			renderColumnHeader={({ title, number }) => <KanbanColumn title={title} number={number} />}
		>
			{controlledBoard}
		</Board>
	)
}

export default KanbanView

import { Button } from 'antd'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import KanbanView from './Kanban/KanbanView'
import TableComponent from './Table'
import TableHeader from './TableHeader'

const DataView = ({
	data,
	columns,
	type,
	rowClickable,
	handleChange,
	pagination,
	handleSearch,
	showKanban,
	kanbanData,
	loading,
	noHeader
}) => {
	const [kanbanOffset, setKanbanOffset] = useState(1)
	const cardsPerPage = 10
	const location = useLocation()

	const handleOffset = () => {
		setKanbanOffset(prev => prev + 1)
	}
	return (
		<div className='px-10'>
			{!noHeader && <TableHeader handleSearch={handleSearch} showKanban={showKanban} />}
			{!location.pathname.split('/enquiries/')[1] ? (
				<TableComponent
					columns={columns}
					data={data}
					type={type}
					pagination={pagination}
					handleChange={handleChange}
					rowClickable={rowClickable || false}
					loading={loading}
				/>
			) : (
				<div className='pb-10 space-y-2'>
					<KanbanView data={kanbanData} pagination={kanbanOffset * cardsPerPage} />
					<Button onClick={handleOffset} className='block w-full border-4'>
						Show More
					</Button>
				</div>
			)}
		</div>
	)
}

export default DataView

import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const SuggestedSection = ({ data }) => (
	<div>
		<div className='flex items-center space-x-4'>
			<div className='font-medium text-5xl'> {data?.count} </div>
			<div className='text-bell-gray font-medium text-lg'>Upcoming</div>
		</div>
		<div className='space-y-4 pt-4'>
			{data?.data.map((item, i) => (
				<Link
					to={`./enquiries?id=${item?.id}`}
					class='grid grid-cols-5 bg-white  p-2 rounded-md shadow-md gap-5 transform hover:translate-x-2 cursor-pointer hover:bg-opacity-40 transition-all duration-300'
					key={i}
				>
					<div className='col-span-1 border-r-4 border-bell-blue grid place-items-center pr-2'>
						<div className='text-3xl text-bell-text'>{moment.unix(item?.submission_date).format('DD')}</div>
						<div className='text-bell-gray'>{moment.unix(item?.submission_date).format('MMM').toUpperCase()}</div>
					</div>

					<div className='col-span-4'>
						<div className='text-xs text-bell-gray font-light'> Submission Date </div>
						<div className='font-medium text-bell-text text-base'>{item?.job_description}</div>
						<div className='text-bell-gray font-normal text-xs'> {item?.enquiry_stage} </div>{' '}
					</div>
				</Link>
			))}
		</div>
	</div>
)

export default SuggestedSection

import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, Upload, message, Table, Space } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { UploadOutlined } from '@ant-design/icons'
import { tailFormItemLayout, formItemLayout } from '../../../Controllers/form'
import { addEnquiryCosting } from '../../../Store/Actions/enquiries'
import { updateRevision } from '../../../Services/Sales/enquiries'
import { URL } from '../../../config'
import { worldCurrencies } from '../../../Content/worldCurrencies'
import { objectDifference } from '../../../Controllers/objectDifference'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { DeleteItem, DrawerComponent } from '../..'
import CostingSchema from './CostingSchema'
import accounting from '../../../Controllers/accountingNumbers'
import { ERROR_MESSAGE } from '../../../Content/messages'

const { Option } = Select

const CostingForm = ({ edit, handleClose, data, updateStage }) => {
	const { enquiry_number, current_revision_data, current_revision_id, id } = useSelector(
		state => state.singleEnquiriesData
	)
	const formData = data
		? {
				...data,
				enquiry_date: data.enquiry_date !== 0 ? moment.unix(data.enquiry_date) : undefined,
				submission_date: data.submission_date !== 0 ? moment.unix(data.submission_date) : undefined
		  }
		: false
	// SELECTORS
	const { token } = useSelector(state => state.userSession)

	const [uploadedFiles, setUploadedFiles] = useState([])
	const [costingSchema, setCostingSchema] = useState(false)
	const [editCostingSchema, setEditCostingSchema] = useState(false)
	const [costingData, setCostingData] = useState(data?.costing || [])
	const [form] = Form.useForm()
	const dispatch = useDispatch()

	const handleData = data => {
		setCostingData(prev => [data, ...prev.filter(x => x.id !== data.id)])
		setCostingSchema(false)
		setEditCostingSchema(false)
	}
	const [loading, setLoading] = useState(false)

	// ADD COSTING
	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			costing: costingData,
			attachments: uploadedFiles
		}
		try {
			await updateRevision(token, { id: current_revision_id, revision_stage: 'Costing', costing: dataObject })
			dispatch(addEnquiryCosting(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Costing for Revision ${current_revision_data.revision_number} Added`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Costing updated successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || ERROR_MESSAGE)
		}
	}

	// EDIT COSTING
	const onEdit = async values => {
		const dataObject = {
			...values,
			costing: costingData,
			attachments: data.attachments ? [...data.attachments, ...uploadedFiles] : uploadedFiles
		}
		setLoading(true)
		const updatedData = objectDifference(data, dataObject)
		try {
			if (updateStage) {
				await updateRevision(token, {
					id: current_revision_id,
					revision_stage: 'Costing',
					costing: updatedData
				})
			} else {
				await updateRevision(token, { id: current_revision_id, costing: updatedData })
			}
			dispatch(addEnquiryCosting(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Costing for Revision ${current_revision_data.revision_number} Updated`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)

			message.success('Costing updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || ERROR_MESSAGE)
		}
	}

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: true,
		onChange(info) {
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				setUploadedFiles([...uploadedFiles, info.file.response.data.name])
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		}
	}

	useEffect(() => {
		form.setFieldsValue({
			currency: 'USD'
		})
		// eslint-disable-next-line
	}, [])

	return (
		<div className='space-y-20'>
			{/* COSTING FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Enquiry Information</div>

				<Form.Item label='Enquiry Number' name='enquiry_number' initialValue={enquiry_number}>
					<Input disabled style={{ width: 300 }} />
				</Form.Item>
				<Form.Item label='Revision Number' name='revision_number' initialValue={current_revision_data.revision_number}>
					<Input disabled style={{ width: 300 }} />
				</Form.Item>
				<Form.Item label='Currency' name='currency'>
					<Select placeholder='Select Currency' showSearch style={{ width: 300 }} disabled>
						{worldCurrencies.map(option => (
							<Option value={option.code}>
								<p className='capitalize'>
									{option.name} ({option.symbol})
								</p>
							</Option>
						))}
					</Select>
				</Form.Item>

				{costingData.length > 0 && (
					<>
						<div className='py-2 italic text-bell-gray'>Costing Information</div>
						<Table
							columns={[
								{
									title: 'Costing Name',
									dataIndex: 'name'
								},
								{
									title: 'Description',
									dataIndex: 'description'
								},
								{
									title: 'Total Cost',
									render: (_, record) => (
										<div>
											{form.getFieldValue('currency')}{' '}
											{accounting(record.deliverables.map(x => x.total_cost).reduce((a, b) => a + b, 0))}
										</div>
									)
								},
								{
									title: 'Action',
									render: (_, record) => (
										<Space>
											{record.resources ? (
												<DrawerComponent
													form={
														<CostingSchema
															currency={form.getFieldValue('currency')}
															handleData={handleData}
															data={costingData?.filter(x => x.id === record.id)[0]}
														/>
													}
													visible={editCostingSchema[record.id]}
													onOpen={async () => {
														await form.validateFields(['currency'])
														setEditCostingSchema(prev => ({ ...prev, [record.id]: true }))
													}}
													onClose={() => setEditCostingSchema(false)}
													buttonTitle='Edit'
													buttonType='link'
													drawerWidth={window.innerWidth}
												/>
											) : null}
											<DeleteItem
												popTitle='Are you sure to delete this schema?'
												onDelete={() => setCostingData(prev => [...prev.filter(x => x.id !== record.id)])}
											/>
										</Space>
									)
								}
							]}
							dataSource={costingData}
						/>
					</>
				)}
				<Form.Item {...tailFormItemLayout}>
					<DrawerComponent
						form={<CostingSchema currency={form.getFieldValue('currency')} handleData={handleData} />}
						visible={costingSchema}
						onOpen={async () => {
							await form.validateFields(['currency'])
							setCostingSchema(true)
						}}
						onClose={() => setCostingSchema(false)}
						buttonTitle='Add Costing'
						buttonType='primary'
						drawerWidth={window.innerWidth}
					/>
				</Form.Item>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Upload {...props}>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Upload>
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default CostingForm

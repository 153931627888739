import React, { useState } from 'react'
import { Button, Divider, Drawer, Form, Input, message, Radio, Select, Space, Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import _ from 'lodash'
import { AccountForm, EmployeeForm } from '.'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import SelectDebounce from '../SelectDebounce'
import { fetchAccountList, fetchUserList } from '../../Controllers/fetchLists'
import { Files } from '../Views'
import ProductServicesTable from './Contracts/ProductServicesTable'
import { addProject, editProject } from '../../Store/Actions/projects'
import ColorPicker from '../ColorPicker'
import { associationsList, colorsArray } from '../../Content/projects'
import { getContractById } from '../../Services/Contracts/contracts'
import { createProject, getProjectById, updateProject } from '../../Services/projects'
import { objectDifference } from '../../Controllers/objectDifference'
import { fetchDeepInformation, fetchLists } from '../../Controllers/fetchInformation'

const ProjectForm = ({ edit, handleClose, data }) => {
	// TODO: Add 'account' if association is also 'account'
	// TODO: Remove PS table as [] if association is PS
	const formData = data
		? {
				...data,
				account_id: {
					key: data.account_id,
					label: data.account_name,
					value: data.account_id
				},
				association_id: {
					key: data.association_id,
					label: data.association_id ? data?.association_name : '',
					value: data.association_id
				},
				project_manager_id: {
					key: data.project_manager_id,
					label: data.project_manager_name,
					value: data.project_manager_id
				},
				deputy_id: {
					key: data.deputy_id,
					label: data.deputy_name,
					value: data.deputy_id
				},
				team: data.team_details?.length
					? data.team_details.map(a => ({
							key: a.id,
							label: a.name,
							value: a.id
					  }))
					: []
		  }
		: false

	const { token, role } = useSelector(state => state.userSession)

	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState(data?.attachments || [])
	const [tableData, setTableData] = useState(data?.ps || [])
	const [color, setColor] = useState(data?.color || colorsArray[Math.floor(Math.random() * colorsArray.length)])
	const [budgetType, setBudgetType] = useState(data?.budget_type || null)
	const [resetOptions, setResetOptions] = useState(false)
	const [association, setAssociation] = useState(data?.association || 'Contract')
	const { Option } = Select

	const selectWidth = 500
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}

	const handleColor = e => {
		const { hex } = e
		setColor(hex)
	}

	const dataObject = values => ({
		..._.omit(values, ['enable_budget']),
		color,
		account_id: association === 'Account' ? values?.association_id?.value : values?.account_id?.value,
		association,
		association_id: values?.association_id?.value,
		project_manager_id: values?.project_manager_id?.value,
		deputy_id: values?.deputy_id?.value,
		attachments: uploadedFiles,
		team: values?.team?.length ? values.team.map(q => q.value) : [],
		// to avoid db query puncture, a temporary id is used in case there is no ps group available.
		ps:
			association === 'Product / Service' && !data
				? []
				: tableData?.map(x => ({
						...x,
						ps_group_id: x?.ps_group_id?.value || v4(),
						ps_data: {
							...x.ps_data,
							billable_tasks: x?.ps_data?.billable_tasks?.length
								? x?.ps_data?.billable_tasks?.map(a => ({ ...a, id: a.id || v4() }))
								: []
						}
				  })),
		budget: Number(values?.budget) || 0,
		budget_type: values?.budget_type || budgetType
	})

	const onAdd = async values => {
		setLoading(true)
		try {
			const { data: responseData } = await createProject(token, dataObject(values))
			const { id } = responseData.data
			const res = await getProjectById(token, id)
			dispatch(addProject(res.data.data))
			message.success('Project added successfully!')
			form.resetFields()
			setUploadedFiles([])
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}
	const onEdit = async values => {
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject(values))
			await updateProject(token, { id: data.id, ...updatedData })
			const res = await getProjectById(token, data.id)
			dispatch(
				editProject({
					id: data.id,
					data: res.data.data
				})
			)
			message.success('Project updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleContract = async w => {
		const { id: contractId } = w
		try {
			const { data } = await getContractById(token, contractId)
			const contractData = data.data
			form.setFieldsValue({
				account_id: formData?.account_id?.value
					? formData.account_id
					: {
							label: contractData.account_name,
							value: contractData.account_id,
							key: contractData.account_id
					  },
				project_manager_id: formData?.project_manager_id?.value
					? formData?.project_manager_id
					: {
							label: contractData.project_manager_name,
							value: contractData.project_manager_id,
							key: contractData.project_manager_id
					  },
				deputy_id: formData?.deputy_id?.value
					? formData?.deputy_id?.value
					: {
							label: contractData.deputy_name,
							value: contractData.deputy_id,
							key: contractData.deputy_id
					  }
			})
			if (!formData || formData?.ps?.length === 0) {
				setTableData(
					contractData.ps
						? contractData.ps.map(x => ({
								..._.omit(x, ['ps_billable_tasks']),
								ps_group_id: {
									label: x.ps_group_name,
									key: x.ps_group_id,
									value: x.ps_group_id
								},
								ps_data: {
									name: x.ps_group_name,
									billable_tasks: x.ps_billable_tasks
								}
						  }))
						: []
				)
			}
		} catch (error) {
			message.error('The contract information could not be auto-filled in the form!')
		}
	}

	const handleAssociation = async e => {
		const associationId = e.value
		try {
			const {
				data: { data }
			} = await fetchDeepInformation(associationId, association, token)
			if (data?.access_specifier === 'Private') {
				form.setFields([
					{
						name: 'association_id',
						errors: ['You are not authorized to associate this entity to the project!']
					}
				])
			} else {
				form.setFields([
					{
						name: 'association_id',
						value: e,
						errors: null
					}
				])
				// if the association is 'contract', it will overwrite the information in the form.
				if (association === 'Contract') {
					handleContract(data)
				}
			}
		} catch (e) {
			message.error('Something went wrong!')
		}
	}

	const getDrawerComponent = () => {
		switch (visible) {
			case 'Add Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={one => {
							form.setFieldsValue({
								account_id: {
									label: one.account_name,
									value: one.id
								}
							})
						}}
					/>
				)

			case 'Add Employee':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								team: [
									...form.getFieldValue('team'),
									{
										label: `${one.first_name} ${one.last_name}`,
										value: one.id
									}
								]
							})
						}}
					/>
				)
			case 'Add Project Manager':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								project_manager_id: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Deputy':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								deputy_id: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Products & Services':
				return (
					<ProductServicesTable
						data={tableData.map(x => ({
							...x,
							ps_group_id:
								typeof x.ps_group_id === 'string'
									? {
											key: x.ps_group_id,
											label: x?.ps_data?.name || '-',
											value: x.ps_group_id
									  }
									: x.ps_group_id
						}))}
						handleClose={onClose}
						handleData={w => setTableData(w)}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Add Contract':
				return 900
			case 'Add Products & Services':
				return 1000
			default:
				return 700
		}
	}

	const handleFiles = async (currentAttachment, fileName) => {
		setUploadedFiles(prev => [currentAttachment, ...prev])
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async fileName => {
		try {
			if (edit) {
				await updateProject(token, {
					id: formData.id,
					attachments: uploadedFiles.filter(x => x !== fileName)
				})
			}
			setUploadedFiles(prev => prev.filter(x => x !== fileName))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Registration Information</div>
				<Form.Item label='Project Name' name='name' rules={[{ required: true, message: 'Please input project name!' }]}>
					<Input placeholder='Name of the Project' />
				</Form.Item>

				<Form.Item label='Color' name='color'>
					<ColorPicker color={color} handleColor={handleColor} />
				</Form.Item>
				<Form.Item
					label='Association'
					style={{
						marginBottom: 0
					}}
				>
					<Form.Item
						name='association'
						style={{
							display: 'inline-block',
							width: 'calc(30%)'
						}}
					>
						<Select
							value={association}
							onChange={e => {
								setAssociation(e)
								form.setFieldsValue({
									association_id: null
								})
								setResetOptions(true)
							}}
							defaultValue={association}
						>
							{associationsList?.map(option => (
								<Option value={option}>{option}</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='association_id'
						style={{
							display: 'inline-block',
							width: 'calc(70%)'
						}}
					>
						<SelectDebounce
							showSearch
							placeholder={association && `Search ${association}`}
							fetchOptions={e => {
								setResetOptions(false)
								return fetchLists(e, association, token)
							}}
							resetOptions={resetOptions}
							onChange={e => handleAssociation(e, association)}
						/>
					</Form.Item>
				</Form.Item>
				{association !== 'Account' && (
					<>
						<div className='py-2 italic text-bell-gray'>Client Information</div>
						<Form.Item label='Account'>
							<Space>
								<Form.Item name='account_id' noStyle>
									<SelectDebounce
										showSearch
										placeholder='Select from Accounts'
										fetchOptions={e => fetchAccountList(e, token)}
										style={{
											width: selectWidth
										}}
									/>
								</Form.Item>
								<Button onClick={e => showDrawer(e, 'Add Account')} type='link'>
									Add Account
								</Button>
							</Space>
						</Form.Item>
					</>
				)}
				{association !== 'Product / Service' && (
					<>
						<div className='py-2 italic text-bell-gray'>Products / Services Information</div>
						<Form.Item label='Products / Services Information' name='ps'>
							<Space>
								<Button onClick={e => showDrawer(e, 'Add Products & Services')} type='secondary'>
									Add Products & Services
								</Button>
								<Divider type='vertical' />
								<div className='text-gray-600 font-normal'>
									<span className='text-bell-blue text-2xl font-medium'>{tableData?.length || 'No'}</span> Item(s)
									added.
								</div>
							</Space>
						</Form.Item>
					</>
				)}
				<div className='py-2 italic text-bell-gray'>Management Information</div>
				<Form.Item label='Project Manager'>
					<Space>
						<Form.Item
							name='project_manager_id'
							noStyle
							rules={[{ required: true, message: 'Please select project manager!' }]}
						>
							<SelectDebounce
								showSearch
								placeholder='Search from Employees'
								fetchOptions={e => fetchUserList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						{role === 'Admin' ? (
							<Button onClick={e => showDrawer(e, 'Add Project Manager')} type='link'>
								Add Project Manager
							</Button>
						) : null}
					</Space>
				</Form.Item>
				<Form.Item label='Deputy'>
					<Space>
						<Form.Item name='deputy_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search from Employees'
								fetchOptions={e => fetchUserList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						{role === 'Admin' ? (
							<Button onClick={e => showDrawer(e, 'Add Deputy')} type='link'>
								Add Deputy
							</Button>
						) : null}
					</Space>
				</Form.Item>
				<Form.Item label='Team'>
					<Space>
						<Form.Item name='team' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search from Employees'
								fetchOptions={e => fetchUserList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
							/>
						</Form.Item>
						{role === 'Admin' ? (
							<Button onClick={e => showDrawer(e, 'Add Employee')} type='link'>
								Add Employee
							</Button>
						) : null}
					</Space>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Budgetary Information</div>
				<Form.Item label='Enable Budget' name='enable_budget'>
					<Switch
						checked={!!budgetType}
						onChange={e => {
							setBudgetType(e ? 'Time' : null)
							form.setFieldsValue({
								budget_type: e ? 'Time' : null
							})
						}}
					/>
				</Form.Item>
				{budgetType && (
					<>
						<Form.Item label='Budget Type' name='budget_type' defaultValue={budgetType}>
							<Radio.Group defaultValue={budgetType} onChange={e => setBudgetType(e.target.value)}>
								<Radio.Button value='Time'>Time</Radio.Button>
								<Radio.Button value='Money'>Money</Radio.Button>
							</Radio.Group>
						</Form.Item>
						<Form.Item label='Budget' name='budget'>
							<Input addonBefore={budgetType === 'Money' ? 'USD' : 'Hours'} style={{ width: 300 }} />
						</Form.Item>
					</>
				)}
				<div className='py-2 italic text-bell-gray'>Attachments</div>
				<Form.Item label='Attachments' name='attachments'>
					<Files attachments={uploadedFiles} handleFiles={handleFiles} handleRemove={handleRemove} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default ProjectForm

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Button, DatePicker, Divider, Space, Statistic, Table, Tabs, Tag, message } from 'antd'
import { getEmployeeWisePettyCashReport, getPettyCashByEmployeeId } from '../../Services/pettycash'
import dateFormat from '../../Content/dateFormat'
import accounting from '../../Controllers/accountingNumbers'
import { DrawerComponent } from '../../Components'
import PettyCashForm from '../../Components/Forms/PettyCashForm'
import * as Colors from '../../styles/colors'
import { statusColor } from '../../Content/expenses'
import SingleExpense from '../Expenses/SingleExpense'
import { getExpenseByEmployeeId } from '../../Services/expenses'
import { defaultPagination } from '../../Content/general'
import PettyCashReportStats from '../../Components/PettyCashReportStats'
import { DownloadExcel } from '../../Components/DownloadExcel'
import MINIOLink from '../../Controllers/MinioLink'

const PettyCashByEmployee = ({ employeeId, employeeName }) => {
	const { token } = useSelector(state => state.userSession)
	const [pettyData, setPettyData] = useState([])
	const [expenseData, setExpenseData] = useState([])
	const [employeesData, setEmployeesData] = useState(null)
	const [addDrawer, setAddDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [timePeriod, setTimePeriod] = useState(null)

	const fetchEmployeePettyData = async () => {
		try {
			const res = await getPettyCashByEmployeeId(token, employeeId)
			if (res?.data?.data) {
				setPettyData(res.data.data)
			}
		} catch (error) {
			message.error('The entity could not be loaded.')
		}
	}

	const fetchEmployeesData = async () => {
		try {
			const res = await getEmployeeWisePettyCashReport(token, employeeId, timePeriod?.start, timePeriod?.end)
			if (res?.data?.data) {
				setEmployeesData(res.data.data)
			}
		} catch (error) {
			message.error('The entity could not be loaded.')
		}
	}

	const fetchEmployeeExpenseData = async () => {
		try {
			const res = await getExpenseByEmployeeId(
				token,
				employeeId,
				defaultPagination.current - 1,
				defaultPagination.pageSize
			)
			if (res?.data?.data) {
				setExpenseData(res.data.data)
			}
		} catch (error) {
			message.error('The entity could not be loaded.')
		}
	}

	useEffect(() => {
		fetchEmployeePettyData()
		fetchEmployeeExpenseData()
	}, [])

	useEffect(() => {
		fetchEmployeesData()
	}, [timePeriod])

	const pettyCashColumns = [
		{
			title: 'Issued Date',
			dataIndex: 'issued_on',
			key: 'issued_on',
			render: (_, record) => moment.unix(record.issued_on).format(dateFormat)
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'issued_on'
		},
		{
			title: 'Attachments',
			dataIndex: 'attachments',
			key: 'attachments',
			render: att => (
				<div className='flex flex-col'>
					{att.map(q => (
						<MINIOLink item={q} />
					))}
				</div>
			)
		}
	]

	const expensesColumn = [
		{
			title: 'Date',
			dataIndex: 'expense_date',
			key: 'expense_date',
			render: (_, record) => moment.unix(record.expense_date).format(dateFormat)
		},
		{
			title: 'Expense Name',
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: 'Reimbursement',
			key: 'tags',
			render: (_, record) => <Space>{record.client_claim && <Tag color={Colors.GREEN}> From Client</Tag>}</Space>
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: text => <Tag color={statusColor[text]}> {text}</Tag>
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle='Expense Details'
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<SingleExpense id={record.id} closeDrawer={() => setViewDrawer(false)} financeView />}
					/>
				</Space>
			)
		}
	]

	const tagColor = {
		expense: Colors.RED,
		payment: Colors.GREEN
	}
	const employeesColumns = [
		{
			title: 'Type',
			key: 'type',
			dataIndex: 'type',
			render: text => (
				<Tag color={tagColor[text]} className='capitalize'>
					{text}
				</Tag>
			)
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, record) => moment.unix(record.date).format(dateFormat)
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title'
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			key: 'balance',
			render: balance =>
				balance < 0 ? (
					<div className='text-bell-red'> {accounting(balance)}</div>
				) : (
					<div className='text-bell-green'> {accounting(balance)}</div>
				)
		}
	]

	const excelData = employeesData
		? {
				Overview: [
					{
						'Total Disbursement': accounting(employeesData.total_payouts),
						'Total Expenses': accounting(employeesData.total_expenses),
						'Time Range': timePeriod
							? `${moment.unix(timePeriod.start).format(dateFormat)} to ${moment
									.unix(timePeriod.end)
									.format(dateFormat)} `
							: 'All Time',
						'Disbursement in this time period': accounting(employeesData?.payouts_in_time_period),
						'Expenses in this time period': accounting(employeesData?.expenses_in_time_period),
						Balance: accounting(employeesData?.opening)
					}
				],
				Enquiries: employeesData?.record?.map(one => ({
					Type: one.type,
					Date: moment.unix(one.date).format(dateFormat),
					Title: one.title,
					Amount: accounting(one.amount),
					Balance: accounting(one.balance)
				}))
		  }
		: null

	const items = [
		{
			key: '1',
			label: `Overview`,
			children: (
				<>
					<div className='pt-2 pb-4 flex justify-between'>
						<div>
							<DatePicker.RangePicker
								format={dateFormat}
								onChange={e => {
									setTimePeriod({
										start: moment(e[0]).unix(),
										end: moment(e[1]).unix()
									})
								}}
								value={[
									timePeriod?.start ? moment.unix(timePeriod.start) : null,
									timePeriod?.end ? moment.unix(timePeriod.end) : null
								]}
							/>
							<Button disabled={!timePeriod} onClick={() => setTimePeriod(null)}>
								Reset
							</Button>
						</div>
						<DownloadExcel data={excelData} fileName={`Disbursement for ${employeeName}.xlsx`} />
					</div>
					<div className='flex space-x-4 justify-center py-2'>
						<Statistic
							title='Disbursement in this time period'
							value={accounting(employeesData?.payouts_in_time_period)}
						/>
						<Statistic
							title='Expenses in this time period'
							value={accounting(employeesData?.expenses_in_time_period)}
						/>
						<Statistic title='Balance' value={accounting(employeesData?.opening)} />
					</div>
					<Table
						columns={employeesColumns}
						dataSource={employeesData?.record || []}
						pagination={false}
						rowClassName={rec => (rec.type === 'expense' ? 'bg-red-50' : 'bg-green-50')}
					/>
				</>
			)
		},
		{
			key: '2',
			label: `Disbursement`,
			children: <Table columns={pettyCashColumns} dataSource={pettyData} pagination={false} />
		},
		{
			key: '3',
			label: `Expenses`,
			children: <Table columns={expensesColumn} dataSource={expenseData} pagination={false} />
		}
	]

	return (
		<div>
			<PettyCashReportStats employeeId={employeeId} refresh={addDrawer} />
			<Divider />
			<div className='flex flex-row-reverse'>
				<DrawerComponent
					form={
						<PettyCashForm
							data={{
								employee_id: employeeId,
								employee_name: employeeName,
								issued_on: moment().unix()
							}}
							handleClose={e => {
								setPettyData(prev => [e, ...prev])
								setAddDrawer(false)
							}}
							disableEmployeeInput
						/>
					}
					visible={addDrawer}
					onOpen={() => setAddDrawer(true)}
					onClose={() => setAddDrawer(false)}
					drawerTitle='Expense Details'
					buttonTitle='Issue Disbursement'
					drawerWidth={600}
					buttonType='secondary'
				/>
			</div>
			<Tabs defaultActiveKey={1} items={items} />
		</div>
	)
}

export default PettyCashByEmployee

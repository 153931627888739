import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Space, Statistic, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../Components'
import dateFormat from '../../Content/dateFormat'
import { BankGuaranteeForm } from '../../Components/Forms'

import { renderBankGuaranteeTag } from '../../Content/bank-guarantees'
import { getAllGuarantees } from '../../Services/guarantees'
import { resetBG } from '../../Store/Actions/bank-guarantees'
import { getGuaranteeStats } from '../../Services/stats'
import SingleGuarantee from './SingleGuarantee'

const BankGuarantees = ({ type }) => {
	const typeData = () => {
		switch (type) {
			case 'Bank Guarantee':
				return {
					title: 'Bank Guarantees',
					routeType: 'bank-guarantees'
				}
			case 'Tender Bond':
				return {
					title: 'Tender Bonds',
					routeType: 'tender-bonds'
				}
			default:
				return null
		}
	}
	const bankGuaranteesData = useSelector(state => state.bankGuaranteesData)
	const { token, role } = useSelector(state => state.userSession)
	const [stats, setStats] = useState(null)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [loading, setLoading] = useState(false)

	const [search, setSearch] = useState('')
	const dispatch = useDispatch()

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		setLoading(true)
		try {
			const { data } = await getAllGuarantees(token, current, pageSize, sortOrder, sortField, query, type)
			if (!data.data) {
				dispatch(resetBG([]))
			} else {
				dispatch(resetBG(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!viewDrawer) {
			dispatch(resetBG([]))
			fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		}
		// eslint-disable-next-line
	}, [search, viewDrawer])

	const getStats = async () => {
		try {
			const { data } = await getGuaranteeStats(token, type)
			setStats(data.data)
		} catch (error) {
			setStats(null)
		}
	}
	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [bankGuaranteesData])

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	const statsObject = {
		title: typeData().title,
		total: stats?.map(x => x?.count)?.reduce((a, b) => a + b, 0) || 0,
		data: stats
			? stats?.map(x => ({
					name: x.status || 'Unassigned',
					color: renderBankGuaranteeTag[x.status]?.color,
					number: x.count
			  }))
			: []
	}

	const columns = [
		{
			title: 'Bank Name',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Issue Date',
			key: 'issue_date',
			render: (_, record) => record.issue_date !== 0 && moment.unix(record.issue_date).format(dateFormat),
			sorter: true
		},
		{
			title: 'Expiration Date',
			key: 'expiration_date',
			render: (_, record) => record.expiration_date !== 0 && moment.unix(record.expiration_date).format(dateFormat),
			sorter: true
		},
		{
			title: 'Association',
			key: 'association_name',
			render: (_, record) =>
				record.association_name &&
				(type === 'Bank Guarantee' ? (
					<Link to={`../contracts/contracts?id=${record.association_id}`}>{record.association_name}</Link>
				) : (
					<Link to={`../sales/enquiries?id=${record.association_id}`}>{record.association_name}</Link>
				)),
			sorter: true
		},
		{
			title: 'Status',
			key: 'status',
			sorter: true,
			dataIndex: 'status',
			render: (_, record) => <Tag color={renderBankGuaranteeTag[record?.status]?.color}>{record.status}</Tag>
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle' key={record.id}>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle={`${type} Details`}
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<SingleGuarantee guaranteeId={record.id} type={type} closeDrawer={() => setViewDrawer(false)} />}
					/>
				</Space>
			)
		}
	]

	return (
		<div className='bg-bell-background'>
			{type === 'Bank Guarantee' ? (
				<InternalHeader title='Contracts' selected='Bank Guarantees' />
			) : (
				<InternalHeader title='Sales' selected='Tender Bonds' />
			)}
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> {typeData().title} </div>
				<div>
					<DrawerComponent
						form={<BankGuaranteeForm handleClose={() => setAddDrawer(false)} type={type} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle={`Add ${type}`}
						drawerWidth={900}
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2 flex space-x-10'>
					<Stats data={statsObject} />
					{/* financial stats currently visible to administrators and managers only. */}
					{role !== 'Employee' && (
						<div className='flex space-x-8'>
							{stats?.map(
								(item, i) =>
									item.status && <Statistic title={item.status} value={item.amount} precision={2} prefix='$' key={i} />
							)}
						</div>
					)}
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={bankGuaranteesData}
					type={typeData().routeType}
					rowClickable={false}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
					loading={loading}
				/>
			</div>
		</div>
	)
}

export default BankGuarantees

import { Button, Popconfirm } from 'antd'
import React from 'react'

const DeleteItem = ({ popTitle, onDelete, loading, ...props }) => (
	<Popconfirm
		title={popTitle || 'Are you sure to delete?'}
		onConfirm={onDelete}
		okText='Delete'
		cancelText='Cancel'
		okButtonProps={{ danger: true }}
		icon={null}
	>
		<Button type={props.buttonType || 'link'} danger loading={loading}>
			{props.children || 'Delete'}
		</Button>
	</Popconfirm>
)

export default DeleteItem

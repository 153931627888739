import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import SelectDebounce from '../../SelectDebounce'
import { fetchEnquiriesList } from '../../../Controllers/fetchLists'
import { updateLead } from '../../../Services/Sales/leads'
import { addNurturingLead } from '../../../Store/Actions/leads'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'

const NurturingLeadForm = ({ edit, handleClose, data }) => {
	const formData = data
		? {
				...data,
				enquiry: [
					{
						key: data.enquiry,
						value: data.enquiry,
						label: data.enquiry_name
					}
				]
		  }
		: false
	// SELECTORS
	const { token } = useSelector(state => state.userSession)
	const { lead_status, id } = useSelector(state => state.singleLeadsData)
	const { first_name, last_name } = useSelector(state => state.userProfile)

	const dispatch = useDispatch()
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false)

	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			id,
			lead_status: edit ? lead_status : 'Nurturing',
			nurturing_data: {
				...values,
				enquiry: values.enquiry?.value
			}
		}
		try {
			await updateLead(token, dataObject)
			dispatch(addNurturingLead(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Lead Stage',
				subject: edit ? `Lead Nurturing Stage updated.` : `Lead Nurturing Stage added.`,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Stage updated successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={onFinish}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Nurturing Information</div>

				<Form.Item label='Select Enquiry' name='enquiry'>
					<SelectDebounce
						showSearch
						placeholder='Identify Enquiry..'
						fetchOptions={e => fetchEnquiriesList(e, token)}
					/>
				</Form.Item>

				<Form.Item label='Summary' name='summary'>
					<Input.TextArea rows={4} placeholder='Summary?' />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default NurturingLeadForm

import React from 'react'
import { MINIO } from '../config'

const MINIOLink = ({ item }) => (
	<a href={`${MINIO}/${item}`}>
		{item.split('@c@')[0]}.{item.split('.')[item.split('.').length - 1]}
	</a>
)

export default MINIOLink

import React, { useState, useEffect } from 'react'
import { Drawer, Dropdown } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import '../index.css'
import { Link, useLocation } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { logout } from '../Store/Actions/userSession'
import logo from '../Assets/Images/paradigm_logo.png'
import ChangePasswordForm from './Forms/ChangePasswordForm'
import TimeTracker from './TimeTracker'
import { getCurrentTimeEntry } from '../Services/timesheet'
import { currentTime } from '../Store/Actions/timesheets'
import * as Colors from '../styles/colors'
import { SUB_DOMAIN } from '../config'

const Header = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const inTimesheets = location.pathname.split('/')[1] === 'timesheets'

	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)

	const [changePasswordDrawer, setChangePasswordDrawer] = useState(false)

	const fetchCurrentEntry = async () => {
		try {
			const { data } = await getCurrentTimeEntry(token)
			if (!data.data) {
				dispatch(currentTime(null))
			} else {
				dispatch(currentTime(data.data))
			}
		} catch (error) {
			dispatch(currentTime(null))
		}
	}

	useEffect(() => {
		fetchCurrentEntry()
	}, [])

	const menu = {
		items: [
			{
				key: 0,
				label: (
					<>
						<div onClick={() => setChangePasswordDrawer(true)}>Change Password</div>
						<Drawer
							title='Change Password'
							width={500}
							onClose={() => setChangePasswordDrawer(false)}
							open={changePasswordDrawer}
						>
							<ChangePasswordForm handleClose={() => setChangePasswordDrawer(false)} />
						</Drawer>
					</>
				)
			},
			{
				key: 1,
				danger: true,
				label: <div onClick={() => dispatch(logout())}>Log Out</div>
			}
		]
	}

	return (
		<div>
			<div
				className={`flex justify-between items-center px-7 py-4 shadow-sm ${
					SUB_DOMAIN === 'try-paradigm' ? 'bg-violet-100' : 'bg-white'
				}`}
			>
				<div className='flex items-center space-x-5'>
					<Link to='/'>
						<div className='flex items-center'>
							<img src={logo} alt='paradigm logo' className='w-10' />
							<div className='text-bell-text text-xl font-bold'> Paradigm</div>
						</div>
					</Link>
				</div>
				<div>{/* <Search placeholder="Search Paradigm" loading={false} /> */}</div>
				<div className='flex items-center space-x-10'>
					{!inTimesheets && <TimeTracker />}
					<Dropdown menu={menu} placement='bottom'>
						<div className='flex items-center space-x-1 cursor-pointer'>
							<div className='font-medium'>
								{first_name} {last_name}
							</div>
							<DownOutlined style={{ color: Colors.LIGHT_GREY, size: 24 }} />
						</div>
					</Dropdown>
				</div>
			</div>
			{SUB_DOMAIN === 'try-paradigm' && (
				<div className='bg-violet-500 text-center text-white'>
					This is a trial account. Any changes you make here will remain here only and will not reflect on the main
					account.
				</div>
			)}
		</div>
	)
}

export default Header

import { message, Space, Table, Tag } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTasksByAssociationID } from '../../../Services/task'
import dateFormat from '../../../Content/dateFormat'
import { taskStatusColor } from '../../../Content/tasks'
import DrawerComponent from '../../DrawerComponent'
import TaskViewer from '../../TaskViewer'

const TasksView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [loading, setLoading] = useState(true)
	const [tasksData, setTasksData] = useState([])
	const [taskForm, setTaskForm] = useState(false)

	const fetchData = async () => {
		try {
			const { data } = await getTasksByAssociationID(token, id)
			setTasksData(data?.data)
			setLoading(false)
		} catch (error) {
			setTasksData([])
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong!')
		}
	}

	const columns = [
		{
			title: 'Action / Task',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Due Date',
			sorter: true,
			key: 'end_date',
			render: (_, record) => <div> {moment.unix(record.end_date).format(dateFormat)}</div>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			sorter: true,
			key: 'status',
			render: status => (
				<Tag color={taskStatusColor[status]} key={status}>
					{status?.toUpperCase()}
				</Tag>
			)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, w) => (
				<div className='z-10'>
					<Space size='middle'>
						<DrawerComponent
							form={<TaskViewer id={w.id} />}
							visible={taskForm[w.id]}
							onOpen={() => setTaskForm({ [w.id]: true })}
							onClose={() => setTaskForm(false)}
							buttonTitle='View'
							buttonType='link'
						/>
					</Space>
				</div>
			)
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [refreshComponent])
	return <Table columns={columns} dataSource={tasksData.map((x, i) => ({ ...x, key: i }))} loading={loading} />
}

export default TasksView

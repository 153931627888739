import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, message, Segmented, Space, Tag } from 'antd'
import moment from 'moment/moment'

import { DrawerComponent, InternalHeader, TableComponent } from '../../Components'
import { ExpenseForm } from '../../Components/Forms'
import dateFormat from '../../Content/dateFormat'
import * as Colors from '../../styles/colors'
import accounting from '../../Controllers/accountingNumbers'
import { getExpenseApprovalsList, getExpenseByEmployeeId } from '../../Services/expenses'
import SingleExpense from './SingleExpense'
import { segmentOptions, statusColor } from '../../Content/expenses'
import { resetExpense } from '../../Store/Actions/expenses'
import { ERROR_MESSAGE } from '../../Content/messages'
import { defaultPagination } from '../../Content/general'
import PettyCashReportStats from '../../Components/PettyCashReportStats'

const Expenses = () => {
	const { id: userId } = useSelector(state => state.userSession)
	const expensesData = useSelector(state => state.expensesData)
	const { token } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [addDrawer, setAddDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [loadingTable, setLoadingTable] = useState(false)
	const [pagination, setPagination] = useState(defaultPagination)
	const [currentSegment, setCurrentSegment] = useState(segmentOptions.yourExpenses)
	const [requestedCount, setRequestedCount] = useState(null)

	const dispatch = useDispatch()

	const fetchData = async (current, pageSize) => {
		// for tab: your expenses
		if (currentSegment === segmentOptions.yourExpenses) {
			setLoadingTable(true)
			try {
				const { data } = await getExpenseByEmployeeId(token, userId, current, pageSize)
				if (!data.data) {
					dispatch(resetExpense([]))
				} else {
					dispatch(resetExpense(data.data))
				}
				setPagination({
					current: current + 1,
					pageSize,
					total: data.count
				})
			} catch (error) {
				message.error(error?.response?.data?.message || ERROR_MESSAGE)
			} finally {
				setLoadingTable(false)
			}

			// for other tabs: requested, queued, completed
		} else {
			setLoadingTable(true)
			try {
				const { data } = await getExpenseApprovalsList(token, current, pageSize, currentSegment)
				if (!data.data) {
					dispatch(resetExpense([]))
				} else {
					dispatch(resetExpense(data.data))
				}
				if (currentSegment === segmentOptions.requested) {
					setRequestedCount(data.count)
				}
				setPagination({
					current: current + 1,
					pageSize,
					total: data.count
				})
			} catch (error) {
				message.error(error?.response?.data?.message || ERROR_MESSAGE)
			} finally {
				setLoadingTable(false)
			}
		}
	}

	const fetchRequestedCount = async () => {
		try {
			const { data } = await getExpenseApprovalsList(
				token,
				defaultPagination.current,
				defaultPagination.pageSize,
				'Requested'
			)
			setRequestedCount(data?.count)
		} catch (error) {
			return null
		}
		return true
	}

	const handleChange = page => {
		fetchData(page.current - 1, page.pageSize)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize)
		// eslint-disable-next-line
	}, [currentSegment, refreshComponent])

	useEffect(() => {
		fetchRequestedCount()
	}, [])

	const columns = [
		{
			title: 'Employee Name',
			key: 'employee_name',
			dataIndex: 'employee_name'
		},
		{
			title: 'Date',
			dataIndex: 'expense_date',
			key: 'expense_date',
			render: (_, record) => moment.unix(record.expense_date).format(dateFormat)
		},
		{
			title: 'Expense Name',
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category'
		},
		{
			title: 'Merchant',
			dataIndex: 'merchant_name',
			key: 'merchant_name'
		},

		{
			title: 'Reimbursement',
			key: 'tags',
			render: (_, record) => <Space>{record.client_claim && <Tag color={Colors.GREEN}> From Client</Tag>}</Space>
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: text => <Tag color={statusColor[text]}> {text}</Tag>
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle='Expense Details'
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={
							<SingleExpense id={record.id} closeDrawer={() => setViewDrawer(false)} currentView={currentSegment} />
						}
					/>
				</Space>
			)
		}
	]

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Expenses' selected='All Expenses' />
			<div className='flex justify-between px-10 py-6'>
				<div className='space-y-4'>
					<div className='uppercase font-medium text-blue-text'> Expenses </div>
					<PettyCashReportStats employeeId={userId} />
				</div>
				<div>
					<DrawerComponent
						form={<ExpenseForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Expense'
						drawerWidth={1000}
					/>
				</div>
			</div>
			<div className='px-10'>
				<div className='flex justify-between py-2'>
					<div className='flex justify-between py-2 gap-7'>
						<Segmented
							defaultValue={segmentOptions.yourExpenses}
							options={[
								segmentOptions.yourExpenses,
								{
									label: requestedCount ? (
										<div className='pr-3'>
											<Badge count={requestedCount} overflowCount={10} offset={[11, 3]}>
												{segmentOptions.requested}
											</Badge>
										</div>
									) : (
										segmentOptions.requested
									),
									value: segmentOptions.requested
								},
								segmentOptions.queued,
								segmentOptions.completed
							]}
							onChange={e => {
								setCurrentSegment(e)
								setPagination(defaultPagination)
							}}
						/>
					</div>
				</div>
				<TableComponent
					columns={columns}
					data={expensesData}
					pagination={pagination}
					handleChange={handleChange}
					loading={loadingTable}
				/>
			</div>
		</div>
	)
}

export default Expenses

import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Radio, Select, Space, Switch, Table, Upload } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { UploadOutlined } from '@ant-design/icons'
import { AiTwotoneDelete } from 'react-icons/ai'
import AccountForm from '../AccountForm'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'

import EmployeeForm from '../EmployeeForm'

import { objectDifference } from '../../../Controllers/objectDifference'
import SelectDebounce from '../../SelectDebounce'
import { addScopeAndEvaluation } from '../../../Store/Actions/enquiries'
import { updateRevision } from '../../../Services/Sales/enquiries'
import ContactForm from '../ContactForm'
import ProductServicesForm from '../ProductsServicesForm'
import { URL } from '../../../config'
import RiskAssessmentForm from '../RiskAssessmentForm'
import * as Colors from '../../../styles/colors'
import { ADD_ACCOUNT, ADD_CONTACT, ADD_PS } from '../../../Controllers/buttonNames'
import { fetchAccountList, fetchContactsList, fetchPSList, fetchUserList } from '../../../Controllers/fetchLists'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'

import { DrawerComponent } from '../..'
import { worldCurrencies } from '../../../Content/worldCurrencies'
import { refreshComponent } from '../../../Store/Actions/userSession'
import { TaskForm } from '..'

const { Option } = Select

const ScopeAndEvaluationForm = ({ edit, handleClose, data, updateStage }) => {
	const formData = data
		? {
				...data,
				enquiry_date: data.enquiry_date !== 0 ? moment.unix(data.enquiry_date) : undefined,
				submission_date: data.submission_date !== 0 ? moment.unix(data.submission_date) : undefined
		  }
		: false
	// SELECTORS
	const { role, token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)
	const { id, enquiry_number, enquiry_stage, current_revision_id, current_revision_data } = useSelector(
		state => state.singleEnquiriesData
	)
	const enquiryStageData = useSelector(state => state.enquiryStageData)
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	const [uploadedFiles, setUploadedFiles] = useState([])
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [actions, setActions] = useState(data?.no_bid_action_data || [])
	const [noBidForm, setNoBidForm] = useState(false)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 5
	})
	const handleChange = page => {
		setPagination(prev => ({
			...prev,
			current: page.current
		}))
	}
	const [psValues, setPsValues] = useState(
		data
			? data?.products_services_data?.map(x => ({
					label: x.name,
					value: x?.id,
					data: x
			  }))
			: []
	)

	const [PSForm, setPSForm] = useState(false)
	const [riskManagement, setRiskManagement] = useState(data?.risk_management?.risk_factors ? true : '')
	const [accountValue, setAccountValue] = useState(data?.competitors_data || [])
	const [contactValues, setContactValues] = useState({
		procurement: data?.decision_makers_procurement_data || [],
		registration: data?.decision_makers_registration_data || [],
		technical: data?.decision_makers_technical_data || []
	})
	const [contactType, setContactType] = useState('')
	const [formSwitches, setFormSwitches] = useState({
		tender_bond_required: data?.tender_bond_required || false,
		insurance_required: data?.insurance_required || false,
		bank_guarantee_required: data?.bank_guarantee_required || false,
		regular_enquiry: data?.regular_enquiry || false,
		bid: data?.bid || false
	})

	const handlePS = e => {
		const dataObject = {
			key: e.id,
			label: e.name,
			value: e.id,
			data: e
		}
		setPsValues(prev => [...prev.filter(x => x.value !== dataObject.value), dataObject])
	}

	const handleAccountValues = e => {
		const dataObject = {
			key: e.id,
			label: e.account_name,
			value: e.id
		}
		setAccountValue(prev => [...prev, dataObject])
	}

	const handleRiskManagement = e => {
		setRiskManagement(e)
	}

	const handleContactValues = (e, data) => {
		const dataObject = {
			key: data.id,
			label: `${data.first_name} ${data.last_name}`,
			value: data.id
		}
		setContactValues(prev => ({
			...prev,
			[e]: [dataObject, ...prev[e]]
		}))
	}
	const selectWidth = 500
	const showDrawer = (e, type, contactType) => {
		setContactType(contactType)
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			attachments: uploadedFiles,
			products_services: psValues.map(x => x.data?.id),
			decision_makers_procurement: values?.decision_makers_procurement?.map(x => x.value),
			decision_makers_registration: values?.decision_makers_registration?.map(x => x.value),
			decision_makers_technical: values?.decision_makers_technical?.map(x => x.value),
			competitors: values?.competitors?.map(x => x.value),
			proposal_engineer: values?.proposal_engineer?.value || undefined,
			risk_management: riskManagement,
			no_bid_actions: actions.map(x => x?.id)
		}
		try {
			await updateRevision(token, {
				id: current_revision_id,
				revision_stage: 'Scope and Evaluation',
				scope_and_evaluation: dataObject
			})
			dispatch(addScopeAndEvaluation(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Scope and Evaluation for Revision ${current_revision_data.revision_number} Added`,
				created_at: moment().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Data saved successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	// EDIT ENQUIRY
	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			attachments: data.attachments ? [...data.attachments, ...uploadedFiles] : uploadedFiles,
			products_services: psValues.map(x => x.data?.id),
			decision_makers_procurement: values.decision_makers_procurement
				? values?.decision_makers_procurement?.map(x => x.value)
				: data?.decision_makers_procurement,
			decision_makers_registration: values.decision_makers_registration
				? values.decision_makers_registration?.map(x => x.value)
				: data?.decision_makers_registration,

			decision_makers_technical: values.decision_makers_technical
				? values.decision_makers_technical?.map(x => x.value)
				: data?.decision_makers_technical,
			competitors: values.competitors ? values.competitors.map(x => x.value) : data?.competitors,
			proposal_engineer: values?.proposal_engineer?.value || data.proposal_engineer,
			risk_management: riskManagement || data.risk_management,
			no_bid_actions: actions.map(x => x?.id) || data.no_bid_actions
		}
		const updatedData = objectDifference(data, dataObject)
		try {
			if (updateStage) {
				await updateRevision(token, {
					id: current_revision_id,
					revision_stage: 'Scope and Evaluation',
					scope_and_evaluation: updatedData
				})
			} else {
				await updateRevision(token, { id: current_revision_id, scope_and_evaluation: updatedData })
			}
			dispatch(addScopeAndEvaluation(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Scope and Evaluation for Revision ${current_revision_data.revision_number} Updated`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Data updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}
	const getDrawerComponent = onClose => {
		switch (visible) {
			case 'Create an Account':
				return <AccountForm handleClose={onClose} handleAccountValues={handleAccountValues} />
			case 'Create a Contact':
				return <ContactForm handleClose={onClose} type={contactType} handleContactValues={handleContactValues} />
			case 'Add Employee':
				return <EmployeeForm handleClose={onClose} />
			case 'Add Product / Service':
				return <ProductServicesForm handleClose={onClose} handlePS={handlePS} />
			case 'Risk Assessment Form':
				return <RiskAssessmentForm handleClose={onClose} handleRiskManagement={handleRiskManagement} />
			case 'Create No Bid Action':
				return (
					<TaskForm
						handleClose={onClose}
						association_id={id}
						handleActions={data => {
							setActions(prev => [data, ...prev])
						}}
						actionIDs={actions.map(act => act.id)}
						revisionID={current_revision_id}
						fromSE
						addAnother
					/>
				)

			default:
				return null
		}
	}

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: true,
		onChange(info) {
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				setUploadedFiles([...uploadedFiles, info.file.response.data.name])
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		}
	}

	useEffect(() => {
		form.setFieldsValue({
			decision_makers_registration: contactValues?.registration?.map(x => ({
				key: x.key ? x.key : x.id,
				label: x.label ? x.label : x.name,
				value: x.value ? x.value : x.id
			})),
			decision_makers_technical: contactValues.technical?.map(x => ({
				key: x.key ? x.key : x.id,
				label: x.label ? x.label : x.name,
				value: x.value ? x.value : x.id
			})),
			decision_makers_procurement: contactValues.procurement?.map(x => ({
				key: x.key ? x.key : x.id,
				label: x.label ? x.label : x.name,
				value: x.value ? x.value : x.id
			})),
			competitors: accountValue?.map(x => ({
				key: x.key ? x.key : x.id,
				label: x.label ? x.label : x.name,
				value: x.value ? x.value : x.id
			}))
		})
	}, [psValues, contactValues, accountValue, form])
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Create an Account':
				return 750
			case 'Create a Contact':
				return 770
			case 'Add Employee':
				return 700
			default:
				return 800
		}
	}

	return (
		<div className='space-y-20'>
			{/* SCOPE AND EVALUATE AN ENQUIRY FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Enquiry Information</div>
				<Form.Item label='Enquiry Number' name='enquiry_number' initialValue={enquiry_number}>
					<Input disabled />
				</Form.Item>
				<Form.Item label='Enquiry Stage' name='enquiry_stage' initialValue={enquiry_stage}>
					<Select placeholder='Select Enquiry Stage'>
						{enquiryStageData.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Enquiry Evaluation</div>

				<Form.Item label='Products / Services'>
					<Space>
						<Form.Item name='products_services' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search for Products or Services..'
								fetchOptions={e => fetchPSList(e, token)}
								style={{
									width: selectWidth
								}}
								onChange={(e, w) => {
									setPsValues(prev => [...prev, w])
								}}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Add Product / Service')} type='link'>
							{ADD_PS}
						</Button>
					</Space>
					{psValues.length > 0 && (
						<Table
							className='table-ps pt-4'
							columns={[
								{
									title: 'Task Name',
									key: 'name',
									render: (_, record) => record?.data?.name
								},
								{
									title: 'Description',
									key: 'description',
									render: (_, record) => record?.data?.description
								},
								{
									title: 'Actions',
									key: 'actions',
									render: (text, record) => (
										<Space size='middle'>
											<DrawerComponent
												form={
													<ProductServicesForm
														edit
														handleClose={() => setPSForm(false)}
														data={record?.data}
														handlePS={handlePS}
													/>
												}
												visible={PSForm[record.value]}
												onOpen={() => setPSForm({ [record.value]: true })}
												onClose={() => setPSForm(false)}
												buttonTitle='Edit'
												buttonType='link'
												drawerWidth={1000}
											/>
											<Button
												type='link'
												onClick={() => setPsValues(prev => prev.filter(x => x.value !== record.value))}
											>
												Delete
											</Button>
										</Space>
									)
								}
							]}
							dataSource={psValues}
							pagination={pagination}
							loading={!psValues}
							onChange={handleChange}
						/>
					)}
				</Form.Item>

				{/* DEV: */}

				<Form.Item label='Is Tender Bond Required?' name='tender_bond_required'>
					<Switch
						onChange={e => setFormSwitches(prev => ({ ...prev, tender_bond_required: e }))}
						defaultChecked={formSwitches.tender_bond_required}
					/>
				</Form.Item>
				{formSwitches.tender_bond_required && (
					<Form.Item label='Tender Bond Value' name='tender_bond_value'>
						<Input
							addonBefore={
								<Form.Item name='currency' noStyle>
									<Select
										style={{
											width: 100
										}}
									>
										{worldCurrencies.slice(0, 5).map(currency => (
											<Option value={currency.code}>{currency.symbol}</Option>
										))}
									</Select>
								</Form.Item>
							}
							style={{
								width: '50%'
							}}
						/>
					</Form.Item>
				)}
				<Form.Item label='Is Insurance Required?' name='insurance_required'>
					<Switch
						onChange={e => setFormSwitches(prev => ({ ...prev, insurance_required: e }))}
						defaultChecked={formSwitches.insurance_required}
					/>
				</Form.Item>
				{formSwitches.insurance_required && (
					<Form.Item label='Remarks on Insurance' name='insurance_remarks'>
						<Input style={{ width: '50%' }} />
					</Form.Item>
				)}
				<Form.Item
					label={
						<div>
							Is Performance Bank <br />
							Guarantee Required?
						</div>
					}
					name='bank_guarantee_required'
				>
					<Switch
						onChange={e => setFormSwitches(prev => ({ ...prev, bank_guarantee_required: e }))}
						defaultChecked={formSwitches.bank_guarantee_required}
					/>
				</Form.Item>
				{formSwitches.bank_guarantee_required && (
					<Form.Item label='Bank Guarantee Value' name='bank_guarantee_value'>
						<Input
							addonBefore={
								<Form.Item name='currency' noStyle>
									<Select
										disabled
										style={{
											width: 100
										}}
									>
										{worldCurrencies.slice(0, 5).map(currency => (
											<Option value={currency.code}>{currency.symbol}</Option>
										))}
									</Select>
								</Form.Item>
							}
							style={{
								width: '50%'
							}}
						/>
					</Form.Item>
				)}
				<div className='py-2 italic text-bell-gray'>Strategy</div>

				<Form.Item
					label={
						<div>
							Decision Makers <br /> (Registration / Prequal)
						</div>
					}
				>
					<Space>
						<Form.Item name='decision_makers_registration' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts..'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								defaultValue={contactValues?.registration?.map(x => ({ key: x.id, label: x.label, value: x.value }))}
								onChange={e => setContactValues(prev => ({ ...prev, registration: e }))}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create a Contact', 'registration')} type='link'>
							{ADD_CONTACT}
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Decision Makers (Technical)'>
					<Space>
						<Form.Item name='decision_makers_technical' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts..'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								defaultValue={contactValues?.technical?.map(x => ({ key: x.id, label: x.label, value: x.value }))}
								onChange={e => setContactValues(prev => ({ ...prev, technical: e }))}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create a Contact', 'technical')} type='link'>
							{ADD_CONTACT}
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Decision Makers (Procurement)'>
					<Space>
						<Form.Item name='decision_makers_procurement' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts..'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								defaultValue={contactValues?.procurement?.map(x => ({ key: x.id, label: x.label, value: x.value }))}
								onChange={e => setContactValues(prev => ({ ...prev, procurement: e }))}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create a Contact', 'procurement')} type='link'>
							{ADD_CONTACT}
						</Button>
					</Space>
				</Form.Item>

				<Form.Item label='Competitors'>
					<Space>
						<Form.Item name='competitors' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search For Competitors in Accounts..'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								defaultValue={accountValue?.map(x => ({ key: x.id, label: x.label, value: x.value }))}
								onChange={e => setAccountValue(e)}
							/>
						</Form.Item>

						<Button onClick={e => showDrawer(e, 'Create an Account')} type='link'>
							{ADD_ACCOUNT}
						</Button>
					</Space>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Enquiry Related Attachments</div>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Upload {...props}>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Upload>
				</Form.Item>

				<div className='py-2 italic text-bell-gray'>Risk Management</div>
				<Form.Item label='Is this a Regular Enquiry?'>
					<Space>
						<Form.Item name='regular_enquiry'>
							<Switch
								onChange={e => setFormSwitches(prev => ({ ...prev, regular_enquiry: e }))}
								defaultChecked={formSwitches.regular_enquiry}
							/>
						</Form.Item>
						{riskManagement ? (
							<Form.Item>
								<div className='flex space-x-2 items-center'>
									<div className='text-sm text-bell-green pl-4'> Risk Assessment Added! </div>
									<div className='cursor-pointer'>
										<AiTwotoneDelete color={Colors.RED} fontSize={16} onClick={() => setRiskManagement('')} />
									</div>
								</div>
							</Form.Item>
						) : (
							!formSwitches.regular_enquiry && (
								<Form.Item {...tailFormItemLayout}>
									<Button onClick={e => showDrawer(e, 'Risk Assessment Form')}> Perform Risk Assessment </Button>
								</Form.Item>
							)
						)}
					</Space>
				</Form.Item>

				<Form.Item label='Bid or No Bid?' name='bid'>
					<Radio.Group onChange={e => setFormSwitches(prev => ({ ...prev, bid: e.target.value }))}>
						<Radio.Button value='Bid'>Bid</Radio.Button>
						<Radio.Button value='No Bid'>No Bid</Radio.Button>
					</Radio.Group>
				</Form.Item>
				{/* eslint-disable-next-line consistent-return */}
				{(() => {
					if (formSwitches.bid === 'Bid') {
						return (
							<Form.Item label='Proposal Engineer' requiredMark>
								<Space>
									<Form.Item
										name='proposal_engineer'
										noStyle
										rules={[{ required: true, message: 'Please input proposal engineer!' }]}
									>
										<SelectDebounce
											showSearch
											placeholder='Search Proposal Engineer..'
											fetchOptions={e => fetchUserList(e, token)}
											style={{
												width: selectWidth
											}}
											currentSearchValue={
												form.getFieldValue('proposal_engineer')?.label ? '' : data?.proposal_engineer_name
											}
										/>
									</Form.Item>
									{role === 'Admin' ? (
										<Button onClick={e => showDrawer(e, 'Add Employee')} type='link'>
											Add Employee
										</Button>
									) : null}
								</Space>
							</Form.Item>
						)
					}
					if (formSwitches.bid === 'No Bid') {
						return (
							<Form.Item {...tailFormItemLayout}>
								<div className={`${actions.length !== 0 ? 'py-2' : ''}`}>
									<Button onClick={e => showDrawer(e, 'Create No Bid Action')}>Add No Bid Actions</Button>
								</div>
								{actions.length !== 0 ? (
									<Table
										columns={[
											{
												title: 'Name',
												dataIndex: 'name',
												key: 'description'
											},
											{
												title: 'Description',
												dataIndex: 'description',
												key: 'description'
											},
											{
												title: 'Actions',
												key: 'actions',
												render: (_, record) => (
													<Space>
														<DrawerComponent
															form={
																<TaskForm
																	edit
																	handleClose={() => setNoBidForm(false)}
																	data={record}
																	handleActions={data => {
																		setActions(prev => [data, ...prev.filter(x => x.id !== data.id)])
																	}}
																/>
															}
															visible={noBidForm[record.id]}
															onOpen={() => setNoBidForm({ [record.id]: true })}
															onClose={() => setNoBidForm(false)}
															buttonTitle='Edit'
															buttonType='link'
														/>
														<Button
															type='link'
															onClick={() => setActions(prev => [...prev.filter(x => x.id !== record.id)])}
														>
															Delete
														</Button>
													</Space>
												)
											}
										]}
										dataSource={actions}
										pagination={pagination}
										onChange={handleChange}
									/>
								) : null}
							</Form.Item>
						)
					}
				})()}

				<div className='py-2 italic text-bell-gray'>Descriptive Information</div>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={
					<div
						style={{
							textAlign: 'right'
						}}
					/>
				}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default ScopeAndEvaluationForm

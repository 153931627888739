import { Button, Drawer } from 'antd'
import React from 'react'

const DrawerComponent = ({
	form,
	visible,
	onOpen,
	buttonTitle,
	buttonType,
	onClose,
	drawerTitle,
	drawerWidth,
	isHidden
}) => (
	<div>
		<Button type={buttonType || 'primary'} onClick={onOpen} hidden={isHidden}>
			{buttonTitle}
		</Button>
		<Drawer
			title={drawerTitle || buttonTitle}
			width={drawerWidth || 700}
			onClose={onClose}
			open={visible}
			bodyStyle={{ paddingBottom: 80 }}
			footer={
				<div
					style={{
						textAlign: 'right'
					}}
				/>
			}
			destroyOnClose
		>
			{form}
		</Drawer>
	</div>
)

export default DrawerComponent

/* eslint-disable import/prefer-default-export */
export const whatsNew = {
	version: '09',
	date: '24-01-2024',
	content: [
		{
			title: 'Website Data',
			description:
				'For Sales, we have integrated web inquiries from various websites on Paradigm. You can easily create a lead from a Web Inquiry.',
			link: '/sales/website'
		},
		{
			title: 'New Home Page',
			description:
				'Enhance your user experience and provide quick access to valuable information and statistics. This new homepage will not only improve the way you interact with the platform but also make your work more efficient and productive.'
		}
	]
}

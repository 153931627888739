import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, message, notification, Radio, Select, Space, TimePicker } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { taskAssociationSync, timeCategory, timeSheetSync } from '../../Content/timetracker'
import { fetchDeepInformation, fetchLists } from '../../Controllers/fetchInformation'
import { fetchAssignedTasks } from '../../Controllers/fetchLists'
import DrawerComponent from '../DrawerComponent'
import SelectDebounce from '../SelectDebounce'
import TaskForm from './TaskForm'

const { Option } = Select

const TimeEntryForm = ({ onFinish, extendedView, data, loading }) => {
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name, id } = useSelector(state => state.userProfile)
	const [category, setCategory] = useState(data?.category || 'Project')
	const [resetOptions, setResetOptions] = useState(false)
	const [resetTaskOptions, setResetTaskOptions] = useState(false)
	const [timerType, setTimerType] = useState(data ? 'Manual' : 'Timer')
	const [addDrawer, setAddDrawer] = useState(false)
	const [taskData, setTaskData] = useState(null)
	const [form] = Form.useForm()
	const [association, setAssociation] = useState(data?.association || 'Project')

	const formData = data
		? {
				...data,
				timer_type: 'Manual',
				time_range: [moment.unix(data.start_time), moment.unix(data.end_time)],
				date: moment.unix(data.start_time),
				task_id: {
					key: data.task_id,
					value: data.task_id,
					label: data.task
				}
		  }
		: { timer_type: 'Timer', category: 'Project', association: 'Project' }

	const currentCategory = timeCategory.filter(q => q.name === category)[0]

	const formItemLayout = extendedView
		? {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 6 }
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 18 }
				}
		  }
		: null

	const tailFormItemLayout = extendedView
		? {
				wrapperCol: {
					xs: {
						span: 24,
						offset: 0
					},
					sm: {
						span: 16,
						offset: 6
					}
				}
		  }
		: null

	const getTaskFormValues = async () => {
		const w = await form.getFieldsValue()
		const dataObject = {
			name: w.description,
			assignee: id,
			assignee_name: `${first_name} ${last_name}`,
			assignor: id,
			assignor_name: `${first_name} ${last_name}`,
			related_to: taskAssociationSync[w.association]?.moduleName,
			association: taskAssociationSync[w.association]?.plural,
			association_id: w.association_id?.value,
			association_name: w.association_id?.label,
			start_date: moment().unix(),
			end_date: moment().unix(),
			status: 'In Progress',
			priority: 'Medium'
		}
		return dataObject
	}

	const handleAssociation = async e => {
		setResetTaskOptions(true)
		form.setFields([
			{
				name: 'task_id',
				value: null,
				label: null,
				errors: []
			}
		])
		const associationId = e.value
		try {
			const {
				data: { data }
			} = await fetchDeepInformation(associationId, association, token)
			if (data?.access_specifier === 'Private') {
				form.setFields([
					{
						name: 'association_id',
						errors: ['You are not authorized to use this entity to create the time entry!']
					}
				])
			} else {
				form.setFields([
					{
						name: 'association_id',
						value: e,
						errors: null
					}
				])
			}
			form.setFieldsValue({
				task: null
			})
		} catch (e) {
			message.error('Something went wrong!')
		}
	}

	return (
		<div>
			<Form
				name='tracker-form'
				scrollToFirstError
				onFinish={async () => {
					const q = form.getFieldsError(['association_id'])
					if (!q[0]?.errors?.length) {
						const values = await form.validateFields()
						onFinish(values)
					}
				}}
				form={form}
				{...formItemLayout}
				initialValues={formData}
			>
				<Form.Item label={extendedView && 'Description'} name='description'>
					<Input placeholder='Add description for your work' />
				</Form.Item>
				<Form.Item label={extendedView && 'Category'} name='category'>
					<Select
						placeholder='Select category'
						value={category}
						onChange={e => {
							setCategory(e)
							setAssociation(
								timeCategory.filter(a => a.name === e)[0].options
									? timeCategory.filter(a => a.name === e)[0].options[0]
									: null
							)
							form.setFieldsValue({
								category_id: null,
								association: timeCategory.filter(a => a.name === e)[0].options
									? timeCategory.filter(a => a.name === e)[0].options[0]
									: null,
								association_id: null,
								task: null
							})
							if (timeCategory.filter(q => q.name === e)[0].showDateRange) {
								form.setFieldsValue({
									timer_type: 'Manual'
								})
								setTimerType('Manual')
							}
							setResetOptions(true)
							setResetTaskOptions(true)
						}}
						defaultValue={category}
					>
						{extendedView
							? timeCategory.map(option => <Option value={option.name}>{option.name}</Option>)
							: timeCategory
									.filter(q => q.tagName !== 'Holiday')
									.map(option => <Option value={option.name}>{option.name}</Option>)}
					</Select>
				</Form.Item>
				<Form.Item
					label={extendedView && 'Association'}
					style={{
						marginBottom: 0
					}}
					hidden={!timeCategory.filter(a => a.name === category)[0].options}
				>
					<Form.Item
						name='association'
						style={{
							display: 'inline-block',
							width: 'calc(30%)'
						}}
					>
						<Select
							value={association}
							onChange={e => {
								setAssociation(e)
								form.setFieldsValue({
									association_id: null
								})
								setResetOptions(true)
								setResetTaskOptions(true)
							}}
							defaultValue={association}
							disabled={timeCategory.filter(a => a.name === category)[0]?.options?.length === 1}
						>
							{timeCategory
								.filter(a => a.name === category)[0]
								.options?.map(option => (
									<Option value={option}>{option}</Option>
								))}
						</Select>
					</Form.Item>
					<Form.Item
						name='association_id'
						style={{
							display: 'inline-block',
							width: 'calc(70%)'
						}}
					>
						<SelectDebounce
							showSearch
							placeholder={association && `Search ${association}`}
							fetchOptions={e => {
								setResetOptions(false)
								return fetchLists(e, association, token)
							}}
							resetOptions={resetOptions}
							onChange={e => handleAssociation(e, association)}
							currentSearchValue={data?.association_id && data?.association_id ? data?.association_name : ''}
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item
					label={
						extendedView && (
							<Space>
								Associated Task
								<span>
									<Button
										shape='circle'
										icon={<InfoCircleOutlined />}
										onClick={() =>
											notification.open({
												type: 'info',
												message: 'Associated Task',
												duration: 10,
												description:
													'If no association is selected, you can choose from all your tasks. Else, you will see your tasks related to the association here. You can also create new task or start without any task association if you have no tasks assigned.'
											})
										}
									/>
								</span>
							</Space>
						)
					}
				>
					<Space>
						<Form.Item name='task_id' style={{ width: 300 }}>
							<SelectDebounce
								showSearch
								placeholder='Search from your tasks or create new!'
								fetchOptions={async e => {
									const w = await fetchAssignedTasks(e, form.getFieldValue('association_id')?.value, token)
									if (!w.length) {
										form.setFields([
											{
												name: 'task_id',
												errors: ['No task for you! You can add new or start without any task association!']
											}
										])
									}
									setResetTaskOptions(false)
									return w
								}}
								onChange={(e, q) => {
									form.setFields([
										{
											name: 'association',
											value: timeSheetSync[q?.data?.association]
										},
										{
											name: 'association_id',
											value: {
												label: q?.data?.association_name,
												id: q?.data?.association_id,
												value: q?.data?.association_id
											}
										}
									])
								}}
								resetOptions={resetTaskOptions}
							/>
						</Form.Item>
						<Form.Item>
							<DrawerComponent
								form={
									<TaskForm
										handleClose={() => setAddDrawer(false)}
										data={taskData}
										handleActions={q =>
											form.setFieldsValue({
												task_id: {
													label: q.name,
													value: q.id,
													key: q.id
												}
											})
										}
									/>
								}
								visible={addDrawer}
								onOpen={async () => {
									const w = await getTaskFormValues()
									setTaskData(w)
									setAddDrawer(true)
								}}
								onClose={() => setAddDrawer(false)}
								buttonTitle='Add Task'
								buttonType='link'
								isHidden={!extendedView}
							/>
						</Form.Item>
					</Space>
				</Form.Item>
				<Form.Item label={extendedView && 'Timer Type'} name='timer_type'>
					<Radio.Group onChange={e => setTimerType(e.target.value)}>
						<Radio.Button value='Manual'>From & To</Radio.Button>
						<Radio.Button value='Timer' disabled={currentCategory.showDateRange || data}>
							Timer
						</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label={extendedView && 'Date'}
					name='date'
					initialValue={moment()}
					hidden={timerType === 'Timer' || !extendedView || currentCategory.showDateRange}
				>
					<DatePicker />
				</Form.Item>
				<Form.Item
					label={extendedView && 'Range'}
					name='time_range'
					hidden={timerType === 'Timer' && !currentCategory.showDateRange}
				>
					{!currentCategory.showDateRange ? (
						<TimePicker.RangePicker format='HH:mm a' placeholder={['From', 'To']} minuteStep={15} />
					) : (
						<DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' minuteStep={15} />
					)}
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						{timerType === 'Timer' ? 'Start' : 'Save'}
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default TimeEntryForm

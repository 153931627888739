/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { DashOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Table, Spin, message, Segmented } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { InternalHeader } from '../../Components'
import { addActiveAndLostReportStatus } from '../../Store/Actions/reports'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { BASE_URL } from '../../config'
import { getEnquiriesByActiveOrLostStatusReport } from '../../Services/reports'
import dateFormat from '../../Content/dateFormat'
import accounting from '../../Controllers/accountingNumbers'

const segmentOptions = [
	{
		label: 'Active',
		value: 'active'
	},
	{
		label: 'Won',
		value: 'won'
	},
	{
		label: 'Lost',
		value: 'lost'
	},
	{
		label: 'Cancelled',
		value: 'cancel'
	}
]

const EnquiriesStatusReport = () => {
	const componentRef = useRef()
	const { token } = useSelector(state => state.userSession)
	const { activeAndLostReport } = useSelector(state => state.reportsData)

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)
	const [reportData, setReportData] = useState(null)

	const fetchReport = async () => {
		setLoading(true)
		try {
			const { data } = await getEnquiriesByActiveOrLostStatusReport(token, activeAndLostReport?.type || 'active')
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		// in case the state is not yet registered
		if (!activeAndLostReport) {
			dispatch(
				addActiveAndLostReportStatus({
					type: 'active'
				})
			)
		}
	}, [])

	useEffect(() => {
		fetchReport()
	}, [activeAndLostReport?.type])

	const excelData = reportData?.length
		? {
				Enquiries: reportData.map(one => ({
					'Enquiry Number': one?.enquiry_number,
					'Enquiry Name': one?.name,
					Description: one?.description,
					Account: one?.account,
					Country: one?.country,
					Owner: one?.enquiry_owner_name,
					'Enquiry Phase': one?.phase,
					'Enquiry Date': one?.enquiry_date && moment.unix(one?.enquiry_date).format(dateFormat),
					'Due Date': one?.due_date && moment.unix(one?.due_date).format(dateFormat),
					'Final Revision': one?.last_revision,
					'Amount (USD)': one?.amount,
					'Submission Date':
						one?.submitted && typeof one?.submitted === 'number'
							? moment.unix(one?.submitted).format(dateFormat)
							: one?.submitted,
					'Enquiry Stage': one?.stage
				}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Enquiries Status Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName={`${activeAndLostReport?.type} Enquiries Report .xlsx`} />
						<ReactToPrint
							trigger={() => (
								<Button type='primary' icon={<PrinterOutlined />}>
									Print Report
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<Segmented
							options={segmentOptions}
							value={activeAndLostReport?.type}
							onChange={e => dispatch(addActiveAndLostReportStatus({ type: e }))}
						/>
					</div>
				</div>
				<ComponentToPrint
					ref={componentRef}
					componentData={reportData}
					activeAndLostReport={activeAndLostReport}
					loading={loading}
				/>
			</div>
		</div>
	)
}

const ComponentToPrint = React.forwardRef(({ componentData, loading, activeAndLostReport }, ref) =>
	// eslint-disable-next-line no-nested-ternary
	loading ? (
		<div className='pt-32 text-center'>
			{' '}
			<Spin size='large' />
		</div>
	) : (
		<div ref={ref} className='print:p-10 py-10'>
			<section className='text-2xl font-semibold py-2'>
				Enquiries Report:{' '}
				<span className='text-bell-blue capitalize'>
					{' '}
					{segmentOptions.filter(a => a.value === activeAndLostReport?.type)[0]?.label || 'Active'}
				</span>
			</section>
			<Table
				columns={[
					{ title: 'Enquiry Number', dataIndex: 'enquiry_number' },
					{ title: 'Job Description', dataIndex: 'name' },
					{ title: 'Account', dataIndex: 'account' },
					{ title: 'Country', dataIndex: 'country' },
					{ title: 'Owner', dataIndex: 'enquiry_owner_name' },
					{ title: 'Enquiry Phase', dataIndex: 'phase' },
					{
						title: 'Enquiry Date',
						dataIndex: 'enquiry_date',
						render: date => date && moment.unix(date).format(dateFormat)
					},
					{ title: 'Due Date', dataIndex: 'due_date', render: date => date && moment.unix(date).format(dateFormat) },
					{ title: 'Final Revision', dataIndex: 'last_revision' },
					{
						title: 'Amount (USD)',
						dataIndex: 'amount',
						render: amount => (amount ? accounting(amount) : <DashOutlined />)
					},
					{
						title: 'Submission Date',
						dataIndex: 'submitted',
						render: text => (text ? moment.unix(text).format(dateFormat) : <DashOutlined />)
					},
					{ title: 'Enquiry Stage', dataIndex: 'stage' },
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/enquiries?id=${record.id}`}>View Enquiry</a>
							</div>
						)
					}
				]}
				dataSource={componentData}
				pagination={false}
			/>
		</div>
	)
)

export default EnquiriesStatusReport

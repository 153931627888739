/* eslint-disable react/no-unstable-nested-components */
import { message, Table } from 'antd'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { BsArrowReturnRight } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { getProjectById, updateProject } from '../../../Services/projects'
import { refreshComponent } from '../../../Store/Actions/userSession'
import DrawerComponent from '../../DrawerComponent'
import ProductServicesTable from '../../Forms/Contracts/ProductServicesTable'
import TaskForm from '../../Forms/TaskForm'
import StartTask from '../../StartTask'
import TaskViewer from '../../TaskViewer'

const DeliverablesView = ({ projectId, projectName, data, accessType }) => {
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name, id } = useSelector(state => state.userProfile)
	const dispatch = useDispatch()
	const [editDrawer, setEditDrawer] = useState(false)
	const [taskForm, setTaskForm] = useState(false)

	const tasksColumn = [
		{
			title: 'Action / Task',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, task) => (
				<div className='flex justify-end'>
					<DrawerComponent
						form={<TaskViewer id={task.id} />}
						visible={taskForm === task.id}
						onOpen={() => setTaskForm(task.id)}
						onClose={() => setTaskForm(false)}
						buttonTitle='View'
						buttonType='link'
					/>
					<StartTask
						description={task.name}
						category='Project'
						association='Project'
						association_id={projectId}
						task_id={task.id}
					/>
				</div>
			)
		}
	]

	const taskDataObject = w => ({
		assignee: id,
		assignee_name: `${first_name} ${last_name}`,
		assignor: id,
		assignor_name: `${first_name} ${last_name}`,
		related_to: 'Projects',
		association: 'Projects',
		association_id: projectId,
		association_name: projectName,
		start_date: moment().unix(),
		end_date: moment().unix(),
		deliverable_id: w.deliverable_id,
		activity_id: w.activity_id,
		status: 'In Progress',
		priority: 'Medium'
	})

	const activitiesColumn = del => [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (text, record) => (
				<div className='flex items-center gap-2'>
					{text}
					{record?.tasks?.length ? (
						<span className='text-xs text-bell-gray flex items-center'>
							<BsArrowReturnRight /> {record.tasks.length}
						</span>
					) : null}
				</div>
			)
		},
		{ title: 'Description', dataIndex: 'description' },
		{
			title: '',
			render: (_, activity) => (
				<div className='flex justify-end'>
					<DrawerComponent
						form={
							<TaskForm
								data={taskDataObject({ deliverable_id: del.key, activity_id: activity.id })}
								handleClose={() => setTaskForm(false)}
							/>
						}
						visible={taskForm === `${del.key}-${activity.id}`}
						onOpen={() => setTaskForm(`${del.key}-${activity.id}`)}
						onClose={() => setTaskForm(false)}
						buttonTitle='Add Task'
						buttonType='link'
					/>
				</div>
			)
		}
	]

	const deliverablesColumn = [
		{
			title: 'Name',
			dataIndex: 'display_name',
			render: (text, record) => (
				<div className='flex items-center gap-2'>
					{text}
					{record?.ps_data?.billable_tasks?.length ? (
						<span className='text-xs text-bell-gray flex items-center'>
							<BsArrowReturnRight /> {record.ps_data.billable_tasks.length}
						</span>
					) : null}
				</div>
			)
		},
		{ title: 'Product / Service Group', render: (e, w) => w.ps_data?.name }
	]

	const handleUpdateDeliverables = async e => {
		const dataObject = {
			ps: e?.map(x => ({
				...x,
				ps_group_id: x?.ps_group_id?.value || v4(),
				ps_data: {
					...x.ps_data,
					billable_tasks: x?.ps_data?.billable_tasks?.length
						? x?.ps_data?.billable_tasks?.map(a => ({ ...a, id: a.id || v4() }))
						: []
				}
			}))
		}
		try {
			await updateProject(token, { id: projectId, ...dataObject })
			await getProjectById(token, projectId)
			dispatch(refreshComponent())
			message.success('Deliverables updated successfully!')
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	return (
		<div>
			<div className='flex flex-row-reverse space-x-4'>
				<DrawerComponent
					form={
						<ProductServicesTable
							edit
							handleClose={() => {
								setEditDrawer(false)
							}}
							handleData={handleUpdateDeliverables}
							data={data.map(one => ({
								...one,
								ps_group_id:
									typeof one.ps_group_id === 'string'
										? { key: one.ps_group_id, label: one?.ps_data?.name || '-', value: one.ps_group_id }
										: one.ps_group_id
							}))}
							hideFinancialInformation
						/>
					}
					visible={editDrawer}
					onOpen={() => setEditDrawer(true)}
					onClose={() => setEditDrawer(false)}
					buttonTitle='Update Deliverables'
					buttonType='primary'
					drawerTitle='Update Deliverables'
					drawerWidth={1300}
					isHidden={accessType !== 'Public'}
				/>
			</div>
			<Table
				expandable={{
					expandedRowRender: record => (
						<Table
							columns={activitiesColumn(record)}
							dataSource={record.ps_data?.billable_tasks?.map((a, i) => ({ key: i, ...a }))}
							pagination={false}
							showHeader={false}
							expandable={{
								expandedRowRender: activity => (
									<Table
										columns={tasksColumn}
										dataSource={activity?.tasks || []}
										pagination={false}
										showHeader={false}
										size='small'
									/>
								),
								defaultExpandedRowKeys: ['0'],
								expandRowByClick: true,
								rowExpandable: rec => rec?.tasks?.length
							}}
						/>
					),
					expandRowByClick: true,
					rowExpandable: rec => rec?.ps_data?.billable_tasks?.length
				}}
				columns={deliverablesColumn}
				dataSource={data}
				pagination={false}
			/>
		</div>
	)
}

export default DeliverablesView

import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import dateFormat from '../../../../Content/dateFormat'

const Registration = ({ data }) => {
	const refactor = {
		..._.omit(data, [
			'created_at',
			'updated_at',
			'share_only',
			'share_only_data',
			'share_and_edit',
			'share_and_edit_data',
			'access_specifier',
			'access_type'
		]),
		enquiry_date: moment.unix(data.enquiry_date).format(dateFormat),
		submission_date: moment.unix(data.submission_date).format(dateFormat),
		contact_person: data.contact_person_data || '-',
		probability: <div> {data.probability}%</div>,
		lead_account_name: <Link to={`./accounts?id=${data.account_id}`}> {data.lead_account_name} </Link>,
		end_user_account_name: <Link to={`./accounts?id=${data.end_user_account_id}`}> {data.end_user_account_name} </Link>
	}
	return (
		<div>
			<div className='font-bold text-base text-bell-text py-2'> Registration</div>
			<div className='p-4 space-y-4'>
				{Object.keys(_.omit(refactor, ['contact_person_data', 'account_id', 'end_user_account_id']))
					.filter(item => refactor[item])
					.map((item, i) => (
						<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
							<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
							<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
						</div>
					))}
			</div>
		</div>
	)
}

export default Registration

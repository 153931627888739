import React, { useEffect, useState } from 'react'
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { BiLockAlt } from 'react-icons/all'
import New from './New'
import Working from './Working'
import Nurturing from './Nurturing'
import Closed from './Closed'
import * as Colors from '../../../../styles/colors'

const LeadStage = ({ stages, data, editable }) => {
	const currentStage = data.lead_status || 'New'
	const [selectedStage, setSelectedStage] = useState('')

	useEffect(() => {
		setSelectedStage(currentStage)
	}, [currentStage])

	const [displayDescription, setDisplayDescription] = useState(false)

	const selectStage = w => {
		setSelectedStage(w)
	}

	const stageStyle = (stage, i) => {
		if (stage === currentStage) {
			return 'h-12 w-48 flex items-center justify-center space-x-2 border border-l-8 border-bell-green rounded-sm bg-white cursor-pointer'
		}
		if (stage === selectedStage) {
			return 'h-12 w-48 flex items-center justify-center space-x-2 border border-bell-blue rounded-sm bg-white cursor-pointer'
		}
		if (stages.indexOf(currentStage) > i) {
			return 'h-12 w-48 flex items-center justify-center space-x-2 border  border-bell-lightgreen rounded-sm bg-white cursor-pointer '
		}
		return 'h-12 w-48 flex items-center justify-center space-x-2 border rounded-sm bg-white cursor-pointer'
	}

	const getDetails = () => {
		switch (selectedStage) {
			case 'New':
				return (
					<New
						data={_.omit(data, [
							'working_data',
							'nurturing_data',
							'closed_data',
							'share_and_edit_data',
							'share_only_data'
						])}
					/>
				)
			case 'Working':
				return (
					<Working
						data={data?.working_data}
						isFilled={stages.indexOf(currentStage) >= stages.indexOf('Working')}
						editable={editable}
					/>
				)
			case 'Nurturing':
				return (
					<Nurturing
						data={data?.nurturing_data}
						isFilled={stages.indexOf(currentStage) >= stages.indexOf('Nurturing')}
						editable={editable}
					/>
				)
			case 'Closed':
				return (
					<Closed
						data={data?.closed_data}
						isFilled={stages.indexOf(currentStage) >= stages.indexOf('Closed')}
						editable={editable}
					/>
				)
			default:
				return null
		}
	}

	return (
		<div>
			<div className='w-full flex justify-between'>
				<div className='flex'>
					<div
						className='h-12 w-12 grid place-items-center border rounded-sm bg-white cursor-pointer'
						onClick={() => setDisplayDescription(!displayDescription)}
					>
						{!displayDescription ? <AiOutlineRight /> : <AiOutlineDown />}
					</div>
					<div className='flex space-x-4 items-center'>
						{stages.map((stage, i) => (
							<div
								className={stageStyle(stage, i)}
								onClick={() => {
									selectStage(stage)
									if (stages.indexOf(currentStage) >= stages.indexOf(stage) - 1) {
										setDisplayDescription(true)
									} else {
										setDisplayDescription(false)
									}
								}}
							>
								{stages.indexOf(currentStage) < stages.indexOf(stage) ? (
									<Tooltip title='Stages cannot be bypassed.' placement='topLeft'>
										<BiLockAlt fontSize={20} color={Colors.BLUE} />
									</Tooltip>
								) : null}
								<div>{stage}</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{displayDescription ? <div className='bg-white p-5 mt-5'>{getDetails()}</div> : null}
		</div>
	)
}

export default LeadStage

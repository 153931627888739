import React from 'react'
import { Link } from 'react-router-dom'
import { drawerMenu } from '../Content/drawerMenu'

const InternalHeader = ({ title, selected }) => {
	const mapper = drawerMenu.filter(a => a.title === title)[0].children
	return (
		<div className='flex px-10 shadow-sm items-center space-x-10 bg-white'>
			<div className='text-xl font-bold'> {title} </div>
			<div className='flex space-x-10 items-center'>
				{mapper.map((item, i) => (
					<div
						key={i}
						className={
							selected && selected === item.title
								? 'flex items-center space-x-2 py-2 px-2 border-b-4 border-bell-blue'
								: 'flex items-center space-x-2 py-2'
						}
					>
						<Link to={item.url}>
							<div className='text-bell-text hover:text-bell-blue'>{item.title}</div>
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}

export default InternalHeader

import { Alert } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setPreferences } from '../Store/Actions/userProfile'

const InformationBanner = ({ text }) => {
	const dispatch = useDispatch()
	return (
		<div className='py-4'>
			<Alert
				message={text}
				type='warning'
				showIcon
				closable
				afterClose={() => dispatch(setPreferences({ calendarInfo: false }))}
			/>
		</div>
	)
}

export default InformationBanner

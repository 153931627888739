import React, { useEffect, useState } from 'react'
import { message, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// COMPONENTS
import { AiFillCaretDown, AiFillCaretRight, AiFillCaretUp } from 'react-icons/ai'
import { DrawerComponent } from '../..'
import { VariationsForm } from '../../Forms'
import ModalView from '../../ModalView'
import dateFormat from '../../../Content/dateFormat'
import accounting from '../../../Controllers/accountingNumbers'
import { getVariationByContractId } from '../../../Services/Contracts/contracts'
import { resetVariation } from '../../../Store/Actions/contracts'
import * as Colors from '../../../styles/colors'
import MINIOLink from '../../../Controllers/MinioLink'

const changeDisplay = change => {
	if (change > 0) {
		return (
			<div className='text-bell-green flex items-center space-x-1'>
				<span>
					<AiFillCaretUp />
				</span>
				<div>{accounting(change)}</div>
			</div>
		)
	}
	if (change < 0) {
		return (
			<div className='text-bell-red flex items-center space-x-1'>
				<span>
					<AiFillCaretDown />
				</span>
				<div>{accounting(Math.abs(change))}</div>
			</div>
		)
	}
	return (
		<div className='text-bell-gray flex items-center space-x-1'>
			<span>
				<AiFillCaretRight />
			</span>
			<div>{accounting(change)}</div>
		</div>
	)
}
const Variations = ({ contractData, accessType }) => {
	const dispatch = useDispatch()
	const { token } = useSelector(state => state.userSession)
	const [addDrawer, setAddDrawer] = useState(false)
	const [viewMore, setViewMore] = useState(false)
	const variationsData = useSelector(state => state.variationsData)

	const fetchData = async () => {
		try {
			const { data } = await getVariationByContractId(token, contractData.id)
			if (!data.data) {
				dispatch(resetVariation([]))
			} else {
				dispatch(resetVariation(data.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const tagColor = {
		Positive: Colors.GREEN,
		Negative: Colors.RED,
		Initial: Colors.LIGHT_GREY,
		Neutral: Colors.BLUE
	}

	const columns = [
		{
			title: 'Date',
			key: 'created_at',
			render: (_, record) => moment.unix(record.created_at).format(dateFormat)
		},
		{
			title: 'Type of Variation',
			key: 'type',
			render: (_, record) => <Tag color={tagColor[record.type]}>{record.type}</Tag>
		},
		{
			title: 'Product & Services',
			key: 'ps',
			render: (text, record) => record?.ps?.length
		},
		{
			title: 'Total Amount (USD)',
			key: 'amount',
			render: (text, record) =>
				`${accounting(record?.ps?.map(one => Number(one.rate) * Number(one.quantity)).reduce((a, b) => a + b, 0))}`
		},
		{
			title: 'Change (USD)',
			key: 'change',
			render: (text, record) => <div className='flex justify-end'>{changeDisplay(record.change)}</div>
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (q, record) => {
				const drawerData = {
					description: record.description,
					amount: record?.ps?.length && (
						<div className='flex'>
							USD{' '}
							{accounting(record?.ps?.map(one => Number(one.rate) * Number(one.quantity)).reduce((a, b) => a + b, 0))}{' '}
							<div className='text-sm pl-4'>{changeDisplay(record.change)}</div>
						</div>
					),
					variation_number: record.number,
					type: <Tag color={tagColor[record.type]}>{record.type}</Tag>,
					'Product & Services': (
						<Table
							columns={[
								{ title: 'Product / Service Name', dataIndex: 'display_name' },
								{ title: 'Product / Service Group', render: (_, w) => w.ps_group_name },
								{ title: 'Quantity', render: (_, w) => Number(w.quantity) },
								{ title: 'Rate', render: (_, w) => `USD ${accounting(Number(w.rate))}` },
								{ title: 'Order Value', render: (_, w) => `USD ${accounting(Number(w.rate) * Number(w.quantity))}` }
							]}
							dataSource={record.ps}
							pagination={false}
						/>
					),
					variation_order:
						record?.variation_order?.length &&
						record?.variation_order?.map((file, i) => (
							<div key={i}>
								<MINIOLink item={file} />
							</div>
						)),
					other_attachments:
						record?.attachments?.length &&
						record?.attachments?.map((file, i) => (
							<div key={i}>
								<MINIOLink item={file} />
							</div>
						)),
					created_at: record.created_at,
					updated_at: record.updated_at
				}
				return (
					<DrawerComponent
						visible={viewMore[record.id]}
						onOpen={() => setViewMore({ [record.id]: true })}
						onClose={() => setViewMore(false)}
						drawerTitle='Variation Details'
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<ModalView data={drawerData} />}
					/>
				)
			}
		}
	]
	return (
		<div className='space-y-5'>
			<div className='text-right'>
				<DrawerComponent
					form={
						<VariationsForm
							contractData={contractData}
							handleClose={() => {
								setAddDrawer(false)
							}}
						/>
					}
					visible={addDrawer}
					onOpen={() => setAddDrawer(true)}
					onClose={() => setAddDrawer(false)}
					buttonTitle='Add Variation'
					drawerWidth={1100}
					isHidden={accessType !== 'Public'}
				/>
			</div>

			{/* NOTES TABLE */}
			<Table columns={columns} dataSource={variationsData} pagination={false} />
		</div>
	)
}

export default Variations

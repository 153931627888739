import React, { useState } from 'react'
import { Form, Input, Button, Select, message, Upload, DatePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import { tailFormItemLayout, formItemLayout } from '../../../Controllers/form'
import { updateRevision } from '../../../Services/Sales/enquiries'
import { proposalType } from '../../../Content/enquiries'
import { URL } from '../../../config'
import { createActivity } from '../../../Services/activity'
import { addActivity } from '../../../Store/Actions/activity'
import { addEnquirySubmission } from '../../../Store/Actions/enquiries'
import { refreshComponent } from '../../../Store/Actions/userSession'
import dateFormat from '../../../Content/dateFormat'

const { Option } = Select

const SubmissionForm = ({ handleClose }) => {
	const { token } = useSelector(state => state.userSession)
	const { enquiry_number, current_revision_data, current_revision_id, id } = useSelector(
		state => state.singleEnquiriesData
	)
	const costingOptions = current_revision_data?.costing?.costing || []

	const [uploadedFiles, setUploadedFiles] = useState({})
	const [uploadedEvidence, setUploadedEvidence] = useState([])
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			costing_schema: values.costing_schema,
			submission_date: moment(values.submission_date).unix(),
			submission_data: values.submission
				? values.submission?.map((x, i) => ({
						attachments: uploadedFiles[i],
						proposal_type: x.proposal_type,
						evidence: uploadedEvidence[i]
				  }))
				: [],
			amount: Number(values.amount)
		}
		try {
			await updateRevision(token, { id: current_revision_id, revision_stage: 'Submission', submission: dataObject })
			dispatch(addEnquirySubmission(dataObject))
			dispatch(refreshComponent())
			const activityObject = {
				association_id: id,
				activity_type: 'Revision',
				subject: `Revision ${current_revision_data.revision_number} Submitted`
			}
			const { data: activityData } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: activityData.data.id,
					...activityObject
				})
			)
			message.success('Submission updated successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}
	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: true
	}

	const onCostingSelection = costingID => {
		const thisSchema = costingOptions?.filter(one => one.id === costingID)[0]
		const totalAmount = thisSchema?.deliverables?.map(x => x.total_cost).reduce((a, b) => a + b, 0)
		form.setFieldsValue({
			amount: totalAmount
		})
	}
	return (
		<div className='space-y-20'>
			{/* SUBMIT AN ENQUIRY FORM */}
			<Form name='control-hooks' form={form} onFinish={onAdd} {...formItemLayout} scrollToFirstError>
				<div className='py-2 italic text-bell-gray'>Enquiry Information</div>
				<Form.Item label='Enquiry Number' name='enquiry_number' initialValue={enquiry_number}>
					<Input disabled />
				</Form.Item>
				<Form.Item label='Revision Number' name='revision_number' initialValue={current_revision_data.revision_number}>
					<Input disabled />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Submission Information</div>
				<Form.Item label='Submission Date' name='submission_date' initialValue={moment()}>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Costing Schema' name='costing_schema'>
					<Select placeholder='Select Costing Schema' onChange={onCostingSelection}>
						{costingOptions?.map(option => (
							<Option value={option.id}>
								<p className='capitalize'>{option.name}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='Amount'
					name='amount'
					rules={[
						{
							type: 'number',
							message: 'The input is not a number',
							transform(value) {
								return Number(value)
							}
						}
					]}
				>
					<Input addonBefore='USD' />
				</Form.Item>
				<Form.List name='submission'>
					{(fields, { add, remove }) => (
						<>
							{fields.map((field, i) => (
								<div key={field.key} className='w-full'>
									<div className='text-right'>
										<MinusCircleOutlined onClick={() => remove(field.name)} />
									</div>
									<div>
										<Form.Item
											{...field}
											name={[field.name, 'proposal_type']}
											fieldKey={[field.fieldKey, 'proposal_type']}
											label='Submission Type'
										>
											<Select
												placeholder='Select Type of Submission'
												style={{
													width: 300
												}}
											>
												{proposalType.map(option => (
													<Option value={option}>
														<p className='capitalize'>{option}</p>
													</Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											{...field}
											label='Attachments'
											name={[field.name, 'attachments']}
											fieldKey={[field.fieldKey, 'attachments']}
										>
											<Upload
												{...props}
												onChange={info => {
													if (info.file.status === 'done') {
														message.success(`${info.file.name} file uploaded successfully`)
														setUploadedFiles(prev => ({
															...prev,
															[i]: prev[i] ? [...prev[i], info.file.response.data.name] : [info.file.response.data.name]
														}))
													} else if (info.file.status === 'error') {
														message.error(`${info.file.name} file upload failed.`)
													}
												}}
											>
												<Button icon={<UploadOutlined />}>Upload Attachment</Button>
											</Upload>
										</Form.Item>

										<Form.Item
											{...field}
											label='Evidence of Submission'
											name={[field.name, 'evidence']}
											fieldKey={[field.fieldKey, 'evidence']}
										>
											<Upload
												{...props}
												onChange={info => {
													if (info.file.status === 'done') {
														message.success(`${info.file.name} file uploaded successfully`)
														setUploadedEvidence(prev => ({
															...prev,
															[i]: prev[i] ? [...prev[i], info.file.response.data.name] : [info.file.response.data.name]
														}))
													} else if (info.file.status === 'error') {
														message.error(`${info.file.name} file upload failed.`)
													}
												}}
											>
												<Button icon={<UploadOutlined />}>Upload Evidence</Button>
											</Upload>
										</Form.Item>
									</div>
								</div>
							))}

							<Form.Item {...tailFormItemLayout}>
								<Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
									Add Proposal
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default SubmissionForm

import React, { useState } from 'react'
import { Button, DatePicker, Divider, Drawer, Form, Input, message, Select, Space } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { v4 } from 'uuid'
import { AccountForm, ContactForm, EmployeeForm, RegisterEnquiryForm } from '..'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import { objectDifference } from '../../../Controllers/objectDifference'
import SelectDebounce from '../../SelectDebounce'
import {
	fetchAccountList,
	fetchContactsList,
	fetchEnquiriesList,
	fetchEntitiesList,
	fetchUserList
} from '../../../Controllers/fetchLists'
import { contractFormOptions } from '../../../Content/contracts'
import { addContract, editContract } from '../../../Store/Actions/contracts'
import { Files } from '../../Views'
import { createContract, getContractById, updateContract } from '../../../Services/Contracts/contracts'
import ProductServicesTable from './ProductServicesTable'
import dateFormat from '../../../Content/dateFormat'
import ReporteeSelect from '../../ReporteeSelect'

const { Option } = Select

const ContractForm = ({ edit, handleClose, data, handleValues }) => {
	const formData = data
		? {
				...data,
				account_id: {
					key: data.account_id,
					label: data.account_name,
					value: data.account_id
				},
				entity_id: {
					key: data.entity_id,
					label: data?.entity_data?.name,
					value: data.entity_id
				},
				owner: {
					key: data.owner,
					label: data.owner_name,
					value: data.owner
				},
				project_manager_id: {
					key: data.project_manager_id,
					label: data.project_manager_name,
					value: data.project_manager_id
				},
				deputy_id: {
					key: data.deputy_id,
					label: data.deputy_name,
					value: data.deputy_id
				},
				enquiry_id: {
					key: data.enquiry_id,
					label: data.enquiry_number ? `${data.enquiry_number} - ${data.enquiry_name}` : data.enquiry_name,
					value: data.enquiry_id
				},
				contracts_representatives: data.contracts_representatives_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				projects_representatives: data.projects_representatives_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				po_date: data.po_date !== 0 ? moment.unix(data.po_date) : undefined
		  }
		: false

	const { token, role } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState({
		contract_documents: data?.attachments?.contract_documents || [],
		supporting_documents: data?.attachments?.supporting_documents || []
	})
	const [tableData, setTableData] = useState([])
	const selectWidth = 500
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}
	const dataObject = values => ({
		..._.omit(values, ['contract_type', 'contract_documents', 'supporting_documents']),
		account_id: values?.account_id?.value,
		entity_id: values?.entity_id?.value,
		enquiry_id: values?.enquiry_id?.value,
		project_manager_id: values?.project_manager_id?.value,
		deputy_id: values?.deputy_id?.value,
		currency: 'USD',
		po_date: values.po_date ? moment(values.po_date).unix() : 0,
		owner: values?.owner?.value,
		contracts_representatives: values.contracts_representatives?.map(one => one.value) || [],
		projects_representatives: values.projects_representatives?.map(one => one.value) || [],
		attachments: uploadedFiles,
		// to avoid db query puncture, a temporary id is used in case there is no ps group available.
		ps: data?.ps || tableData?.map(x => ({ ...x, ps_group_id: x?.ps_group_id?.value || v4() }))
	})
	const onAdd = async values => {
		setLoading(true)
		try {
			const { data: responseData } = await createContract(token, dataObject(values))
			const { id } = responseData.data
			const res = await getContractById(token, id)
			dispatch(addContract(res.data.data))
			message.success('Contract added successfully!')
			form.resetFields()
			setUploadedFiles({
				contract_documents: [],
				supporting_documents: []
			})
			if (handleValues) {
				handleValues(res.data.data)
			}
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}
	const onEdit = async values => {
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject(values))
			await updateContract(token, { id: data.id, ...updatedData })
			const res = await getContractById(token, data.id)
			dispatch(
				editContract({
					id: data.id,
					data: res.data.data
				})
			)
			message.success('Contract updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getDrawerComponent = () => {
		switch (visible) {
			case 'Add Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={one => {
							form.setFieldsValue({
								account_id: {
									label: one.account_name,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Employee':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								owner: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Project Manager':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								project_manager_id: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Deputy':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={one => {
							form.setFieldsValue({
								deputy_id: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id
								}
							})
						}}
					/>
				)
			case 'Add Enquiry':
				return (
					<RegisterEnquiryForm
						handleClose={onClose}
						handleValue={(a, b) => {
							form.setFieldsValue({
								enquiry_id: {
									label: b,
									value: a
								}
							})
							form.setFieldsValue({
								project_name: b
							})
						}}
					/>
				)
			case 'Add Project Representative':
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(_, one) => {
							form.setFieldsValue({
								projects_representatives: [
									...form.getFieldValue('projects_representatives'),
									{
										label: `${one.first_name} ${one.last_name}`,
										value: one.id
									}
								]
							})
						}}
					/>
				)
			case 'Add Contract Representative':
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(_, one) => {
							form.setFieldsValue({
								contracts_representatives: [
									...form.getFieldValue('contracts_representatives'),
									{
										label: `${one.first_name} ${one.last_name}`,
										value: one.id
									}
								]
							})
						}}
					/>
				)
			case 'Add Products & Services':
				return (
					<ProductServicesTable
						data={tableData.map(q => _.omit(q, ['ps_data']))}
						handleClose={onClose}
						handleData={w => setTableData(w)}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Add Account':
				return 700
			case 'Add Employee':
				return 500
			case 'Add Enquiry':
				return 900
			case 'Add Products & Services':
				return 1000
			default:
				return 700
		}
	}

	const handleFiles = async (currentAttachment, fileName, attachmentType) => {
		setUploadedFiles(prev => ({
			...prev,
			[attachmentType]: [currentAttachment, ...prev[attachmentType]]
		}))
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async (fileName, attachmentType) => {
		try {
			if (edit) {
				await updateContract(token, {
					id: formData.id,
					attachments: {
						...uploadedFiles,
						[attachmentType]: uploadedFiles[attachmentType].filter(x => x !== fileName)
					}
				})
			}
			setUploadedFiles(prev => ({
				...prev,
				[attachmentType]: prev[attachmentType].filter(x => x !== fileName)
			}))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	return (
		<div className='space-y-20'>
			{/* REGISTER FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Registration Information</div>
				<Form.Item
					name='owner'
					label='Contract Owner'
					rules={[{ required: true, message: 'Please input contract owner!' }]}
				>
					<ReporteeSelect form={form} fieldName='owner' currentValue={formData ? formData?.owner?.label : null} />
				</Form.Item>

				<Form.Item label='Invoicing Entity' name='entity_id'>
					<SelectDebounce
						showSearch
						placeholder='Search For Invoicing Entity or Ask Admin to add one!'
						fetchOptions={e => fetchEntitiesList(e, token)}
					/>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Contract Information</div>
				<Form.Item label='Account Name'>
					<Space>
						<Form.Item name='account_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select from Accounts'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Account')} type='link'>
							Add Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Enquiry'>
					<Space>
						<Form.Item name='enquiry_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select from Enquiries'
								fetchOptions={e => fetchEnquiriesList(e, token)}
								style={{
									width: selectWidth
								}}
								onChange={e => form.setFieldsValue({ project_name: e.label.split('-')[1]?.trim() || e.label })}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Enquiry')} type='link'>
							Add Enquiry
						</Button>
					</Space>
				</Form.Item>
				<Form.Item
					label='Project Name'
					name='project_name'
					rules={[{ required: true, message: 'Please input project name!' }]}
				>
					<Input placeholder='Auto filled from Enquiry Name' />
				</Form.Item>
				<Form.Item label='Type of Contract' name='contract_type'>
					<Select placeholder='Select type of contract' defaultValue='New Contract' disabled>
						{contractFormOptions.contractType.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Contract Information'>
					<Space>
						<Form.Item label='Contract / PO Number' name='po_number'>
							<Input placeholder='Order Ref. No' />
						</Form.Item>
						<Form.Item label='PO Date' name='po_date' rules={[{ required: true, message: 'Please input PO date!' }]}>
							<DatePicker format={dateFormat} />
						</Form.Item>
					</Space>
				</Form.Item>
				<Divider />
				<Form.Item label='Products / Services Information' name='ps'>
					{edit ? (
						<div className='text-sm text-bell-gray'>
							To update Products & Services, Add &quot;Variation&quot; in Details Tab of this Contract.
						</div>
					) : (
						<Space>
							<Button onClick={e => showDrawer(e, 'Add Products & Services')} type='secondary'>
								Add Products & Services
							</Button>
							<Divider type='vertical' />
							<div className='text-gray-600 font-normal'>
								<span className='text-bell-blue text-2xl font-medium'>{tableData?.length || 'No'}</span> Item(s) added.
							</div>
						</Space>
					)}
				</Form.Item>
				<Divider />

				<Form.Item label='Terms & Conditions' name='terms_and_conditions'>
					<Input.TextArea rows={4} placeholder='Terms & Conditions' />
				</Form.Item>
				<Form.Item label="Client's TRN" name='trn'>
					<Input placeholder="Client's Tax Registration Number" />
				</Form.Item>

				<div className='py-2 italic text-bell-gray'>Management Information</div>
				<Form.Item label='Project Manager'>
					<Space>
						<Form.Item name='project_manager_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search from Employees'
								fetchOptions={e => fetchUserList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						{role === 'Admin' ? (
							<Button onClick={e => showDrawer(e, 'Add Project Manager')} type='link'>
								Add Employee
							</Button>
						) : null}
					</Space>
				</Form.Item>
				<Form.Item label='Deputy'>
					<Space>
						<Form.Item name='deputy_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search from Employees'
								fetchOptions={e => fetchUserList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						{role === 'Admin' ? (
							<Button onClick={e => showDrawer(e, 'Add Deputy')} type='link'>
								Add Employee
							</Button>
						) : null}
					</Space>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Contacts Information</div>
				<Form.Item label="Project's Representatives">
					<Space>
						<Form.Item name='projects_representatives' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select from Contacts'
								fetchOptions={e => fetchContactsList(e, token)}
								isMultiple
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Project Representative')} type='link'>
							Add Contact
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label="Contract's Representatives">
					<Space>
						<Form.Item name='contracts_representatives' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select from Contacts'
								fetchOptions={e => fetchContactsList(e, token)}
								isMultiple
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Contract Representative')} type='link'>
							Add Contact
						</Button>
					</Space>
				</Form.Item>

				<div className='py-2 italic text-bell-gray'>Attachments</div>
				<Form.Item label='Contract Documents' name='contract_documents'>
					<Files
						attachments={uploadedFiles.contract_documents}
						handleFiles={(a, b) => handleFiles(a, b, 'contract_documents')}
						handleRemove={a => handleRemove(a, 'contract_documents')}
					/>
				</Form.Item>
				<Form.Item label='Supporting Documents' name='supporting_documents'>
					<Files
						attachments={uploadedFiles.supporting_documents}
						handleFiles={(a, b) => handleFiles(a, b, 'supporting_documents')}
						handleRemove={a => handleRemove(a, 'supporting_documents')}
					/>
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				visible={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default ContractForm
